import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { MarketInfoService } from './MarketInfoService.service';
@Injectable({
  providedIn: 'root',
})

export class RentalService {
  private baseURL: string;
  constructor(private http: HttpClient, private marketInfoService: MarketInfoService
  ) {
    this.baseURL = environment.apiBaseUrl;
  }

  public options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };


  SaveDailyRental(rentalData): Observable<any> {
    var marketId = null;
    this.marketInfoService.getMarketId().subscribe(marketId1 => {
      marketId = marketId1;
    });
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveDailyRentalTransactions?marketId=${marketId}`
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SaveCombinedMonthlyRentalTransactions(rentalData): Observable<any> {
    var marketId = null;
    this.marketInfoService.getMarketId().subscribe(marketId1 => {
      marketId = marketId1;
    });
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveCombinedMonthlyRentalTransactions?marketId=${marketId}`
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SaveCombinedWeeklyRentalTransactions(rentalData): Observable<any> {
    var marketId = null;
    this.marketInfoService.getMarketId().subscribe(marketId1 => {
      marketId = marketId1;
    });
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveCombinedWeeklyRentalTransactions?marketId=${marketId}`
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SaveWeeklyRentalTransactions(rentalData): Observable<any> {
    var marketId = null;
    this.marketInfoService.getMarketId().subscribe(marketId1 => {
      marketId = marketId1;
    });
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveWeeklyRentalTransactions?marketId=${marketId}`
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  checkRentalExits(marketId,rentalDateDetails): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/CheckRentalExits?marketId=${marketId}`;
    return this.http.post<any>(url, rentalDateDetails, options)
    .pipe(
      map(res => {
        const response = res as any;
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getDailyRentals(marketId,vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetDailyRentals?vendorId=${vendorId}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }


  getDailyOtherRentals(marketId,vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetDailyOtherRentals?vendorId=${vendorId}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  saveMonthlyRental(marketId,rentalData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/SaveMonthlyRentalTransactions?marketId=${marketId}`
    return this.http.post<any>(url, rentalData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getOtherRentalItems(marketId,rentalDate = ''): Observable<any[]> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetOtherRentalItems?marketId=${marketId}&rentalDate=${rentalDate}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getPromoCodeAppliedCountForVendor(marketId,VendorId,PromoCodeId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetPromoCodeAppliedCountForVendor?marketId=${marketId}&VendorId=${VendorId}&PromoCodeId=${PromoCodeId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getWIFIOtherItem(marketId,wIFIOtherItemId): Observable<any[]> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetWIFIOtherItem?marketId=${marketId}&WIFIOtherItemId=${wIFIOtherItemId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getOtherItemsRentalCharges(otherRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetOtherItemsRentalCharges`;
    return this.http.post<any>(url, otherRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  generateTempRecordNumber(marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GenerateTempRecordNumber?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  insertDataToTemp(tempData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/InsertDataToTemp`;
    return this.http.post<any>(url, tempData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  deleteFromTemp(tempData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/DeleteFromTemp`;
    return this.http.post<any>(url, tempData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  GetSelectedMonthEndDate(yearMonth,marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetSelectedMonthEndDate?marketId=${marketId}&yearMonth=${yearMonth}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const monthEndDate = res.body as any;
          return monthEndDate;
        }),
        catchError(this.handleError)
      );
  }

  checkAbsenteeVendorForDate(marketId, boothNumber, selectedMarketDays): Observable<any> {
    const url = `${this.baseURL}/Rental/CheckAbsenteeVendorForDate?marketId=${marketId}&BoothNumber=${boothNumber}`
    return this.http.post<any>(url, selectedMarketDays, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getVendorBalances(vendorId, marketId): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetVendorBalances?vendorId=${vendorId}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getVendorPaymentDetails(vendorId, marketId,accountReceiptId): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetVendorPaymentDetails?vendorId=${vendorId}&marketId=${marketId}&accountReceiptId=${accountReceiptId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  ComposeEmails(marketId,emailData): Observable<any[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/ComposeEmails?marketId=${marketId}`;
    return this.http.post<any>(url, emailData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SendWRPaymentConfirmationEMail(marketId,emailData): Observable<any[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SendWRPaymentConfirmationEMail?marketId=${marketId}`;
    return this.http.post<any>(url, emailData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  SendWRBookingConfirmationEMail(marketId,emailData): Observable<any[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Vendor/SendWRBookingConfirmationEMail?marketId=${marketId}`;
    return this.http.post<any>(url, emailData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getRentalSettings(marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetRentalSettings?marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getCustomSettings(marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetCustomSettings?marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getRecordFromTemp(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetRecordFromTemp`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getBoothStatus(boothStatusInput): Observable<any> {
    const url = `${this.baseURL}/Rental/GetBoothStatus`;
    return this.http.post<any>(url, boothStatusInput, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getProductCategories(marketId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetProductCategories?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getVendorRentalDays(rentalDays): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetVendorRentalDays`;
    return this.http.post<any>(url, rentalDays, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getBoothRentalCombinedMonthlyCharges(marketId,boothRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/getBoothRentalCombinedMonthlyCharges?marketId=${marketId}`;
    return this.http.post<any>(url, boothRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getBoothRentalCombinedWeeklyCharges(marketId,boothRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetBoothRentalCombinedWeeklyCharges?marketId=${marketId}`;
    return this.http.post<any>(url, boothRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getBoothRentalWeeklyCharges(marketId,boothRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetBoothRentalWeeklyCharges?marketId=${marketId}`;
    return this.http.post<any>(url, boothRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getBoothRentalMontlyCharges(marketId,boothRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetBoothRentalMontlyCharges?marketId=${marketId}`;
    return this.http.post<any>(url, boothRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }
  
  getBoothrentalCharges(boothRentalCharges): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GetBoothrentalCharges`;
    return this.http.post<any>(url, boothRentalCharges, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  UpdateVendorResaleInfo(vendorResaleInfo): Observable<boolean> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/UpdateVendorResaleInfo`;
    return this.http.post<any>(url, vendorResaleInfo, options)
      .pipe(
        map(res => {
          const response = res as boolean;
          return response;
        }),
        catchError(this.handleError)
      );
  }
  
  updateVendorBusinessLicense(marketId,vendorBusinesLicInfo): Observable<boolean> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/UpdateVendorBusinessLicense?marketId=${marketId}`;
    return this.http.post<any>(url, vendorBusinesLicInfo, options)
      .pipe(
        map(res => {
          const response = res as boolean;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getCombinedMonthlyBookings(marketId,vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetCombinedMonthlyBookings?vendorId=${vendorId}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  
  getCombinedMonthlyOtherRentals(marketId,vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetCombinedMonthlyOtherRentals?vendorId=${vendorId}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getMonthlyBookings(marketId,vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetMonthlyBookings?vendorId=${vendorId}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }


  getMonthlyOtherRentals(marketId,vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetMonthlyOtherRentals?vendorId=${vendorId}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getCombinedWeeklyBookings(marketId, vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetCombinedWeeklyBookings?vendorId=${vendorId}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getCombinedWeeklyOtherRentals(marketId, vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetCombinedWeeklyOtherRentals?vendorId=${vendorId}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getWeeklyBookings(marketId,vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetWeeklyBookings?vendorId=${vendorId}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getWeeklyOtherRentals(marketId,vendorId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/Rental/GetWeeklyOtherRentals?vendorId=${vendorId}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  GetVendorProductsById(marketId, Id: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetVendorProducts?Id=${Id}&marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  getBookingFeeById(marketId,bookingFeeId): Observable<any[]> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetBookingFeeById?marketId=${marketId}&BookingFeeId=${bookingFeeId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getBookingRenewalFeeById(marketId,bookingRenewalFeeId): Observable<any[]> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/GetBookingRenewalFeeById?marketId=${marketId}&BookingRenewalFeeId=${bookingRenewalFeeId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  generatePaymentReceipt(marketId,paymentReceiptData): Observable<any[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/GeneratePaymentReceipt?marketId=${marketId}`;
    return this.http.post<any>(url, paymentReceiptData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  CheckMinimumUnusedCodeCount(marketId,RentalType): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Rental/CheckMinimumUnusedCodeCount?marketId=${marketId}&RentalType=${RentalType}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketdays = res.body as any;
          return marketdays;
        }),
        catchError(this.handleError)
      );
  }

  updateWIFIAccessCodeForRental(wifiData): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Rental/UpdateWIFIAccessCodeForRental`;
    return this.http.post<any>(url, wifiData, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }
}
