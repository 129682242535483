import { Component, OnInit } from '@angular/core';
import { ContactUsService } from 'src/app/services/ContactUs.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params } from '@angular/router';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; // Import FormGroup and FormBuilder

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsForm: FormGroup;  // Declare form group
  public PhoneNumberMask: String = '000-000-0000';
  marketId: any;
  firstNum: number;
  secondNum: number;
  public loaderIcon: Promise<boolean>;
  marketName = "";
  private subscriptions: Subscription[] = [];

  constructor(
    private contactUsService: ContactUsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private marketInfoService: MarketInfoService,
    private fb: FormBuilder // Inject FormBuilder
  ) { }

  toasterConfig = {
    closeButton: true,
    timeOut: 4500,
    easing: 'ease-out',
    progressBar: true,
    positionClass: 'toast-top-right',
    tapToDismiss: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp'
  };

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
      this.marketInfoService.setMarketId(this.marketId);
    });
    this.getMarketDetails();
    this.generateCaptcha();

    this.contactUsForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', [Validators.required,Validators.minLength(10), Validators.maxLength(12)]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      comments: ['', Validators.required],
      captchaResult: ['']
    });
  }

  getMarketDetails() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.marketInfoService.getMarketDetails(this.marketId).subscribe((res: any) => {
      if (res) {
        this.marketName = res.MarketName;
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  onSubmit(): void {
    if (this.contactUsForm.invalid) {
      this.toastr.error('Please check the form.', 'Error', this.toasterConfig);
      return;
    }

    if (!this.validateCaptcha()) {
      this.toastr.error('Captcha verification failed.', 'Error', this.toasterConfig);
      this.generateCaptcha();
      return;
    }

    const contactUsFormValue = this.contactUsForm.value;
    const contactUsForm = {
      MarketId: this.marketId,
      Name: contactUsFormValue.name,
      Phone: contactUsFormValue.phone,
      Email: contactUsFormValue.email,
      Subject: contactUsFormValue.subject,
      comments: contactUsFormValue.comments
    };

    this.submitContactUsForm(contactUsForm);
  }

  submitContactUsForm(contactUsForm) {
    this.contactUsService.SubmitContactUs(contactUsForm)
      .subscribe(
        res => {
          if (res.Success) {
            this.toastr.success('Submitted Successfully', 'Success', this.toasterConfig);
            this.resetForm();
          } else {
            this.toastr.error('Submit Unsuccessful', 'Error', this.toasterConfig);
          }
        },
        err => {
          this.toastr.error(err.Message, 'Error', this.toasterConfig);
        }
      );
  }

  generateCaptcha(): void {
    this.firstNum = Math.floor(Math.random() * 25) + 1;
    this.secondNum = Math.floor(Math.random() * 25) + 1;

    const firstNumElement = document.getElementById("firstNum");
    const secondNumElement = document.getElementById("secondNum");

    if (firstNumElement && secondNumElement) {
      firstNumElement.textContent = this.firstNum.toString();
      secondNumElement.textContent = this.secondNum.toString();
    }
  }

  validateCaptchaResult(event) {
    const key = event.key || event.keyCode;

    if (((event.keyCode >= 65 && event.keyCode <= 95) || event.keyCode >= 106 || (event.keyCode >= 48 && event.keyCode <= 57 && isNaN(event.key))) && event.keyCode != 32) {
      event.preventDefault();
    }
  }

  validateCaptcha(): boolean {
    //const enteredResult = Number((<HTMLInputElement>document.getElementById("captchaResult")).value);
    const enteredResult = Number(this.contactUsForm.value.captchaResult);

    const expectedResult = this.firstNum + this.secondNum;

    return enteredResult === expectedResult;
  }

  resetForm(): void {
    this.contactUsForm.reset();
    this.generateCaptcha();
  }
}
