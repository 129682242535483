import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root',
})

export class SharedDataService {
    public paymentSharedData;
    public dailyRentalSelectedDays;
    public dailyRentalSelectedBooths=[];
    public rentalType;
    public tempRecordNo;
    public newVendorContactInfo;
    public newVendorLoginDetails;
    public wifiSelectedDay;
}