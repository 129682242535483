import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
import { PaymentService } from 'src/app/services/paymentService.service';
import { RentalService } from 'src/app/services/Rental.service';
import { VendorService } from 'src/app/services/Vendor.service';
import { customCurrencyPipe } from 'src/app/Shared/customCurrency.pipe';
import Swal from 'sweetalert2';
import { DiscountType } from 'src/app/Shared/enum';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/services/General.service';
interface DateString {
  startDate: string;
}

@Component({
  selector: 'app-booking-details-weekly-monthly',
  templateUrl: './booking-details-weekly-monthly.component.html',
  styleUrls: ['./booking-details-weekly-monthly.component.scss']
})
export class BookingDetailsWeeklyMonthlyComponent  implements OnInit, OnDestroy {
  @Input() rentalType;
  RentalTypeText;
  voidPayment = false;
  selectedBooths = [];
  selectedMonthlyBooths =[]
  public loaderIcon: Promise<boolean>;
  private subscriptions: Subscription[] = [];
  @Input() vendorId;
  @Input() marketId;
  processPayment = false;
  paymentAmount = 0;
  vendorProducts=[];
  vendorDiscountPlans=[];
  priorBalance;
  paymentGatewayCredentials;
  PromptRenewOther = false;
  processCreditCardOnline;
  verifoneId;
  paymentOnly = false;
  masterRentChargeforResOtherItems = false;
  openConfirmationPage = false;
  creditCardAmount = 0;
  accountReceipId;
  selectedWeeklyBooths;
  private clickTimeout: any;
  selectedMarketDays = [];
  rentEndPeriodIndex;
  private monthEndDateCache = new Map<string, Date>();
  totalRentalCharge = 0.00;
  totalOtherRentalCharge = 0.00;
  totalSalesTax = 0.00;
  totalSecondLeveltax = 0.00
  totalCharge = 0.00;
  otherItemList = [];
  toasterConfig = {
    closeButton: true,
    timeOut: 4500,
    easing: 'ease-out',
    progressBar: true,
    positionClass: 'toast-top-right',
    tapToDismiss: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp'
  };
  bookingDetailsForm: FormGroup;
  selectedOtherItems = [];
  applyCCCharge = false;
  CcfeeinPercentage;
  AllowPartialPay;
  vendorRentalDays;
  marketDayCountForResale = 0;
  actualTotalDue = 0;
  renewAllBoothsFromGrid;
  keyedInTransaction = true;
  MonthlyBookingEndDate = false;
  transactionType="CREDITSALE"
  monthlyMarketDays = [];
  allrentedOtherItems = [];
  showDetails: boolean = false;
  ResaleNumberRequired  = false;
  DaysSellWithoutResaleNumber;
  BusinessLicenseNumberRequired  = false;
  DaysSellWithoutBusiLicNumber;
  resaleOptionSelected;
  resaleNoOption = "1";
  exemptResale = false;
  resaleNo = "";
  exemptResaleReason = "";
  businessLicenseNumber;
  businessLicenseExpiryDate;
  secondTaxName="";
  weeklyMarketDays =[];
  @ViewChild('modalResaleNo', { static: false }) modalResaleNo: ElementRef;
  totalDiscountCharge;
  applyRenewalFee = false;
  bookingRenewalFeeId;
  renewalFeeAmount = 0
  rules: any[] = [];
  allowWeeklyRentalRenewal = false;
  allowMonthlyRentalRenewal = false;
  allowOtherItemRenewal = false;
  constructor(
    private formbuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    public rentalService: RentalService,
    public vendorService: VendorService,
    private customCurrency: customCurrencyPipe,
    private paymentService: PaymentService,
    private toastr: ToastrService,
    private generalService: GeneralService,
    private marketInfoService: MarketInfoService,
    private datePipe: DatePipe) {
    this.bookingDetailsForm = this.formbuilder.group({
      priorBalance: [this.customCurrency.transform('0')],
      totalCharge: [this.customCurrency.transform('0')],
      paymentAmount: [this.customCurrency.transform('0')],
      convenienceFee: [this.customCurrency.transform('0')],
      totalPayment: [this.customCurrency.transform('0')],
      balanceDue: [this.customCurrency.transform('0')],
      rentalCharge: [this.customCurrency.transform('0')],
      otherCharge: [this.customCurrency.transform('0')],
      discount: [this.customCurrency.transform('0')],
      additionalCharge: [this.customCurrency.transform('0')],
      totalSalesTax: [this.customCurrency.transform('0')],
      totalSecondLeveltax: [this.customCurrency.transform('0')],
      rules: this.formbuilder.array(this.initializeRulesArray())
    });

    this.subscriptions.push(
      this.bookingDetailsForm.get('totalCharge')?.valueChanges.subscribe((val) => {
        if (!this.AllowPartialPay)
          var totalCharge = val.toString().includes('(') && val.toString().includes(')') ? -1 * parseFloat(val.toString().replace(/[^0-9.-]/g, '')) : parseFloat(val.toString().replace(/[^0-9.-]/g, ''));
        var convenienceFee = this.bookingDetailsForm.get('convenienceFee').value.toString().includes('(') && this.bookingDetailsForm.get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
        if (convenienceFee > 0) {
          val = totalCharge - convenienceFee;
          val = this.customCurrency.transform(val);
        }
        this.bookingDetailsForm.get('paymentAmount')?.setValue(val, { emitEvent: false });
        this.calculateBalance();
      })
    );
  }
    
  ngOnInit(): void {
    const rentalTypeMapping: { [key: string]: string } = {
      '2': 'Monthly',
      '3': 'Weekly'
    };

    this.rentalType = parseInt(this.rentalType);
    this.RentalTypeText = rentalTypeMapping[this.rentalType] || '';
    this.marketInfoService.setMarketId(this.marketId);
    this.getMonthlyMarketDays();
    this.getOtherRentalItems();
    //   this.getVendorProducts();
    this.getVendorBalances();
    this.getCCCredentials();
    this.getWebRentalSetting();
    this.getRentalPaymentSettings();
    this.getMarketRulesAndRegulations();
  }

  getOtherRentalItems() {
    this.rentalService.getOtherRentalItems(this.marketId).subscribe((response) => {
      if (response) {
        response.map((el, i) => {
          const ob = {
            Id: el.OtherItemId,
            OtherItem: el.OtherItem,
            OtherItemCharge: this.customCurrency.transform(el.OtherItemCombinedMonthlyCharge),
            Quantity: 0,
            OtherItemtotal: this.customCurrency.transform('0'),
            Recurring: el.Recurring,
            othChgBooths: "none"
          }
          this.otherItemList.push(ob);
        });
      }
    }, (err) => {
    });
  }

  initializeRulesArray(): FormGroup[] {
    return this.rules.map(rule => this.formbuilder.group({
      isChecked: [false],  // Initialize checkbox with false
      rulesAndRegualtions: [rule.rulesAndRegualtions],
      AgreementLinkRequired: [rule.AgreementLinkRequired],
      AgreementBlobURL: [rule.AgreementBlobURL],
      AgreementLinkTextContent: [rule.AgreementLinkTextContent]
    }));
  }

 updateArrayDirectly(index: number, updatedValue: any) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const control = this.rulesFormArray.at(index);
    if (control) {
      this.bookingDetailsForm.value['rules'][index].isChecked = isChecked;
    }
  }

 getMarketRulesAndRegulations() {
    const sub = this.generalService.getWebrentalMarketRules(this.marketId).subscribe((rules: any) => {
      if (rules) {
        const rulesArray = this.bookingDetailsForm.get('rules') as FormArray;
        rules.forEach(rule => {
          rulesArray.push(this.formbuilder.group({
            id: rule.ID,
            isChecked: false,
            rulesAndRegualtions: rule.Rules,
            AgreementLinkRequired : rule.AgreementLinkRequired ?? false,
            AgreementLinkTextContent: rule.AgreementLinkTextContent ?? "",
            AgreementBlobURL : rule.AgreementBlobURL ?? ""
          }));
        });
      }
    });
  }

 allCheckboxesCheckedValidator(control: AbstractControl) {
    var allChecked = this.bookingDetailsForm.value['rules'].filter(e=>!e.isChecked).length > 0 ? false : true;
    return allChecked;
  }

  get rulesFormArray() {
    return this.bookingDetailsForm.value['rules'] as FormArray;
  }

  getVendorProducts() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.rentalService.GetVendorProductsById(this.marketId, this.vendorId).subscribe((res: any) => {
      if (res) {
        this.vendorProducts = res.body;
        this.loaderIcon = Promise.resolve(false);
      }    
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  ClosePaymentPage(){
    this.processPayment = false;
  }

  getMonthlyMarketDays() {
    forkJoin({
      response: this.marketInfoService.getMonthlyMarketDays(this.marketId),
      weeklyMarket: this.marketInfoService.getWeeklyMarketDays(this.marketId),
      resRentalSettings: this.rentalService.getRentalSettings(this.marketId),
      vendorProducts: this.rentalService.GetVendorProductsById(this.marketId, this.vendorId)
    }).subscribe(
      async ({ response, resRentalSettings, vendorProducts,weeklyMarket }: any) => {
        if (response) {
          this.monthlyMarketDays = response;
        }
        if(weeklyMarket){
          this.weeklyMarketDays = weeklyMarket;
        }
        if (vendorProducts) {
          this.vendorProducts = vendorProducts.body;
        }

        if (resRentalSettings) {
          this.MonthlyBookingEndDate = resRentalSettings.MonthlyBookingEndDate;
          if (this.MonthlyBookingEndDate && this.rentalType == 4) {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const currentYearMonth = `${currentYear}${(currentMonth + 1) < 10 ? '0' : ''}${(currentMonth + 1)}`;
            const currentMonthEndDate =await this.getMonthEndingDateFromSetting(currentYearMonth);
            if (currentMonthEndDate <= new Date(currentDate)) {
              var currentDateYearAndMonth = this.datePipe.transform(currentDate, 'MMMM yyyy');
              this.monthlyMarketDays = response.filter(e => e.RentalMonths != currentDateYearAndMonth)
            }
          }
        }
        // this.selectedMonthlyMarketDays();  
        if (this.rentalType == 2) {
          this.getMonthlyBookings();
          this.getMonthlyOtherRentals();
        }
        else if(this.rentalType == 3){
          this.getWeeklyRental();
          this.getWeeklyOtherRentals();
        }
      }, (err) => {
      });
  }

  getMonthIndex(monthString: string): number {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthIndex = months.findIndex(month => month.toLowerCase() === monthString.toLowerCase());
    return monthIndex !== -1 ? monthIndex : 0;
  }

  getVendorBalances() {
    this.rentalService.getVendorBalances(this.vendorId, this.marketId).subscribe((res: any) => {
     if (res) {
        this.priorBalance = res.PriorBalance;
        this.actualTotalDue = res.PriorBalance;
        this.bookingDetailsForm.patchValue({
          priorBalance: this.customCurrency.transform(this.priorBalance),
          totalCharge: this.customCurrency.transform(this.priorBalance)
        });
      }
    });
  }

  getMonthEndDate(inputDate: Date): Date {
    const nextMonthFirstDay = new Date(inputDate);
    nextMonthFirstDay.setMonth(nextMonthFirstDay.getMonth() + 1, 1);
    const monthEndDate = new Date(nextMonthFirstDay);
    monthEndDate.setDate(monthEndDate.getDate() - 1);
    return monthEndDate;
  }

 toggleDetails(index: number) {
    this.selectedMonthlyBooths[index].showDetails = !this.selectedMonthlyBooths[index].showDetails;
  }

  toggleOtherItemsDetails(index: number) {
    this.selectedOtherItems[index].showOtherItemsDetails = !this.selectedOtherItems[index].showOtherItemsDetails;
  }

  toggleWeeklyBookingDetails(index: number) {
    this.selectedWeeklyBooths[index].showDetails = !this.selectedWeeklyBooths[index].showDetails;
  }

  async getMonthlyBookings(): Promise<any> {
    this.loaderIcon = Promise.resolve(true);
    this.selectedMonthlyBooths = [];
    var existingBooths = [];
    const sub = this.rentalService.getMonthlyBookings(this.marketId, this.vendorId).subscribe(async (res: any[]) => {
      if (res) {
        existingBooths = res;
        this.bindMonthlyRentalData(existingBooths);
      }
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  async bindMonthlyRentalData(existingBooths) {
    for (const el of existingBooths) {
      const StartDateList = [];
      var initialStartDate;
      var marketDays = 0;
      var marketDay;
      const today: Date = new Date(new Date().toDateString());

      var startDate = new Date(el.RentalStartDate);
      var endDate = new Date(el.RentalEndDate);
      if (el.Status == "R" && startDate < today) {
        startDate = today;
        initialStartDate = startDate;
        endDate = this.getMonthEndDate(startDate);
      }

      var selectedDayOfWeek = new Date(el.RentalStartDate).getDay();
      marketDay = this.datePipe.transform(el.RentalStartDate, 'EEE');
      let currentDay = new Date(startDate);
      while (currentDay <= endDate) {
          if (currentDay.getDay() === selectedDayOfWeek) {
            marketDays += 1;
            StartDateList.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
        }

        if (this.datePipe.transform(currentDay, 'MM/dd/yyyy') == this.datePipe.transform(endDate, 'MM/dd/yyyy')) {
          if (StartDateList && StartDateList.length == 0) {
            endDate.setDate(endDate.getDate() + 1);
            endDate = this.getMonthEndDate(endDate);
          }
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }
      const compareDates = (a: DateString, b: DateString): number => {
        const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
        const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
        return dateA - dateB;
      };
      const sortedDates = StartDateList.sort(compareDates);
      switch (el) {
        case 1: marketDay = 'Sun'
          break;
        case 2: marketDay = 'Mon'
          break;
        case 3: marketDay = 'Tue'
          break;
        case 4: marketDay = 'Wed'
          break;
        case 5: marketDay = 'Thu'
          break;
        case 6: marketDay = 'Fri'
          break;
        case 7: marketDay = 'Sat'
          break;
      }

      if (el.Status == "R") {
        if (initialStartDate <= today) {
          el.RentalStartDate = StartDateList[0].startDate
          el.RentalEndDate = StartDateList[StartDateList.length - 1].startDate;
        }
      }

      const rentEndPeriodIndex = this.monthlyMarketDays.findIndex(e => e.RentalMonths == this.datePipe.transform(el.RentalEndDate, 'MMMM yyyy'));
      var renewalStartMonth = this.monthlyMarketDays[rentEndPeriodIndex +1].RentalMonths

      if (el.Status == "R") {
        renewalStartMonth = this.monthlyMarketDays[rentEndPeriodIndex].RentalMonths
        var obj = {
          BoothTypeId: el.BoothTypeId,
          VendorId: this.vendorId,
          RentalStartPeriod: new Date(el.RentalStartDate),
          RentalEndPeriod: new Date(el.RentalEndDate),
          RentalMarketDay: marketDay
        }
        const res = await this.rentalService.getBoothRentalMontlyCharges(this.marketId, obj).toPromise();
        if (res) {
          const ob = {
            MonthlyRentalId: el.MonthlyRentalId,
            BoothNumber: el.BoothNumber,
            BoothType: el.BoothType,
            BoothTypeId: el.BoothTypeId,
            RentalStartDate: this.datePipe.transform(el.RentalStartDate, 'MMM d, y'),
            RentalEndDate: this.datePipe.transform(el.RentalEndDate, 'MMM d, y'),
            RentalSavedEndDate: this.datePipe.transform(el.RentalStartDate, 'MMM d, y'),
            RentStartPeriod: this.datePipe.transform(el.RentalStartDate, 'MMMM yyyy'),
            RentEndPeriod: this.datePipe.transform(el.RentalEndDate, 'MMMM yyyy'),
            Product: el.ProductId,
            ProductName: this.vendorProducts.filter(e => e.ProductId == el.ProductId)[0]?.ProductName,
            StartDateList: StartDateList,
            MktDays: marketDays,
            PerDiemRent: this.customCurrency.transform('0.00'),
            Rent: this.customCurrency.transform(res.Rent),
            SalesTax: this.customCurrency.transform(res.SalesTax),
            CityTax: this.customCurrency.transform(res.CityTax),
            CurrentRentStartPeriod: this.datePipe.transform(el.RentalStartDate, 'MMMM yyyy'),
            CurrentRentEndPeriod: this.datePipe.transform(el.RentalEndDate, 'MMMM yyyy'),
            CurrentRent: this.customCurrency.transform(el.RentalCharge),
            CurrentSalesTax: this.customCurrency.transform(el.SalesTax),
            CurrentSecondTax: this.customCurrency.transform(el.SecLevelTax),
            Sunday: el.Sunday,
            Monday: el.Monday,
            Tuesday: el.Tuesday,
            Wednesday: el.Wednesday,
            Thursday: el.Thursday,
            Friday: el.Friday,
            Saturday: el.Saturday,
            Reservation: el.BoothReserved,
            Drop: el.DoNotRenew,
            January: this.customCurrency.transform(res.JanRent),
            February: this.customCurrency.transform(res.FebRent),
            March: this.customCurrency.transform(res.MarRent),
            April: this.customCurrency.transform(res.AprRent),
            May: this.customCurrency.transform(res.MayRent),
            June: this.customCurrency.transform(res.JunRent),
            July: this.customCurrency.transform(res.JulRent),
            August: this.customCurrency.transform(res.AugRent),
            September: this.customCurrency.transform(res.SepRent),
            October: this.customCurrency.transform(res.OctRent),
            November: this.customCurrency.transform(res.NovRent),
            December: this.customCurrency.transform(res.DecRent),
            JanuaryMonthPresent: res.JanuaryMonthPresent,
            FebruaryMonthPresent: res.FebruaryMonthPresent,
            MarchMonthPresent: res.MarchMonthPresent,
            AprilMonthPresent: res.AprilMonthPresent,
            MayMonthPresent: res.MayMonthPresent,
            JuneMonthPresent: res.JuneMonthPresent,
            JulyMonthPresent: res.JulyMonthPresent,
            AugustMonthPresent: res.AugustMonthPresent,
            SeptemberMonthPresent: res.SeptemberMonthPresent,
            OctoberMonthPresent: res.OctoberMonthPresent,
            NovemberMonthPresent: res.NovemberMonthPresent,
            DecemberMonthPresent: res.DecemberMonthPresent,
            DisableJanMonth: res.JanRent ? false : true,
            DisableFebMonth: res.FebRent ? false : true,
            DisableMarMonth: res.MarRent ? false : true,
            DisableAprMonth: res.AprRent ? false : true,
            DisableMayMonth: res.MayRent ? false : true,
            DisableJunMonth: res.JunRent ? false : true,
            DisableJulMonth: res.JulRent ? false : true,
            DisableAugMonth: res.AugRent ? false : true,
            DisableSepMonth: res.SepRent ? false : true,
            DisableOctMonth: res.OctRent ? false : true,
            DisableNovMonth: res.NovRent ? false : true,
            DisableDecMonth: res.DecRent ? false : true,
            DiasbleStartDate: false,
            Status: el.Status,
            NewBooth: false,
            ExtendRental: true,
            BookingCanExtend: true,
            AllRentalDates: StartDateList.map(el => new Date(el.startDate)),
            RenewalStartMonth: renewalStartMonth,
            MonthlyRenewalMonth: this.monthlyMarketDays[rentEndPeriodIndex].RentalMonths,
            RentEndPeriodIndex: rentEndPeriodIndex
          }
          if (this.allowMonthlyRentalRenewal) {
            this.totalRentalCharge += res.Rent.toString().includes('(') && res.Rent.toString().includes(')') ? -1 * parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, ''));
            this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
            this.bookingDetailsForm.patchValue({
              rentalCharge: this.customCurrency.transform(this.totalRentalCharge.toString()),
              totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
              totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
              totalCharge: this.customCurrency.transform(this.totalCharge.toString())
            });
          }
          this.selectedMonthlyBooths.push(ob);
        }
      }
      else {
        var rentalStartPeriod;
        if (this.MonthlyBookingEndDate && this.rentalType == 4) {
          let rentalStartDate = new Date(el.RentalStartDate);
          var startMonth = rentalStartDate.getMonth() + 1;
          var startYear = rentalStartDate.getFullYear();
          const startYearAndMonth = `${startYear}${startMonth < 10 ? '0' : ''}${startMonth}`;
          const rentalMonthEndDate = await this.getMonthEndingDateFromSetting(startYearAndMonth);

          if (new Date(el.RentalStartDate) > rentalMonthEndDate) {
            rentalStartDate = new Date(el.RentalStartDate);
            const dayOfMonth = rentalStartDate.getDate();
            rentalStartDate.setMonth(rentalStartDate.getMonth() + 1);

            if (rentalStartDate.getDate() < dayOfMonth) {
              rentalStartDate.setDate(0); // Set to the last day of the previous month
            }
            rentalStartPeriod = this.datePipe.transform(rentalStartDate, 'MMMM yyyy');
          }
          else {
            rentalStartPeriod = this.datePipe.transform(el.RentalStartDate, 'MMMM yyyy');
          }
        }
        else {
          rentalStartPeriod = this.datePipe.transform(el.RentalStartDate, 'MMMM yyyy');
        }

        const ob = {
          MonthlyRentalId: el.MonthlyRentalId,
          BoothNumber: el.BoothNumber,
          BoothType: el.BoothType,
          BoothTypeId: el.BoothTypeId,
          RentalStartDate: this.datePipe.transform(el.RentalStartDate, 'MMM d, y'),
          RentalEndDate: this.datePipe.transform(el.RentalEndDate, 'MMM d, y'),
          RentalSavedEndDate: this.datePipe.transform(el.RentalEndDate, 'MMM d, y'),
          RentStartPeriod: rentalStartPeriod,
          RentEndPeriod: this.datePipe.transform(el.RentalEndDate, 'MMMM yyyy'),
          Product: el.ProductId,
          ProductName: this.vendorProducts.filter(e => e.ProductId == el.ProductId)[0]?.ProductName,
          StartDateList: StartDateList,
          MktDays: marketDays,
          PerDiemRent: this.customCurrency.transform('0.00'),
          Rent: this.customCurrency.transform('0.00'),
          SalesTax: this.customCurrency.transform('0.00'),
          CityTax: this.customCurrency.transform('0.00'),
          CurrentRentStartPeriod: this.datePipe.transform(el.RentalStartDate, 'MMMM yyyy'),
          CurrentRentEndPeriod: this.datePipe.transform(el.RentalEndDate, 'MMMM yyyy'), 
          CurrentRent: this.customCurrency.transform(el.RentalCharge),
          CurrentSalesTax: this.customCurrency.transform(el.SalesTax),
          CurrentSecondTax: this.customCurrency.transform(el.SecLevelTax),
          Sunday: el.Sunday,
          Monday: el.Monday,
          Tuesday: el.Tuesday,
          Wednesday: el.Wednesday,
          Thursday: el.Thursday,
          Friday: el.Friday,
          Saturday: el.Saturday,
          Reservation: el.BoothReserved,
          Drop: el.DoNotRenew,
          Status: el.Status,
          BookingCanExtend: true,
          January: this.customCurrency.transform('0.00'),
          February: this.customCurrency.transform('0.00'),
          March: this.customCurrency.transform('0.00'),
          April: this.customCurrency.transform('0.00'),
          May: this.customCurrency.transform('0.00'),
          June: this.customCurrency.transform('0.00'),
          July: this.customCurrency.transform('0.00'),
          August: this.customCurrency.transform('0.00'),
          September: this.customCurrency.transform('0.00'),
          October: this.customCurrency.transform('0.00'),
          November: this.customCurrency.transform('0.00'),
          December: this.customCurrency.transform('0.00'),
          JanuaryMonthPresent: el.JanuaryMonthPresent,
          FebruaryMonthPresent: el.FebruaryMonthPresent,
          MarchMonthPresent: el.MarchMonthPresent,
          AprilMonthPresent: el.AprilMonthPresent,
          MayMonthPresent: el.MayMonthPresent,
          JuneMonthPresent: el.JuneMonthPresent,
          JulyMonthPresent: el.JulyMonthPresent,
          AugustMonthPresent: el.AugustMonthPresent,
          SeptemberMonthPresent: el.SeptemberMonthPresent,
          OctoberMonthPresent: el.OctoberMonthPresent,
          NovemberMonthPresent: el.NovemberMonthPresent,
          DecemberMonthPresent: el.DecemberMonthPresent,
          DisableJanMonth: true,
          DisableFebMonth: true,
          DisableMarMonth: true,
          DisableAprMonth: true,
          DisableMayMonth: true,
          DisableJunMonth: true,
          DisableJulMonth: true,
          DisableAugMonth: true,
          DisableSepMonth: true,
          DisableOctMonth: true,
          DisableNovMonth: true,
          DisableDecMonth: true,
          DiasbleStartDate: true,
          NewBooth: false,
          AllRentalDates: StartDateList.map(el => new Date(el.startDate)),
          RenewalStartMonth: renewalStartMonth,
          MonthlyRenewalMonth: this.monthlyMarketDays[rentEndPeriodIndex + 1].RentalMonths,
          RentEndPeriodIndex:rentEndPeriodIndex
        }
        this.selectedMonthlyBooths.push(ob);
      }
    };

    await this.handleMonthlyEndDateChange();
    this.loaderIcon = Promise.resolve(false);
  }

  getDaysDifference(date1: Date, date2: Date): number {
    // Normalize both dates to start of the day (midnight)
    const start = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const end = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    // Calculate the difference in time (milliseconds)
    const timeDiff = Math.abs(end.getTime() - start.getTime());
    // Convert time difference from milliseconds to days
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return dayDiff;
  }

  async handleMonthlyEndDateChange() {
    this.renewAllBoothsFromGrid = false;
    if (this.selectedMonthlyBooths.length === 0) {
      await this.renewAllBooths();
      return;
    }
  
    this.renewAllBoothsFromGrid = true;
    let rentalDates: any[] = [];
    let noResale = true;
    this.vendorRentalDays = [];
    this.marketDayCountForResale = 0;
  
    if ((this.ResaleNumberRequired && !this.exemptResale) || this.BusinessLicenseNumberRequired) {
      await this.processSelectedBooths(rentalDates);
  
      for (const item of this.selectedMonthlyBooths) {
        if (this.shouldProcessBooth(item)) {
          await this.processBoothRentalDates(item, rentalDates);
        }
      }
  
      this.updateVendorRentalDays(rentalDates);
  
      const vendorRentalDaysObj = { VendorID: this.vendorId, VendorRentalDate: new Date() };
      const vendorRentalDaysRes = await this.rentalService.getVendorRentalDays(vendorRentalDaysObj).toPromise();
  
      if (this.marketDayCountForResale <= this.DaysSellWithoutResaleNumber ||
          this.marketDayCountForResale <= this.DaysSellWithoutBusiLicNumber) {
        this.addVendorRentalDays(vendorRentalDaysRes);
      }
  
      await this.checkResaleOrBusinessLicense(noResale);
    } else {
      await this.renewAllBooths();
    }
  }

  updateMonthlytherItemRecord(record){
    if (record.Recurring) {
      const rentEndPeriodIndex = this.monthlyMarketDays.findIndex(e => e.RentalMonths == record.RentalMonth);
      const renewalStartMonth = this.monthlyMarketDays[rentEndPeriodIndex + 1].RentalMonths;
      record.BookingCanExtend = true;
      record.Quantity = 1;
      record.RenewalStartMonth = renewalStartMonth;
      record.MonthlyRenewalMonth = renewalStartMonth;
      record.RentEndPeriodIndex = rentEndPeriodIndex
      this.extendMonthlyOtherRental(record);
    }
    else {    
      record.BookingCanExtend = false;
      record.RenewalStartWeek = "This booking cannot be extended further!";
      record.MonthlyRenewalMonth = record.RentalMonth;
      this.totalOtherRentalCharge -= record.OtherItemtotal.toString().includes('(') && record.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(record.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
      this.totalSalesTax -= record.SalesTax.toString().includes('(') && record.SalesTax.toString().includes(')') ? -1 * parseFloat(record.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.SalesTax.toString().replace(/[^0-9.-]/g, ''));
      this.totalSecondLeveltax -= record.CityTax.toString().includes('(') && record.CityTax.toString().includes(')') ? -1 * parseFloat(record.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.CityTax.toString().replace(/[^0-9.-]/g, ''));
      this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
      this.bookingDetailsForm.patchValue({
        otherCharge: this.customCurrency.transform(this.totalOtherRentalCharge.toString()),
        totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
        totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
        totalCharge: this.customCurrency.transform(this.totalCharge.toString())
      });
      record.OtherItemtotal = this.customCurrency.transform("0");
      record.Quantity = 0;
      record.OtherItemCharge = this.customCurrency.transform("0");
    }
  }

  extendMonthlyOtherRental(item) {
    const endMonth = new Date(item.MonthlyRenewalMonth);
    const currentMonth = new Date(item.RentalMonth);
    if (currentMonth < new Date(item.MonthlyRenewalMonth) && item.Recurring && this.allowOtherItemRenewal) {
      var nextDate = currentMonth.setMonth(currentMonth.getMonth() + 1);
      var rentalMonthDate = this.monthlyMarketDays.filter(date => this.datePipe.transform(new Date(date.RentalMonths), 'MM/dd/yyyy') <= this.datePipe.transform(new Date(nextDate), 'MM/dd/yyyy')
        && this.datePipe.transform(new Date(date.RentalMonths), 'MM/dd/yyyy') >= this.datePipe.transform(new Date(nextDate), 'MM/dd/yyyy'))
      if (rentalMonthDate.length > 0)
        var rentalStartMonth = rentalMonthDate[0].RentalMonths;

      var recordExist = this.selectedOtherItems.findIndex(e => e.RentalMonth == rentalStartMonth && e.Id == item.Id && e.Reference == item.Reference && e.BoothNumber == item.BoothNo)
      var otherItemCharge = this.otherItemList.filter(e => e.Id == item.Id)[0].OtherItemCharge;
      if (!this.masterRentChargeforResOtherItems && this.allrentedOtherItems.length > 0) {
        otherItemCharge = this.customCurrency.transform(this.allrentedOtherItems.filter(e => e.OtherItemId == item.Id)[0].OtherCharge);
      }
      var obj = {
        MarketId: this.marketId,
        OtherItemId: item.Id,
        VendorId: this.vendorId,
        Quantity: item.Quantity,
        OtherItemCharge: otherItemCharge.includes('(') && otherItemCharge.includes(')') ? (-1) * parseFloat(otherItemCharge.replace(/[^0-9.-]/g, '')) : parseFloat(otherItemCharge.replace(/[^0-9.-]/g, ''))
      }

      this.rentalService.getOtherItemsRentalCharges(obj).subscribe((res: any) => {
        const isNegative = otherItemCharge.includes('(') && otherItemCharge.includes(')');
        const totalValue = parseFloat(otherItemCharge.replace(/[^0-9.-]/g, ''));
        const finalCharge = isNegative ? -totalValue : totalValue;
        item.OtherItemtotal =this.customCurrency.transform((parseFloat(item.OtherItemtotal.replace(/[^0-9.-]/g, '')) + (finalCharge * item.Quantity)).toString());
        item.Quantity += item.Quantity;
        item.MonthlyRentalIds += ',' + item.Id
        item.MonthlyRenewalMonth = rentalStartMonth;

        this.totalOtherRentalCharge += item.OtherItemtotal.toString().includes('(') && item.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(item.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(item.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
        this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
        this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
        this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
        this.bookingDetailsForm.patchValue({
          otherCharge: this.customCurrency.transform(this.totalOtherRentalCharge.toString()),
          totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
          totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
          totalCharge: this.customCurrency.transform(this.totalCharge.toString())
        });
      });
    }
    else {
      item.BookingCanExtend = false;
      item.RenewalStartMonth = "This booking cannot be extended further!";
      item.MonthlyRenewalMonth = item.RentalMonth;
    }
  }

  getDateFromDateTime(date){
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  renewMonthlyOtherCharges() {
    if (this.selectedOtherItems.length > 0) {
          this.proceedRenewingOtherCharges();
    }
  }

  async proceedRenewingOtherCharges(){
    for(const item of this.selectedOtherItems){
       const endMonth = new Date(item.MonthlyRenewalMonth);
       const currentMonth = new Date(item.RentalMonth);
       if (this.getDateFromDateTime(currentMonth) <= this.getDateFromDateTime(new Date(item.MonthlyRenewalMonth)) && item.Recurring && this.allowOtherItemRenewal) {
         var nextDate = currentMonth.setMonth(currentMonth.getMonth() + 1);
         var rentalMonthDate = this.monthlyMarketDays.filter(date => this.datePipe.transform(new Date(date.RentalMonths), 'MM/dd/yyyy') <= this.datePipe.transform(new Date(nextDate), 'MM/dd/yyyy')
           && this.datePipe.transform(new Date(date.RentalMonths), 'MM/dd/yyyy') >= this.datePipe.transform(new Date(nextDate), 'MM/dd/yyyy'))
         if (rentalMonthDate.length > 0)
           var rentalEndMonth = rentalMonthDate[0].RentalMonths;
 
         var otherItemCharge = this.otherItemList.filter(e => e.Id == item.Id)[0].OtherItemCharge;
         if (!this.masterRentChargeforResOtherItems && this.allrentedOtherItems.length > 0) {
           otherItemCharge = this.customCurrency.transform(this.allrentedOtherItems.filter(e => e.OtherItemId == item.Id)[0].OtherCharge);
         }
         var obj = {
           MarketId: this.marketId,
           OtherItemId: item.Id,
           VendorId: this.vendorId,
           Quantity: item.Quantity,
           OtherItemCharge: otherItemCharge.includes('(') && otherItemCharge.includes(')') ? (-1) * parseFloat(otherItemCharge.replace(/[^0-9.-]/g, '')) : parseFloat(otherItemCharge.replace(/[^0-9.-]/g, ''))
         }
 
         var res = await this.rentalService.getOtherItemsRentalCharges(obj).toPromise();
         const isNegative = otherItemCharge.includes('(') && otherItemCharge.includes(')');
         const totalValue = parseFloat(otherItemCharge.replace(/[^0-9.-]/g, ''));
         const finalCharge = isNegative ? -totalValue : totalValue;
         const OtherItemtotal = this.customCurrency.transform((finalCharge * item.Quantity).toString());
         item.Quantity = item.Quantity;
         item.MonthlyRentalIds += ',' + item.Id
         item.OtherItemtotal = OtherItemtotal;
         item.MonthlyRenewalMonth = rentalEndMonth;
 
         this.totalOtherRentalCharge += item.OtherItemtotal.toString().includes('(') && item.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(item.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(item.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
         this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
         this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
         this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
         this.bookingDetailsForm.patchValue({
           otherCharge: this.customCurrency.transform(this.totalOtherRentalCharge.toString()),
           totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
           totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
           totalCharge: this.customCurrency.transform(this.totalCharge.toString())
         });
         
       }
       else{
         item.BookingCanExtend = false;
         item.RenewalStartMonth = "This booking cannot be extended further!";
         item.MonthlyRenewalMonth = item.RentalMonth;
       }
     }
   }  
  // Helper functions
  
  // Process selected booths for rental dates
  async processSelectedBooths(rentalDates: any[]) {
    const selectedBoothRecord = this.selectedMonthlyBooths.filter(e => e.NewBooth || e.ExtendRental);
    for (const record of selectedBoothRecord) {
      for (const el of this.selectedMarketDays) {
        this.addRentalDates(record, rentalDates, el - 1);
      }
    }
  }
  
  // Check if the booth should be processed
  shouldProcessBooth(item: any): boolean {
    return new Date(item.RentEndPeriod.split('-')[0]) < new Date(item.MonthlyRenewalMonth) &&
      !item.Drop && item.Reservation;
  }
  
  // Process rental dates for a booth
  async processBoothRentalDates(item: any, rentalDates: any[]) {
    const recordExist = this.selectedMonthlyBooths.findIndex(e =>
      e.RentStartPeriod === item.RentStartPeriod && e.RentEndPeriod === item.RentEndPeriod &&
      e.BoothNumber === item.BoothNumber && (item.MonthlyRentalId === 0 || e.MonthlyRentalId === item.MonthlyRentalId)
    );
  
    if (recordExist !== -1) {
      const startDate = new Date(this.selectedMonthlyBooths[recordExist].RentalStartDate);
      let endDate = await this.calculateEndDate(item);
  
      // Loop through selected market days and add dates
      this.selectedMarketDays.forEach((el) => {
        this.addRentalDates({ RentalStartDate: startDate, RentalEndDate: endDate }, rentalDates, el - 1);
      });
    }
  }
  
  // Add rental dates based on the day of the week
  addRentalDates(record: any, rentalDates: any[], dayOfWeek: number) {
    let currentDay = new Date(record.RentalStartDate);
    const endDate = new Date(record.RentalEndDate);
  
    while (currentDay <= endDate) {
      if (currentDay.getDay() === dayOfWeek) {
        rentalDates.push({ startDate: this.datePipe.transform(currentDay, 'MMM d, y') });
      }
      currentDay.setDate(currentDay.getDate() + 1);
    }
  }
  
  // Calculate end date based on MonthlyRenewalMonth
  async calculateEndDate(item: any): Promise<Date> {
    const [endMonthDate, endMonthYear] = item.MonthlyRenewalMonth.split(' ');
    const endMonthIndex = this.getMonthIndex(endMonthDate);
    let endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
  
    if (this.MonthlyBookingEndDate) {
      const rentalEndDateYearMonth = `${endDate.getFullYear()}${(endDate.getMonth() + 1).toString().padStart(2, '0')}`;
      endDate = await this.getMonthEndingDateFromSetting(rentalEndDateYearMonth);
    }
  
    return endDate;
  }
  
  // Update vendor rental days by comparing with existing rental dates
  updateVendorRentalDays(rentalDates: any[]) {
    rentalDates.forEach(date => {
      const targetDateString = this.datePipe.transform(date.startDate, "MM/dd/yyyy");
      const dateExist = this.vendorRentalDays.some(el => {
        const rentalDateString = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
        return rentalDateString === targetDateString;
      });
  
      if (!dateExist) {
        const formattedDate = this.datePipe.transform(date.startDate, "MM/dd/yyyy");
        this.vendorRentalDays.push({ VendorRentalDate: formattedDate });
        this.marketDayCountForResale += 1;
      }
    });
  }

  disableSavePayment() {
    const paymentAmount = this.bookingDetailsForm.get('totalPayment').value.toString().includes('(') && this.bookingDetailsForm.get('totalPayment').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('totalPayment').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('totalPayment').value.toString().replace(/[^0-9.-]/g, ''));
    if (paymentAmount > 0) {
      return false
    }
    else
      return true
  }
  
  // Add vendor rental days from API response
  addVendorRentalDays(res: any[]) {
    res.forEach(el => {
      const targetDateString = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
      const dateExist = this.vendorRentalDays.some(data => {
        const rentalDateString = this.datePipe.transform(data.VendorRentalDate, "MM/dd/yyyy");
        return rentalDateString === targetDateString;
      });
  
      if (!dateExist) {
        const formattedDate = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
        this.vendorRentalDays.push({ VendorRentalDate: formattedDate });
        this.marketDayCountForResale += 1;
      }
    });
  }
  
  // Check conditions for resale or business license
  async checkResaleOrBusinessLicense(noResale: boolean) {
    if (this.ResaleNumberRequired && !this.exemptResale && !this.resaleNo && 
        this.marketDayCountForResale > this.DaysSellWithoutResaleNumber && !this.resaleOptionSelected) {
      const modal = this.modalResaleNo.nativeElement;
      modal.classList.add('show');
      modal.style.display = 'block';
      noResale = false;
    } else if (this.BusinessLicenseNumberRequired && 
               this.marketDayCountForResale > this.DaysSellWithoutBusiLicNumber) {
      await this.handleBusinessLicenseNumber();
    } else {
      await this.renewAllBooths();
    }
  }
  
  // Handle business license number validation
  async handleBusinessLicenseNumber() {
    if (!this.businessLicenseNumber) {
      const result = await Swal.fire({
        title: $localize`Confirm`,
        text: $localize`Business License Number is required! Do you want to continue without Business License Number?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: $localize`Yes`,
        cancelButtonText: $localize`No`,
      });
  
      if (result.value) {
        await this.renewAllBooths();
      }
    } else {
      await this.checkBusinessLicenseExpiry();
    }
  }
  
  // Check business license expiry date
  async checkBusinessLicenseExpiry() {
    if (!this.businessLicenseExpiryDate) {
      const result = await Swal.fire({
        title: $localize`Confirm`,
        text: $localize`Business License expiry date is not provided! Do you want to continue without that?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: $localize`Yes`,
        cancelButtonText: $localize`No`,
      });
  
      if (result.value) {
        await this.renewAllBooths();
      }
    } else if (new Date(this.businessLicenseExpiryDate) < new Date()) {
      const result = await Swal.fire({
        title: $localize`Confirm`,
        text: $localize`Business License is expired! Do you want to continue?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: $localize`Yes`,
        cancelButtonText: $localize`No`,
      });
  
      if (result.value) {
        await this.renewAllBooths();
      }
    } else {
     await this.renewAllBooths();
    }
  }
  

  async getMonthEndingDateFromSetting(rentalYearMonth: string): Promise<Date> {
    if (this.monthEndDateCache.has(rentalYearMonth)) {
      return this.monthEndDateCache.get(rentalYearMonth);
    }
    const response = await this.rentalService.GetSelectedMonthEndDate(rentalYearMonth,this.marketId).toPromise();
    if (response) {
      const monthEndDate = new Date(response.MonthEndDateSelected);
      this.monthEndDateCache.set(rentalYearMonth, monthEndDate);
      return monthEndDate;
    }
    else {
      return null
    }
  }

  async renewAllBooths() {
    const nonAvailableBooths = [];

    for (const item of this.selectedMonthlyBooths) {
      if (item.Status != "R") {
        const { RentEndPeriod, MonthlyRenewalMonth, Drop, Reservation, MonthlyRentalId, BoothNumber, RentStartPeriod } = item;
        const rentEndDate = new Date(RentEndPeriod.split('-')[0]);
        const renewalMonth = new Date(MonthlyRenewalMonth);

        if (rentEndDate < renewalMonth && !Drop && Reservation && this.allowMonthlyRentalRenewal) {
          const recordExist = this.selectedMonthlyBooths.findIndex(e =>
            e.RentStartPeriod === RentStartPeriod &&
            e.RentEndPeriod === RentEndPeriod &&
            e.BoothNumber === BoothNumber &&
            e.MonthlyRentalId === MonthlyRentalId &&
            e.Reservation && !e.Drop
          );

          if (recordExist != -1) {
            const rentalDates = [];
            var currentMonthRentalDates = [];
            var rentalstartDate = "";
            var marketDays = 0;
            this.selectedMarketDays = [];
            this.selectedMonthlyBooths[recordExist].Sunday == true ? this.selectedMarketDays.push(1) : 0;
            this.selectedMonthlyBooths[recordExist].Monday ? this.selectedMarketDays.push(2) : 0;
            this.selectedMonthlyBooths[recordExist].Tuesday == true ? this.selectedMarketDays.push(3) : 0;
            this.selectedMonthlyBooths[recordExist].Wednesday == true ? this.selectedMarketDays.push(4) : 0;
            this.selectedMonthlyBooths[recordExist].Thursday == true ? this.selectedMarketDays.push(5) : 0;
            this.selectedMonthlyBooths[recordExist].Friday == true ? this.selectedMarketDays.push(6) : 0;
            this.selectedMonthlyBooths[recordExist].Saturday == true ? this.selectedMarketDays.push(7) : 0;
            for (const el of this.selectedMarketDays) {
              const currentDate = new Date();
              const currentYear = currentDate.getFullYear();
              const currentMonth = currentDate.getMonth();
              var endDateYearMonth;
              const currentYearMonth = `${currentYear}${(currentMonth + 1) < 10 ? '0' : ''}${(currentMonth + 1)}`;
              var startDate = new Date(this.selectedMonthlyBooths[recordExist].RentalStartDate);
              const endMonth = item.MonthlyRenewalMonth;
              const [endMonthDate, endMonthYear] = endMonth.split(' ');
              const endMonthIndex = this.getMonthIndex(endMonthDate);
              var endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
              var previousRentalYearMonth;
              if (this.MonthlyBookingEndDate) {
                const rentalEndDateYear = endDate.getFullYear();
                const rentalEndDateMonth = endDate.getMonth() + 1;
                const rentalEndDateYearMonth = `${rentalEndDateYear}${rentalEndDateMonth < 10 ? '0' : ''}${rentalEndDateMonth}`;
                endDate = await this.getMonthEndingDateFromSetting(rentalEndDateYearMonth);
                endDateYearMonth = rentalEndDateYearMonth;

                const rentalStartDate = new Date(startDate);
                var previousMonth = new Date(rentalStartDate.setMonth(rentalStartDate.getMonth() - 1)).getMonth() + 1;
                var PreviousMonthYear = new Date(rentalStartDate.setMonth(rentalStartDate.getMonth() - 1)).getFullYear();
                const PreviousMonthYearAndMonth = `${PreviousMonthYear}${previousMonth < 10 ? '0' : ''}${previousMonth}`;
                // if (PreviousMonthYearAndMonth >= currentYearMonth) {
                const monthEndDate = await this.getMonthEndingDateFromSetting(PreviousMonthYearAndMonth);
                const dateDiff = this.getDaysDifference(monthEndDate, new Date(startDate)); //calculates the diff between current month start date and previous monthend date     
                if (dateDiff <= 7 && new Date(monthEndDate) < startDate) {
                  if (new Date() > new Date(monthEndDate)) {
                    startDate = new Date();
                  }
                  else {
                    startDate = new Date(monthEndDate.setDate(monthEndDate.getDate() + 1));
                  }
                }
                // }
              }
              var previousRentalYearMonth;
              var currentMonthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
              if (this.MonthlyBookingEndDate) {
                const currentStartMonth = this.selectedMonthlyBooths[recordExist].RentStartPeriod;
                const [startMonthDate, startMonthYear] = currentStartMonth.split(' ');
                const startMonthIndex = this.getMonthIndex(startMonthDate);
                var currentMonthEnd = new Date(startMonthYear, startMonthIndex + 1, 0);
                const rentalEndDateYear = currentMonthEnd.getFullYear();
                const rentalEndDateMonth = currentMonthEnd.getMonth() + 1;
                const rentalEndDateYearMonth = `${rentalEndDateYear}${rentalEndDateMonth < 10 ? '0' : ''}${rentalEndDateMonth}`;
                previousRentalYearMonth = rentalEndDateYearMonth;
                if (endDateYearMonth == rentalEndDateYearMonth)
                  currentMonthEnd = await this.getMonthEndingDateFromSetting(rentalEndDateYearMonth);
              }
              const selectedDayOfWeek = el - 1
              let currentDay = new Date(startDate);
              while (currentDay <= endDate) {
                if (currentDay.getDay() === selectedDayOfWeek) {
                  if (!rentalstartDate) {
                    rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                  }
                  marketDays += 1;
                  rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                }
                currentDay.setDate(currentDay.getDate() + 1);
              }

              if (this.MonthlyBookingEndDate) {
                currentDay = new Date(startDate);
                while (currentDay <= currentMonthEnd) {
                  if (currentDay.getDay() === selectedDayOfWeek) {
                    if (!rentalstartDate) {
                      rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                    }
                    currentMonthRentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                  }
                  currentDay.setDate(currentDay.getDate() + 1);
                }
              }
            };

            const compareDates = (a: DateString, b: DateString): number => {
              const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
              const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
              return dateA - dateB;
            };
            const sortedDates = rentalDates.sort(compareDates);

            if (this.MonthlyBookingEndDate) {
              const compareCurrentMonthDates = (a: DateString, b: DateString): number => {
                const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                return dateA - dateB;
              };
              const currentMonthSortedDates = currentMonthRentalDates.sort(compareCurrentMonthDates);
            }
            else {
              currentMonthRentalDates = rentalDates;
            }

            var allRentalDates = rentalDates.map(el => new Date(el.startDate));
            var currentRentedEndDate = new Date(this.selectedMonthlyBooths[recordExist].RentalEndDate);
            const filteredRentalDates = allRentalDates.filter(rentalDate => rentalDate > currentRentedEndDate);
            var obj1 = {
              RentalDates: filteredRentalDates,
              BoothNumber: this.selectedMonthlyBooths[recordExist].BoothNumber
            }

            const rentalExist = await this.rentalService.checkRentalExits(this.marketId, obj1).toPromise();
            if (!rentalExist.Success) {
              await this.updateBoothRentDetails(recordExist, rentalDates, marketDays);
            } else {
              nonAvailableBooths.push(BoothNumber);
            }
          }
        }
        else {
          item.MonthlyRenewalMonth = item.RentEndPeriod;
          item.RenewalStartMonth = "This booking cannot be extended further!"
          item.BookingCanExtend = false;
          nonAvailableBooths.push(item.BoothNumber);
        }
      }
    }
  }
  
  private async getEndDate(MonthlyRenewalMonth, startDate: Date) {
    const [endMonthDate, endMonthYear] = MonthlyRenewalMonth.split(' ');
    const endMonthIndex = this.getMonthIndex(endMonthDate);
    return new Date(endMonthYear, endMonthIndex + 1, 0);
  }
  
  private async getPreviousMonthEndDate(startDate: Date) {
    const rentalStartDate = new Date(startDate);
    rentalStartDate.setMonth(rentalStartDate.getMonth() - 1);
    const previousMonth = rentalStartDate.getMonth() + 1;
    const previousYear = rentalStartDate.getFullYear();
    return await this.getMonthEndingDateFromSetting(`${previousYear}${previousMonth.toString().padStart(2, '0')}`);
  }
  
  private filterCurrentMonthDates(startDate: Date, selectedDayOfWeek: number, currentMonthRentalDates: any[]) {
    let currentDay = new Date(startDate);
    const currentMonthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    while (currentDay <= currentMonthEnd) {
      if (currentDay.getDay() === selectedDayOfWeek) {
        currentMonthRentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
      }
      currentDay.setDate(currentDay.getDate() + 1);
    }
    return currentMonthRentalDates;
  }
  
  private sortDates(dates: any[]): any[] {
    return dates.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
  }
  
  private filterDatesAfterEndDate(allRentalDates: Date[], currentRentedEndDate: string): Date[] {
    const currentEndDate = new Date(currentRentedEndDate);
    return allRentalDates.filter(date => date > currentEndDate);
  }
  
  private async updateBoothRentDetails(recordExist: number, rentalDates: any[], marketDays: number) {
    const { BoothTypeId, RenewalStartMonth, Status, PerDiem, Rent, SalesTax, CityTax } = this.selectedMonthlyBooths[recordExist];
    var marketDay;
    if (this.selectedMonthlyBooths[recordExist].Sunday == true) {
      marketDay = 'Sun'
    }
    else if (this.selectedMonthlyBooths[recordExist].Monday == true) {
      marketDay = 'Mon'
    }
    else if (this.selectedMonthlyBooths[recordExist].Tuesday == true) {
      marketDay = 'Tue'
    }
    else if (this.selectedMonthlyBooths[recordExist].Wednesday == true) {
      marketDay = 'Wed'
    }
    else if (this.selectedMonthlyBooths[recordExist].Thursday == true) {
      marketDay = 'Thu'
    }
    else if (this.selectedMonthlyBooths[recordExist].Friday == true) {
      marketDay = 'Fri'
    }
    else if (this.selectedMonthlyBooths[recordExist].Saturday == true) {
      marketDay = 'Sat'
    }

    const res = await this.rentalService.getBoothRentalMontlyCharges(this.marketId, {
      BoothTypeId,
      VendorId: this.vendorId,
      RentalStartPeriod: new Date(RenewalStartMonth),
      RentalEndPeriod: new Date(rentalDates[rentalDates.length - 1].startDate),
      PerDiemApplicable: PerDiem,
      RentalMarketDay: marketDay,
      Rent: this.parseCurrency(Rent),
      SalesTax: this.parseCurrency(SalesTax),
      CityTax: this.parseCurrency(CityTax)
    }).toPromise();
  
    if (res) {
        RentalEndDate: this.datePipe.transform(new Date(rentalDates[rentalDates.length - 1].startDate), 'MMM d, y'),
        this.selectedMonthlyBooths[recordExist].RentalEndDate = this.datePipe.transform(new Date(rentalDates[rentalDates.length - 1].startDate), 'MMM d, y');
        this.selectedMonthlyBooths[recordExist].MktDays = marketDays;
        this.selectedMonthlyBooths[recordExist].Rent = this.customCurrency.transform(res.Rent);
        this.selectedMonthlyBooths[recordExist].SalesTax = this.customCurrency.transform(res.SalesTax);
        this.selectedMonthlyBooths[recordExist].CityTax = this.customCurrency.transform(res.CityTax);
        this.selectedMonthlyBooths[recordExist].January = this.customCurrency.transform(res.JanRent);
        this.selectedMonthlyBooths[recordExist].February = this.customCurrency.transform(res.FebRent);
        this.selectedMonthlyBooths[recordExist].March = this.customCurrency.transform(res.MarRent);
        this.selectedMonthlyBooths[recordExist].April = this.customCurrency.transform(res.AprRent);
        this.selectedMonthlyBooths[recordExist].May = this.customCurrency.transform(res.MayRent);
        this.selectedMonthlyBooths[recordExist].June = this.customCurrency.transform(res.JunRent);
        this.selectedMonthlyBooths[recordExist].July = this.customCurrency.transform(res.JulRent);
        this.selectedMonthlyBooths[recordExist].August = this.customCurrency.transform(res.AugRent);
        this.selectedMonthlyBooths[recordExist].September = this.customCurrency.transform(res.SepRent);
        this.selectedMonthlyBooths[recordExist].October = this.customCurrency.transform(res.OctRent);
        this.selectedMonthlyBooths[recordExist].November = this.customCurrency.transform(res.NovRent);
        this.selectedMonthlyBooths[recordExist].December = this.customCurrency.transform(res.DecRent);
        this.selectedMonthlyBooths[recordExist].JanuaryMonthPresent = res.JanuaryMonthPresent;
        this.selectedMonthlyBooths[recordExist].FebruaryMonthPresent = res.FebruaryMonthPresent;
        this.selectedMonthlyBooths[recordExist].MarchMonthPresent = res.MarchMonthPresent;
        this.selectedMonthlyBooths[recordExist].AprilMonthPresent = res.AprilMonthPresent;
        this.selectedMonthlyBooths[recordExist].MayMonthPresent = res.MayMonthPresent;
        this.selectedMonthlyBooths[recordExist].JuneMonthPresent = res.JuneMonthPresent;
        this.selectedMonthlyBooths[recordExist].JulyMonthPresent = res.JulyMonthPresent;
        this.selectedMonthlyBooths[recordExist].AugustMonthPresent = res.AugustMonthPresent;
        this.selectedMonthlyBooths[recordExist].SeptemberMonthPresent = res.SeptemberMonthPresent;
        this.selectedMonthlyBooths[recordExist].OctoberMonthPresent = res.OctoberMonthPresent;
        this.selectedMonthlyBooths[recordExist].NovemberMonthPresent = res.NovemberMonthPresent;
        this.selectedMonthlyBooths[recordExist].DecemberMonthPresent = res.DecemberMonthPresent;
        this.selectedMonthlyBooths[recordExist].DisableJanMonth = res.JanRent || res.JanRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].DisableFebMonth = res.FebRent || res.FebRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].DisableMarMonth = res.MarRent || res.MarRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].DisableAprMonth = res.AprRent || res.AprRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].DisableMayMonth = res.MayRent || res.MayRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].DisableJunMonth = res.JunRent || res.JunRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].DisableJulMonth = res.JulRent || res.JulRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].DisableAugMonth = res.AugRent || res.AugRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].DisableSepMonth = res.SepRent || res.SepRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].DisableOctMonth = res.OctRent || res.OctRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].DisableNovMonth = res.NovRent || res.NovRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].DisableDecMonth = res.DecRent || res.DecRent == 0 ? false : true;
        this.selectedMonthlyBooths[recordExist].ExtendRental = true;
        this.selectedMonthlyBooths[recordExist].AllRentalDates =  rentalDates.map(el => new Date(el.startDate));  
      this.updateTotalCharges(res.Rent, res.SalesTax, res.CityTax);
    }
  }
  
  private parseCurrency(value: string) {
    return value.includes('(') && value.includes(')') ? -parseFloat(value.replace(/[^0-9.-]/g, '')) : parseFloat(value.replace(/[^0-9.-]/g, ''));
  }
  
  private updateTotalCharges(Rent, SalesTax, CityTax) {
    this.totalRentalCharge += Rent;
    this.totalSalesTax += SalesTax;
    this.totalSecondLeveltax += CityTax;
    this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
     this.bookingDetailsForm.patchValue({
      rentalCharge: this.customCurrency.transform(this.totalRentalCharge.toString()),
      totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
      totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
      totalCharge: this.customCurrency.transform(this.totalCharge.toString())     
    });
  }
  
  getMonthlyOtherRentals() {
    this.loaderIcon = Promise.resolve(true);
    this.selectedOtherItems = [];
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const currentYearMonth = `${currentYear}${currentMonth < 10 ? '0' : ''}${currentMonth}`;
    const sub = this.rentalService.getMonthlyOtherRentals(this.marketId, this.vendorId).subscribe(async (res: any[]) => {
      if (res) {
        this.allrentedOtherItems = res;
        for(const el of res){
          if (el.YearMonth >= currentYearMonth) {        
            const year = parseInt(el.YearMonth.substring(0, 4));
            const month = parseInt(el.YearMonth.substring(4, 6));
            const date = new Date(year, month - 1, 1);
            const formattedDate = this.datePipe.transform(date, 'MMMM yyyy');
            const rentEndPeriodIndex = this.monthlyMarketDays.findIndex(e => e.RentalMonths ==formattedDate);
            const renewalStartMonth = this.monthlyMarketDays.length > 1 ? this.monthlyMarketDays[rentEndPeriodIndex +1].RentalMonths : "";
          
            const index = this.selectedOtherItems.findIndex(item => item.Id === el.OtherItemId && item.RentalMonth == formattedDate && item.Reference == el.Reference && item.BoothNo == el.BoothNumber && item.Recurring == el.Recurring);
            if (index !== -1) {
              this.selectedOtherItems[index].Quantity += el.Quantity;
              this.selectedOtherItems[index].MonthlyRentalIds += ',' + el.OtherRentalId
              var otherItemTotal = (el.OtherCharge * this.selectedOtherItems[index].Quantity).toString();    
              this.selectedOtherItems[index].OtherItemtotal = otherItemTotal;
            }
            else {           
              var otherItemTotal = (el.OtherCharge *  el.Quantity).toString();           
              const ob = {
                Id: el.OtherItemId,
                MonthOtherRentalId: el.OtherRentalId,
                OtherItem: el.OtherItemName,
                OtherItemCharge: this.customCurrency.transform("0"),
                Quantity: el.Quantity,
                OtherItemtotal: this.customCurrency.transform("0"),
                RentalMonth: formattedDate,
                SalesTax: this.customCurrency.transform('0'),
                CityTax: this.customCurrency.transform('0'),
                CurrentRentalMonth: formattedDate,
                CurrentOtherItemtotal: this.customCurrency.transform(el.OtherCharge.toString()),
                CurrentSalesTax: this.customCurrency.transform(el.SalesTax.toString()),
                CurrentSecLevelTax: this.customCurrency.transform(el.SecLevelTax.toString()),
                BoothNo: el.BoothNumber ? el.BoothNumber : "none",
                Recurring: el.Recurring,
                Reference: el.Reference,
                NewItem: false,
                MonthlyRentalIds: el.OtherRentalId.toString(),
                RenewalStartMonth: renewalStartMonth,
                MonthlyRenewalMonth:renewalStartMonth,
                BookingCanExtend: true,
                RentEndPeriodIndex:rentEndPeriodIndex
              }
              this.selectedOtherItems.push(ob);
            }
          }
          else {
            const rentEndPeriodIndex = this.monthlyMarketDays.findIndex(e => e.RentalMonths ==formattedDate);
            const renewalStartMonth = this.monthlyMarketDays.length > 1 ? this.monthlyMarketDays[rentEndPeriodIndex +1].RentalMonths : "";
            var obj = {
              MarketId: this.marketId,
              OtherItemId: el.OtherItemId,
              VendorId: this.vendorId,
              Quantity: el.Quantity,
              OtherItemCharge: el.OtherCharge
            }
            const date = new Date(currentYear, currentMonth - 1, 1);
            const formattedDate = this.datePipe.transform(date, 'MMMM yyyy');
            const res = await this.rentalService.getOtherItemsRentalCharges(obj).toPromise();
            if(res)
            {
              const ob = {
                Id: el.OtherItemId,
                MonthOtherRentalId: el.OtherRentalId,
                OtherItem: el.OtherItemName,
                OtherItemCharge: this.customCurrency.transform(el.OtherCharge),
                Quantity: el.Quantity,
                OtherItemtotal: this.customCurrency.transform((el.OtherCharge * el.Quantity).toString()),
                RentalMonth: formattedDate,
                SalesTax: this.customCurrency.transform(res.SalesTax.toString()),
                CityTax: this.customCurrency.transform(res.CityTax.toString()),
                BoothNo: el.BoothNumber ? el.BoothNumber : "none",
                Recurring: el.Recurring,
                Reference: el.Reference,
                NewItem: false,
                MonthlyRentalIds: '',
                Status: "R",
                RentEndPeriodIndex:rentEndPeriodIndex,
                BookingCanExtend: true,
                RenewalStartMonth: formattedDate,
                MonthlyRenewalMonth:formattedDate,
              }
              this.selectedOtherItems.push(ob);
              const OtherItemtotal = el.OtherCharge * el.Quantity;
              if (this.allowOtherItemRenewal) {
                this.totalOtherRentalCharge = OtherItemtotal;
                this.totalSalesTax = res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
                this.totalSecondLeveltax = res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
                this.totalCharge = this.priorBalance + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
                this.bookingDetailsForm.patchValue({
                  otherCharge: this.customCurrency.transform(this.totalOtherRentalCharge.toString()),
                  totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
                  totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
                  totalCharge: this.customCurrency.transform(this.totalCharge.toString())
                });
              }
            }
          }
        }
        this.renewMonthlyOtherCharges();
        this.loaderIcon = Promise.resolve(false);
      }
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  saveRentalWithPayment() {    
    var creditCardAmount = this.bookingDetailsForm.get('paymentAmount').value.toString().includes('(') && this.bookingDetailsForm.get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, ''));
     var convenienceFee = this.bookingDetailsForm.get('convenienceFee').value.toString().includes('(') && this.bookingDetailsForm.get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
    const totalAmountPaid = creditCardAmount  + convenienceFee;
    if (totalAmountPaid != 0) {
      Swal.fire({
        title: $localize`Confirm`,
        text: $localize`Are you sure you want to proceed with the payment?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: $localize`Yes`,
        cancelButtonText: $localize`No`,
      }).then((result) => {
        if (result.value) {
          if (this.paymentGatewayCredentials && this.processCreditCardOnline)
            this.processPayment = true;
          else {
            Swal.fire({
              title: $localize`Warning`,
              text: $localize`Credit card cannot be processed.`,
              icon: "warning",
              confirmButtonText: $localize`Ok`,
            });
          }
        }
      });
    }
    else {
      Swal.fire({
        title: $localize`Warning`,
        text: $localize`Please enter the payment amount!`,
        icon: "warning",
        confirmButtonText: $localize`Ok`,
      });
    }
  }

  PaymentResponse(response) {
    this.loaderIcon = Promise.resolve(true);
    if (response.PaymentSuccess) {
      this.verifoneId = response.VerifoneId;
      switch (this.rentalType) {
       case 2:
          var convenienceFee = this.bookingDetailsForm.get('convenienceFee').value.toString().includes('(') && this.bookingDetailsForm.get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('convenienceFee').value.toString().replace(/[^0-9.-]/g, ''));
          if (convenienceFee > 0 && this.paymentOnly) {
            this.paymentOnly = false;
          }
          var objM = {
            VendorId: this.vendorId,
            PaymentOrderId: response.OrderId,
            RentalCharge: this.bookingDetailsForm.get('rentalCharge').value.toString().includes('(') && this.bookingDetailsForm.get('rentalCharge').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('rentalCharge').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('rentalCharge').value.toString().replace(/[^0-9.-]/g, '')),
            SalesTax: 0,
            SecLevelTax: 0,
            OtherCharge: this.bookingDetailsForm.get('otherCharge').value.toString().includes('(') && this.bookingDetailsForm.get('otherCharge').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('otherCharge').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('otherCharge').value.toString().replace(/[^0-9.-]/g, '')),
            PriorBalance: this.bookingDetailsForm.get('priorBalance').value.toString().includes('(') && this.bookingDetailsForm.get('priorBalance').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('priorBalance').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('priorBalance').value.toString().replace(/[^0-9.-]/g, '')),
            TotalBalanceDue: this.bookingDetailsForm.get('balanceDue').value.toString().includes('(') && this.bookingDetailsForm.get('balanceDue').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('balanceDue').value.toString().replace(/[^0-9.-]/g, '')),
            RentalNotes: "",
            CashAmount: 0,
            CheckAmount: 0,
            CheckNo: "",
            ChangeAmount: 0,
            CardAmount: this.bookingDetailsForm.get('paymentAmount').value.toString().includes('(') && this.bookingDetailsForm.get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')),
            DirectDebitAmount: 0,
            CardType: "",
            PaymentDone: true,
            WebRentalRequest: true,
            PaymentDueDate: null,
            VerifoneId: response.VerifoneId,
            DiscountAmount: this.bookingDetailsForm.get('discount').value.toString().includes('(') && this.bookingDetailsForm.get('discount').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('discount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('discount').value.toString().replace(/[^0-9.-]/g, '')),
            ConvenienceFee: this.bookingDetailsForm.get('convenienceFee').value.toString().includes('(') && this.bookingDetailsForm.get('convenienceFee').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('convenienceFee').value.toString().replace(/[^0-9.-]/g, '')),
            BookingFee: this.bookingDetailsForm.get('additionalCharge').value.toString().includes('(') && this.bookingDetailsForm.get('additionalCharge').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('additionalCharge').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('additionalCharge').value.toString().replace(/[^0-9.-]/g, '')),
            MonthlyRentalBooth: [],
            MonthlyOtherRentalCharges: [],
            AppliedDiscountModel: []
          }

          var objSelectedBooth = [];
          var objReturnData = [];
          if (this.selectedMonthlyBooths.length > 0) {
            this.selectedMonthlyBooths.forEach((item, i) => {
              const rentalStartDateYear = new Date(item.RentalEndDate).getFullYear();
              const rentalStartDateMonth = new Date(item.RentalEndDate).getMonth() + 1;
              const rentalStartDateYearMonth = `${rentalStartDateYear}${rentalStartDateMonth < 10 ? '0' : ''}${rentalStartDateMonth}`;
              if (item.Reservation) {
                objSelectedBooth.push({
                  MonthlyRentalId: item.MonthlyRentalId,
                  BoothNumber: item.BoothNumber,
                  BoothTypeId: item.BoothTypeId,
                  ProductId: item.Product,
                  BoothType: item.BoothType,
                  RentalStartDate: new Date(item.RentalStartDate),
                  RentalEndDate: new Date(item.RentalEndDate),
                  RentalYearMonth: rentalStartDateYearMonth,
                  BoothReserved: item.Reservation,
                  DoNotRenew: item.Drop,
                  PerDiem: item.PerDiem,
                  PerdiemRent: item.PerDiemRent.includes('(') && item.PerDiemRent.includes(')') ? -1 * parseFloat(item.PerDiemRent.replace(/[^0-9.-]/g, '')) : parseFloat(item.PerDiemRent.replace(/[^0-9.-]/g, '')),
                  Monday: item.Monday,
                  Tuesday: item.Tuesday,
                  Wednesday: item.Wednesday,
                  Thursday: item.Thursday,
                  Friday: item.Friday,
                  Saturday: item.Saturday,
                  Sunday: item.Sunday,
                  RentalCharge: item.Rent.includes('(') && item.Rent.includes(')') ? -1 * parseFloat(item.Rent.replace(/[^0-9.-]/g, '')) : parseFloat(item.Rent.replace(/[^0-9.-]/g, '')),
                  SalesTax: item.SalesTax.includes('(') && item.SalesTax.includes(')') ? -1 * parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')),
                  SecLevelTax: item.CityTax.includes('(') && item.CityTax.includes(')') ? -1 * parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')),
                  JanRent: item.January ? (item.January.includes('(') && item.January.includes(')') ? -1 * parseFloat(item.January.replace(/[^0-9.-]/g, '')) : parseFloat(item.January.replace(/[^0-9.-]/g, ''))) : 0,
                  FebRent: item.February ? (item.February.includes('(') && item.February.includes(')') ? -1 * parseFloat(item.February.replace(/[^0-9.-]/g, '')) : parseFloat(item.February.replace(/[^0-9.-]/g, ''))) : 0,
                  MarRent: item.March ? (item.March.includes('(') && item.March.includes(')') ? -1 * parseFloat(item.March.replace(/[^0-9.-]/g, '')) : parseFloat(item.March.replace(/[^0-9.-]/g, ''))) : 0,
                  AprRent: item.April ? (item.April.includes('(') && item.April.includes(')') ? -1 * parseFloat(item.April.replace(/[^0-9.-]/g, '')) : parseFloat(item.April.replace(/[^0-9.-]/g, ''))) : 0,
                  MayRent: item.May ? (item.May.includes('(') && item.May.includes(')') ? -1 * parseFloat(item.May.replace(/[^0-9.-]/g, '')) : parseFloat(item.May.replace(/[^0-9.-]/g, ''))) : 0,
                  JunRent: item.June ? (item.June.includes('(') && item.June.includes(')') ? -1 * parseFloat(item.June.replace(/[^0-9.-]/g, '')) : parseFloat(item.June.replace(/[^0-9.-]/g, ''))) : 0,
                  JulRent: item.July ? (item.July.includes('(') && item.July.includes(')') ? -1 * parseFloat(item.July.replace(/[^0-9.-]/g, '')) : parseFloat(item.July.replace(/[^0-9.-]/g, ''))) : 0,
                  AugRent: item.August ? (item.August.includes('(') && item.August.includes(')') ? -1 * parseFloat(item.August.replace(/[^0-9.-]/g, '')) : parseFloat(item.August.replace(/[^0-9.-]/g, ''))) : 0,
                  SepRent: item.September ? (item.September.includes('(') && item.September.includes(')') ? -1 * parseFloat(item.September.replace(/[^0-9.-]/g, '')) : parseFloat(item.September.replace(/[^0-9.-]/g, ''))) : 0,
                  OctRent: item.October ? (item.October.includes('(') && item.October.includes(')') ? -1 * parseFloat(item.October.replace(/[^0-9.-]/g, '')) : parseFloat(item.October.replace(/[^0-9.-]/g, ''))) : 0,
                  NovRent: item.November ? (item.November.includes('(') && item.November.includes(')') ? -1 * parseFloat(item.November.replace(/[^0-9.-]/g, '')) : parseFloat(item.November.replace(/[^0-9.-]/g, ''))) : 0,
                  DecRent: item.December ? (item.December.includes('(') && item.December.includes(')') ? -1 * parseFloat(item.December.replace(/[^0-9.-]/g, '')) : parseFloat(item.December.replace(/[^0-9.-]/g, ''))) : 0,
                  BookingLog: i + 1,
                  AllRentalDates: item.AllRentalDates
                });
              }

              if (item.NewBooth) {
                objReturnData.push({
                  MonthlyRentalId: item.MonthlyRentalId,
                  BoothNumber: item.BoothNumber,
                  BoothType: item.BoothType,
                  BoothTypeId: item.BoothTypeId,
                  Product: item.Product,
                  RentStartPeriod: item.RentStartPeriod,
                  RentEndPeriod: item.RentEndPeriod,
                  RentalSavedEndDate: item.RentalEndDate,
                  RentalStartDate: item.RentalStartDate,
                  RentalEndDate: item.RentalEndDate,
                  MktDays: item.MktDays,
                  Rent: item.Rent,
                  SalesTax: item.SalesTax,
                  CityTax: item.CityTax,
                  PerDiem: item.PerDiem,
                  PerdiemRent: item.PerDiemRent,
                  Sunday: item.Sunday,
                  Monday: item.Monday,
                  Tuesday: item.Tuesday,
                  Wednesday: item.Wednesday,
                  Thursday: item.Thursday,
                  Friday: item.Friday,
                  Saturday: item.Saturday,
                  Reservation: item.Reservation,
                  Drop: item.Drop,
                  January: item.January,
                  February: item.February,
                  March: item.March,
                  April: item.April,
                  May: item.May,
                  June: item.June,
                  July: item.July,
                  August: item.August,
                  September: item.September,
                  October: item.October,
                  November: item.November,
                  December: item.December,
                  JanuaryMonthPresent: item.January ? true : false,
                  FebruaryMonthPresent: item.February ? true : false,
                  MarchMonthPresent: item.March ? true : false,
                  AprilMonthPresent: item.April ? true : false,
                  MayMonthPresent: item.May ? true : false,
                  JuneMonthPresent: item.June ? true : false,
                  JulyMonthPresent: item.July ? true : false,
                  AugustMonthPresent: item.August ? true : false,
                  SeptemberMonthPresent: item.September ? true : false,
                  OctoberMonthPresent: item.October ? true : false,
                  NovemberMonthPresent: item.November ? true : false,
                  DecemberMonthPresent: item.December ? true : false,
                  NewBooth: true,
                  Status: "N",
                  StartDateList: item.StartDateList,
                  DisableStartDate: false,
                  AllRentalDates: item.AllRentalDates
                });
              }
            });
          }

          if (objSelectedBooth.length > 0)
            objM.MonthlyRentalBooth = objSelectedBooth;

          var objSelectedOtherCharge = [];
          if (this.selectedOtherItems.length > 0) {
            this.selectedOtherItems.forEach((item) => {
              const otherRentalYear = new Date(item.RentalMonth.split('-')[0]).getFullYear();
              const otherRentalMonth = new Date(item.RentalMonth.split('-')[0]).getMonth() + 1;
              const otherRentalYearMonth = `${otherRentalYear}${otherRentalMonth <
                10 ? '0' : ''}${otherRentalMonth}`;

              if (item.Recurring) {
                objSelectedOtherCharge.push({
                  OtherItemId: item.Id,
                  MonthOtherRentalId: item.MonthOtherRentalId,
                  OtherItemName: item.OtherItem,
                  OtherItemCharge: item.OtherItemtotal.includes('(') && item.OtherItemtotal.includes(')') ? -1 * parseFloat(item.OtherItemtotal.replace(/[^0-9.-]/g, '')) : parseFloat(item.OtherItemtotal.replace(/[^0-9.-]/g, '')),
                  Quantity: item.Quantity,
                  YearMonth: otherRentalYearMonth,
                  BoothNumber: item.BoothNo,
                  Recurring: item.Recurring,
                  OtherCharge: item.OtherItemCharge.includes('(') && item.OtherItemCharge.includes(')') ? -1 * parseFloat(item.OtherItemCharge.replace(/[^0-9.-]/g, '')) : parseFloat(item.OtherItemCharge.replace(/[^0-9.-]/g, '')),
                  SalesTax: item.SalesTax.includes('(') && item.SalesTax.includes(')') ? -1 * parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')),
                  SecLevelTax: item.CityTax.includes('(') && item.CityTax.includes(')') ? -1 * parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')),
                  Reference: item.Reference,
                  MonthlyRentalIds: item.MonthlyRentalIds
                })
              }
            });
          }
          if (objSelectedOtherCharge.length > 0)
            objM.MonthlyOtherRentalCharges = objSelectedOtherCharge;

          var objDiscountData = [];
          if (this.vendorDiscountPlans.length > 0 && this.totalDiscountCharge > 0) {
            this.vendorDiscountPlans.forEach((item) => {
              if (item.CheckAll) {
                objDiscountData.push({
                  PlanId: item.PlanSeqId,
                  DiscountAmount: item.AppliedDiscount.includes('(') && item.AppliedDiscount.includes(')') ? -1 * parseFloat(item.AppliedDiscount.replace(/[^0-9.-]/g, '')) : parseFloat(item.AppliedDiscount.replace(/[^0-9.-]/g, '')),
                  OtherCharges: item.OtherItems,
                  SalesTax: item.SalesTaxApplied,
                  SecondLevelTax: item.SecondLevelTaxApplied,
                  DiscountType: item.DiscountType == DiscountType.Percentage ? "0" : "1",
                  DiscountValue: item.DiscountValue,
                  SalesTaxAmount: item.SalesTaxAmount.includes('(') && item.SalesTaxAmount.includes(')') ? -1 * parseFloat(item.SalesTaxAmount.replace(/[^0-9.-]/g, '')) : parseFloat(item.SalesTaxAmount.replace(/[^0-9.-]/g, '')),
                  SecondLevelTaxAmount: item.SecondLevelTaxAmount.includes('(') && item.SecondLevelTaxAmount.includes(')') ? -1 * parseFloat(item.SecondLevelTaxAmount.replace(/[^0-9.-]/g, '')) : parseFloat(item.SecondLevelTaxAmount.replace(/[^0-9.-]/g, ''))
                })
              }
            });
          }
          if (objDiscountData.length > 0)
            objM.AppliedDiscountModel = objDiscountData;

          const subCM = this.rentalService.saveMonthlyRental(this.marketId,objM).subscribe(async (res: any) => {
            if (res.Success) {
              this.accountReceipId = res.Id;
              this.toastr.success($localize`Transaction saved successfully`, $localize`Success`, this.toasterConfig);
              this.openConfirmationPage = true;
            } else {
              this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
              if (this.verifoneId) {
                this.voidPayment = true;
              }
            }
            this.loaderIcon = Promise.resolve(false);
          }, (err) => {
            this.toastr.error($localize`Error occurred while saving the rental transaction`, $localize`Error`, this.toasterConfig);
            // const url = 'ops/vendors/details/' + this.vendorId + '/3';
            // this.router.navigate([url]);               

            this.loaderIcon = Promise.resolve(false);
          });

          this.subscriptions.push(subCM);
          break;
      }
    }
    else {
      this.processPayment = false;
      this.voidPayment = false;
      this.loaderIcon = Promise.resolve(false);
    }
  }

  setMinimum(controls) {
    const value = controls.value;
    var enteredAmount = value.toString().includes('(') && value.toString().includes(')') ? -1 * parseFloat(value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(value.toString().replace(/[^0-9.-]/g, ''));
    if (!enteredAmount) {
      controls.setValue(this.customCurrency.transform("0.00"));
    }
    var totalrentalCharge = this.bookingDetailsForm.get('rentalCharge').value.toString().includes('(') && this.bookingDetailsForm.get('rentalCharge').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('rentalCharge').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('rentalCharge').value.toString().replace(/[^0-9.-]/g, ''));
    var otherRentalCharge = this.bookingDetailsForm.get('otherCharge').value.toString().includes('(') && this.bookingDetailsForm.get('otherCharge').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('otherCharge').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('otherCharge').value.toString().replace(/[^0-9.-]/g, ''));
    var totalDue = this.actualTotalDue + totalrentalCharge + otherRentalCharge + this.renewalFeeAmount;
    if (enteredAmount > totalDue) {
      this.bookingDetailsForm.get("paymentAmount").setValue(this.customCurrency.transform(totalDue.toString()));
      Swal.fire({
        title: $localize`Warning`,
        text: $localize`Payment amount cannot be greater than total due.`,
        icon: "warning",
        confirmButtonText: $localize`Ok`,
      });
    }
    this.calculateBalance();
  }

  calculateBalance() {
    const creditCardAmount = this.bookingDetailsForm.get('paymentAmount').value.toString().includes('(') && this.bookingDetailsForm.get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, ''));
    this.creditCardAmount = this.bookingDetailsForm.get('paymentAmount').value.toString().includes('(') && this.bookingDetailsForm.get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, ''));
    var totalrentalCharge = this.bookingDetailsForm.get('rentalCharge').value.toString().includes('(') && this.bookingDetailsForm.get('rentalCharge').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('rentalCharge').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('rentalCharge').value.toString().replace(/[^0-9.-]/g, ''));
    var otherRentalCharge = this.bookingDetailsForm.get('otherCharge').value.toString().includes('(') && this.bookingDetailsForm.get('otherCharge').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('otherCharge').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('otherCharge').value.toString().replace(/[^0-9.-]/g, ''));
    var totalSalesTax = this.bookingDetailsForm.get('totalSalesTax').value.toString().includes('(') && this.bookingDetailsForm.get('totalSalesTax').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('totalSalesTax').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('totalSalesTax').value.toString().replace(/[^0-9.-]/g, ''));
    var totalSecondLeveltax = this.bookingDetailsForm.get('totalSecondLeveltax').value.toString().includes('(') && this.bookingDetailsForm.get('totalSecondLeveltax').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('totalSecondLeveltax').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('totalSecondLeveltax').value.toString().replace(/[^0-9.-]/g, ''));

    var renewalFee = this.renewalFeeAmount.toString().includes('(') && this.renewalFeeAmount.toString().includes(')') ? -1 * parseFloat(this.renewalFeeAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.renewalFeeAmount.toString().replace(/[^0-9.-]/g, ''));

    var totalDue = this.actualTotalDue + totalrentalCharge + otherRentalCharge + totalSalesTax + totalSecondLeveltax;
    if(totalDue > 0){
      totalDue = totalDue + renewalFee;
    }
    else{
      renewalFee = 0;
    }
    const convenienceFee = 0;

    var totalAmount;
    totalAmount = creditCardAmount + convenienceFee;
    const balanceAmount = totalDue != 0 ? totalDue - totalAmount : 0;

    this.bookingDetailsForm.patchValue({
      totalDue: this.customCurrency.transform(totalDue.toString()),
      balanceDue: this.customCurrency.transform(balanceAmount.toString()),
      convenienceFee: this.customCurrency.transform(convenienceFee.toString()),
      additionalCharge: this.customCurrency.transform(renewalFee.toString()),
      totalPayment: this.customCurrency.transform(totalAmount.toString())
    })

    this.paymentAmount = this.bookingDetailsForm.get('totalPayment').value.toString().includes('(') && this.bookingDetailsForm.get('totalPayment').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('totalPayment').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('totalPayment').value.toString().replace(/[^0-9.-]/g, ''));

    if (this.applyCCCharge && creditCardAmount > 0) {
      this.calculateConvenienceFee();
    }
  }

  handledblClick(event: any) {
    clearTimeout(this.clickTimeout);

    const inputElement = event.target as HTMLInputElement;
    inputElement.select(); // Select the entire text on double-click
  }

  handleClick(event: any) {
    // Get the current value of the input field
    let currentValue: string = event.target.value;
    const currentCursorPosition = event.target.selectionStart;
    const currentSelectionLength = event.target.selectionEnd - event.target.selectionStart;
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    clearTimeout(this.clickTimeout);

    this.clickTimeout = setTimeout(() => {
      if (inputValue === '$0.00') {
        const selectionLength = inputElement.selectionEnd - inputElement.selectionStart;

        // If the entire text is not selected, set the cursor position after the "$" sign
        if (selectionLength !== inputValue.length) {
          inputElement.setSelectionRange(1, 1);
        }
      }
    }, 200); // Delay to check for double click
  
    if (currentSelectionLength === currentValue.length) {
      return true;
    }
    // Check if the input value is "$0.00"
    if (currentValue === '$0.00') {
      // Set the cursor position after the "$" sign
      event.target.setSelectionRange(1, 1);
      return;
    }

    // Check if the "$" sign is present in the current value
    const hasDollarSign = currentValue.includes('$');
    // Get the cursor position
    const caretPosition = event.target.selectionStart;
    // Check if the cursor position is after the "$" sign
    const hasDollarSignBeforeCaret = currentValue.slice(0, caretPosition).includes('$');
    // If the cursor is not after the "$" sign, adjust the cursor position
    if (!hasDollarSignBeforeCaret && hasDollarSign) {
      // Find the index of "$" sign
      const dollarSignIndex = currentValue.indexOf('$');
      // Set the cursor position after the "$" sign
      event.target.setSelectionRange(dollarSignIndex + 1, dollarSignIndex + 1);
    }
  }

   setCurrencyFormat(controls) {
    const value = controls.value;
    let inputValue = value;
    if(inputValue.toString().substring(1,2) == "$")
    inputValue = `${inputValue.slice(1)}`;
    controls.setValue(inputValue);
  }


  numberOnly(event): boolean {
    const inputValue = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    const currentCursorPosition = event.target.selectionStart;
    const currentSelectionLength = event.target.selectionEnd - event.target.selectionStart;
    // Check if the input value is "$0.00"
    if (charCode == 45) {
      return false;
    }
    if (currentSelectionLength === inputValue.length) {
      return true;
    }
    if (inputValue === '$0.00') {
      // Set the cursor position after the "$" sign
      event.target.setSelectionRange(1, 1);
      return true; // Allow further input
    }
    // Check if the "$" sign is present in the current value
    const hasDollarSign = inputValue.includes('$');
    // Check if the cursor position is after the "$" sign
    const hasDollarSignBeforeCaret = inputValue.slice(0, currentCursorPosition).includes('$');
    // If the cursor is not after the "$" sign and "$" sign is present, prevent input
    if (!hasDollarSignBeforeCaret && hasDollarSign && charCode !== 46 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    // If the entered character is a dot and there's already a dot in the input, prevent input
    if (charCode == 46 && inputValue.includes('.') && inputValue.indexOf('.') !== currentCursorPosition) {
      return false;
    }
    if (charCode > 31 && (charCode < 46 || charCode > 57 || charCode === 47)) {
      return false;
    }
    return true; // Allow input
  }

   getCCCredentials() {
    this.paymentService.getCCCredentials(this.marketId).subscribe((res) => {
      if (res !== null) {
        this.paymentGatewayCredentials = res.GatewayDetails ? JSON.parse(res.GatewayDetails) : null;
        this.processCreditCardOnline = res.ProcessCreditCardOnline;
      }
    })
  }

  getWebRentalSetting() {
    const sub = this.marketInfoService.getWebRentalSetting(this.marketId).subscribe((res: any) => {
      if (res) {
        this.applyCCCharge = res.ApplyCcporcessingFeePercentage;
        this.CcfeeinPercentage = res.CcfeeinPercentage;
        this.AllowPartialPay = res.AllowPartialPay;             
        this.allowWeeklyRentalRenewal = res.AllowRenewalsMonthly;
        this.allowMonthlyRentalRenewal = res.AllowRenewalsMonthly;
        this.allowOtherItemRenewal = res.AllowRenewingOtherItems;
        this.applyRenewalFee = res.ApplyBookingFee;
        this.bookingRenewalFeeId = res.ApplyBookingFeeId;
        if (this.bookingRenewalFeeId) {
          this.subscriptions.push(this.rentalService.getBookingRenewalFeeById(this.marketId, this.bookingRenewalFeeId).subscribe((resFee: any) => {
            if (resFee) {
              this.renewalFeeAmount = (this.rentalType == 2 ? resFee.OtherItemMonthlyCharge :
                this.rentalType == 3 ? resFee.OtherItemWeeklyCharge : 0) ?? 0;
            }
          }));
        }
        this.bookingDetailsForm.get('paymentAmount').enable();
        if (!this.AllowPartialPay) {
          this.bookingDetailsForm.get('paymentAmount').disable();
          this.bookingDetailsForm.patchValue({
            paymentAmount: this.customCurrency.transform(this.actualTotalDue.toString()),
            totalPayment: this.customCurrency.transform(this.actualTotalDue.toString()),
          })
        }
        this.paymentAmount =  this.bookingDetailsForm.get('totalPayment').value.toString().includes('(') && this.bookingDetailsForm.get('totalPayment').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('totalPayment').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('totalPayment').value.toString().replace(/[^0-9.-]/g, ''));
        const creditCardAmount = this.bookingDetailsForm.get('paymentAmount').value.toString().includes('(') && this.bookingDetailsForm.get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, ''));
        if (this.applyCCCharge && creditCardAmount > 0) {
          this.calculateConvenienceFee();
        }
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  calculateConvenienceFee() {
    var convenienceFee = 0;
    var creditCardAmount = this.bookingDetailsForm.get('paymentAmount').value.toString().includes('(') && this.bookingDetailsForm.get('paymentAmount').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('paymentAmount').value.toString().replace(/[^0-9.-]/g, ''));
    var totalrentalCharge = this.bookingDetailsForm.get('rentalCharge').value.toString().includes('(') && this.bookingDetailsForm.get('rentalCharge').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('rentalCharge').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('rentalCharge').value.toString().replace(/[^0-9.-]/g, ''));
    var otherRentalCharge = this.bookingDetailsForm.get('otherCharge').value.toString().includes('(') && this.bookingDetailsForm.get('otherCharge').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('otherCharge').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('otherCharge').value.toString().replace(/[^0-9.-]/g, ''));
    var renewalFee = this.bookingDetailsForm.get('additionalCharge').value.toString().includes('(') && this.bookingDetailsForm.get('additionalCharge').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('additionalCharge').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('additionalCharge').value.toString().replace(/[^0-9.-]/g, ''));
    var totalDue = this.actualTotalDue + totalrentalCharge + otherRentalCharge;
    if(totalDue > 0){
      totalDue = totalDue + renewalFee;
    }
    else{
      renewalFee = 0;
    }
    if (creditCardAmount > 0) {
      var convenienceFee = creditCardAmount * ((this.CcfeeinPercentage) / 100);
      totalDue += convenienceFee;
      this.creditCardAmount += convenienceFee;
    }
    var totalAmount = 0;
    totalAmount = creditCardAmount + convenienceFee;
    const balanceAmount = totalDue - totalAmount;

    this.bookingDetailsForm.patchValue({
      convenienceFee: this.customCurrency.transform(convenienceFee.toString()),     
      balanceDue: this.customCurrency.transform(balanceAmount.toString()),
      totalPayment: this.customCurrency.transform(totalAmount.toString()),
      totalCharge: this.customCurrency.transform(totalDue.toString()),
    })
    this.paymentAmount =  this.bookingDetailsForm.get('totalPayment').value.toString().includes('(') && this.bookingDetailsForm.get('totalPayment').value.toString().includes(')') ? -1 * parseFloat(this.bookingDetailsForm.get('totalPayment').value.toString().replace(/[^0-9.-]/g, '')) : parseFloat(this.bookingDetailsForm.get('totalPayment').value.toString().replace(/[^0-9.-]/g, ''));
  }

  getRentalPaymentSettings() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.rentalService.getRentalSettings(this.marketId).subscribe((res: any) => {
      if (res) {
        this.ResaleNumberRequired = res.ResaleNumberRequired;
        this.DaysSellWithoutResaleNumber = res.DaysSellWithoutResaleNumber;
        this.BusinessLicenseNumberRequired = res.BusinessLicenseNumberRequired;
        this.DaysSellWithoutBusiLicNumber = res.DaysSellWithoutBusiLicNumber;
        this.MonthlyBookingEndDate = res.MonthlyBookingEndDate;
        this.masterRentChargeforResOtherItems = res.MasterChargeForOtherChargeRenewal;
        this.secondTaxName = res.SecondTaxName ? res.SecondTaxName: "Sec Level Tax";
      }
    });
  }

  updateRentalRecord(record) {
    if (record.Reservation && this.allowMonthlyRentalRenewal) {
      const rentEndPeriodIndex = this.monthlyMarketDays.findIndex(e => e.RentalMonths == record.RentEndPeriod);
      const renewalStartMonth = this.monthlyMarketDays[rentEndPeriodIndex +1].RentalMonths
      record.BookingCanExtend = true;
      record.RenewalStartMonth =renewalStartMonth
      record.MonthlyRenewalMonth = renewalStartMonth;
      record.RentEndPeriodIndex =rentEndPeriodIndex
      this.extendMonthlyRental(record);
    }
    else{
      record.BookingCanExtend = false;
      record.RenewalStartMonth = "This booking cannot be extended further!";
      record.MonthlyRenewalMonth = record.RentalMonth;
      this.totalRentalCharge -= record.Rent.toString().includes('(') && record.Rent.toString().includes(')') ? -1 * parseFloat(record.Rent.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.Rent.toString().replace(/[^0-9.-]/g, ''));
      this.totalSalesTax -= record.SalesTax.toString().includes('(') && record.SalesTax.toString().includes(')') ? -1 * parseFloat(record.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.SalesTax.toString().replace(/[^0-9.-]/g, ''));
      this.totalSecondLeveltax -= record.CityTax.toString().includes('(') && record.CityTax.toString().includes(')') ? -1 * parseFloat(record.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.CityTax.toString().replace(/[^0-9.-]/g, ''));
      this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
      this.bookingDetailsForm.patchValue({
        rentalCharge: this.customCurrency.transform(this.totalRentalCharge.toString()),
        totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
        totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
        totalCharge: this.customCurrency.transform(this.totalCharge.toString())
      });
      record.Rent = this.customCurrency.transform("0");
    }
  }

  extendMonthlyRental(extendRentalRecord) {
    const extendedRentalEndDates =extendRentalRecord.MonthlyRenewalMonth;
    Swal.fire({
      title: $localize`Confirm`,
      text: $localize`Are you sure you wish to extend the bookings till ` + extendedRentalEndDates + '? Please note that the extended period cannot be cancelled',
      icon: "question",
      showCancelButton: true,
      confirmButtonText: $localize`Yes`,
      cancelButtonText: $localize`No`,
    }).then(async (result) => {
      if (result.value) {
            var rentalDates = [];
            let noResale = true;
            const vendorRentalDays = [];
            var  marketDayCountForResale = 0 
            if ((this.ResaleNumberRequired && !this.exemptResale) || (this.BusinessLicenseNumberRequired)) {
              var rentalstartDate = "";
              var selectedBoothRecord = this.selectedMonthlyBooths.filter(e => e.NewBooth == true || e.ExtendRental == true);
              if (selectedBoothRecord.length > 0) {
                selectedBoothRecord.forEach(record => {
                  this.selectedMarketDays.map(el => {
                    const startDate = new Date(record.RentalStartDate);
                    const endDate = new Date(record.RentalEndDate);
                    const selectedDayOfWeek = el - 1;
                    let currentDay = new Date(startDate);
                   while (currentDay <= endDate) {                  
                      if (currentDay.getDay() === selectedDayOfWeek) {
                        if (!rentalstartDate) {
                          rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                        }
                        rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                      }
                      currentDay.setDate(currentDay.getDate() + 1);
                    }

                    const compareDates = (a: DateString, b: DateString): number => {
                      const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                      const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
                      return dateA - dateB;
                    };
                    const sortedDates = rentalDates.sort(compareDates);
                  });
                });    
              }
        
              if (extendRentalRecord.MonthlyRentalId != 0)
                var recordExist = this.selectedMonthlyBooths.findIndex(e => e.RentStartPeriod == extendRentalRecord.RentStartPeriod && e.RentEndPeriod == extendRentalRecord.RentEndPeriod
                  && e.BoothNumber == extendRentalRecord.BoothNumber && e.MonthlyRentalId == extendRentalRecord.MonthlyRentalId);
              else
                var recordExist = this.selectedMonthlyBooths.findIndex(e => e.RentStartPeriod == extendRentalRecord.RentStartPeriod && e.RentEndPeriod == extendRentalRecord.RentEndPeriod
                  && e.BoothNumber == extendRentalRecord.BoothNumber);
          
              if (recordExist != -1) {            
                var rentalstartDate = "";
                for(const el of this.selectedMarketDays){
                  const selectedDayOfWeek = el - 1;
                  const startDate = new Date(this.selectedMonthlyBooths[recordExist].RentalStartDate);
                  const endMonth = extendRentalRecord.MonthlyRenewalMonth;
                  const [endMonthDate, endMonthYear] = endMonth.split(' ');
                  const endMonthIndex = this.getMonthIndex(endMonthDate);
                  var endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
                  if (this.MonthlyBookingEndDate) {
                    const rentalEndDateYear = endDate.getFullYear();
                    const rentalEndDateMonth = endDate.getMonth() + 1;
                    const rentalEndDateYearMonth = `${rentalEndDateYear}${rentalEndDateMonth < 10 ? '0' : ''}${rentalEndDateMonth}`;
                    endDate = await this.getMonthEndingDateFromSetting(rentalEndDateYearMonth);
                  }
                  let currentDay = new Date(startDate);
                  while (currentDay <= endDate) {                   
                    if (currentDay.getDay() === selectedDayOfWeek) {
                      rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
                    }
                    currentDay.setDate(currentDay.getDate() + 1);
                  }
                 }
              }
        
              rentalDates.map(date => {
                const targetDateString = this.datePipe.transform(date.startDate, "MM/dd/yyyy");
                const dateExist = vendorRentalDays.find(el => {
                  const rentalDateString = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
                  return rentalDateString === targetDateString;
                });
                if (!dateExist) {
                  const formattedDate = this.datePipe.transform(date.startDate, "MM/dd/yyyy");
                  vendorRentalDays.push({ VendorRentalDate: formattedDate });
                  marketDayCountForResale += 1;
                  if (marketDayCountForResale > this.DaysSellWithoutResaleNumber) {
                    return; // exits the forEach loop when the condition is met
                  }
                }
              });
        
              const obj = {
                VendorID: this.vendorId,
                VendorRentalDate: new Date()
              };
        
              this.subscriptions.push(this.rentalService.getVendorRentalDays(obj).subscribe((res: any[]) => {
                  if (marketDayCountForResale <= this.DaysSellWithoutResaleNumber || marketDayCountForResale <= this.DaysSellWithoutBusiLicNumber) {
                  if (res && res.length > 0) {
                    res.forEach(el => {
                      const targetDateString = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
                      const dateExist = vendorRentalDays.find(data => {
                        const rentalDateString = this.datePipe.transform(data.VendorRentalDate, "MM/dd/yyyy");
                        return rentalDateString === targetDateString;
                      });
                      if (!dateExist) {
                        const formattedDate = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
                        vendorRentalDays.push({ VendorRentalDate: formattedDate });
                        marketDayCountForResale += 1;
                      }
                    });
                  }
                }
                if (this.ResaleNumberRequired && !this.exemptResale && !this.resaleNo && marketDayCountForResale > this.DaysSellWithoutResaleNumber && !this.resaleOptionSelected) {
                  const modal = this.modalResaleNo.nativeElement;
                  modal.classList.add('show');
                  if (modal.classList.contains('show')) {
                    modal.style.display = 'block';
                  }
                  noResale = false;
                }
                else if (this.BusinessLicenseNumberRequired && marketDayCountForResale > this.DaysSellWithoutBusiLicNumber) {
                  if (!this.businessLicenseNumber) {
                    Swal.fire({
                      title: $localize`Confirm`,
                      text: $localize`Business License Number is required! Do you want to continue without Business License Number?`,
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonText: $localize`Yes`,
                      cancelButtonText: $localize`No`,
                    }).then((result) => {
                      if (result.value) {
                        this.proceedExtendingRental(extendRentalRecord);
                      }
                      else {
                        //this.refreshAddBooth();
                      }
                    });
                  }
                  else {
                    if (!this.businessLicenseExpiryDate) {
                      Swal.fire({
                        title: $localize`Confirm`,
                        text: $localize`Business License expiry date is not provided! Do you want to continue without that?`,
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonText: $localize`Yes`,
                        cancelButtonText: $localize`No`,
                      }).then((result) => {
                        if (result.value) {
                          this.proceedExtendingRental(extendRentalRecord);
                        }
                        else {
                         // this.refreshAddBooth();
                        }
                      });
                    }
                    else {
                      if (new Date(this.businessLicenseExpiryDate) < new Date()) {
                        Swal.fire({
                          title: $localize`Confirm`,
                          text: $localize`Business License is expired! Do you want to continue?`,
                          icon: "question",
                          showCancelButton: true,
                          confirmButtonText: $localize`Yes`,
                          cancelButtonText: $localize`No`,
                        }).then((result) => {
                          if (result.value) {
                            this.proceedExtendingRental(extendRentalRecord);
                          }
                          else {
                           // this.refreshAddBooth();
                          }
                        });
                      }
                      else {
                        this.proceedExtendingRental(extendRentalRecord);
                      }
                    }
                  }
                }
                else {
                  noResale = true;
                  this.proceedExtendingRental(extendRentalRecord);
                }
              }));
            } else {
              this.proceedExtendingRental(extendRentalRecord);
            }
      }
    });
  }

  async proceedExtendingRental(extendRentalRecord) {
    if (extendRentalRecord.MonthlyRentalId != 0)
      var recordExist = this.selectedMonthlyBooths.findIndex(e => e.RentStartPeriod == extendRentalRecord.RentStartPeriod && e.RentEndPeriod == extendRentalRecord.RentEndPeriod
        && e.BoothNumber == extendRentalRecord.BoothNumber && e.MonthlyRentalId == extendRentalRecord.MonthlyRentalId);
    else
      var recordExist = this.selectedMonthlyBooths.findIndex(e => e.RentStartPeriod == extendRentalRecord.RentStartPeriod && e.RentEndPeriod == extendRentalRecord.RentEndPeriod
        && e.BoothNumber == extendRentalRecord.BoothNumber && e.RentalMarketDay == extendRentalRecord.RentalMarketDay);

    if (recordExist != -1) {
      if (!this.selectedMonthlyBooths[recordExist].Drop && this.selectedMonthlyBooths[recordExist].Reservation && this.allowMonthlyRentalRenewal) {
        const rentalDates = [];
        var currentMonthRentalDates = [];
        var rentalstartDate;
        var marketDays = 0;
        var marketDay;
        
        if (this.selectedMonthlyBooths[recordExist].Sunday == true) {
          marketDay = 'Sun'
        }
        else if (this.selectedMonthlyBooths[recordExist].Monday == true) {
          marketDay = 'Mon'
        }
        else if (this.selectedMonthlyBooths[recordExist].Tuesday == true) {
          marketDay = 'Tue'
        }
        else if (this.selectedMonthlyBooths[recordExist].Wednesday == true) {
          marketDay = 'Wed'
        }
        else if (this.selectedMonthlyBooths[recordExist].Thursday == true) {
          marketDay = 'Thu'
        }
        else if (this.selectedMonthlyBooths[recordExist].Friday == true) {
          marketDay = 'Fri'
        }
        else if (this.selectedMonthlyBooths[recordExist].Saturday == true) {
          marketDay = 'Sat'
        }
      
        for (const el of this.selectedMarketDays) {
          const selectedDayOfWeek = el - 1;
          var endDateYearMonth;
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth();
          const currentYearMonth = `${currentYear}${(currentMonth + 1) < 10 ? '0' : ''}${(currentMonth + 1)}`;
          var startDate = new Date(this.selectedMonthlyBooths[recordExist].RentalStartDate);
          const endMonth = extendRentalRecord.MonthlyRenewalMonth;
          const [endMonthDate, endMonthYear] = endMonth.split(' ');
          const endMonthIndex = this.getMonthIndex(endMonthDate);
          var endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
          if (this.MonthlyBookingEndDate) {
            const rentalEndDateYear = endDate.getFullYear();
            const rentalEndDateMonth = endDate.getMonth() + 1;
            const rentalEndDateYearMonth = `${rentalEndDateYear}${rentalEndDateMonth < 10 ? '0' : ''}${rentalEndDateMonth}`;
            endDate = await this.getMonthEndingDateFromSetting(rentalEndDateYearMonth);
            endDateYearMonth = rentalEndDateYearMonth;

            const rentalStartDate = new Date(startDate);
            var previousMonth = new Date(rentalStartDate.setMonth(rentalStartDate.getMonth() - 1)).getMonth() + 1;
            var PreviousMonthYear = new Date(rentalStartDate.setMonth(rentalStartDate.getMonth() - 1)).getFullYear();
            const PreviousMonthYearAndMonth = `${PreviousMonthYear}${previousMonth < 10 ? '0' : ''}${previousMonth}`;
            // if (PreviousMonthYearAndMonth >= currentYearMonth) {
            const monthEndDate = await this.getMonthEndingDateFromSetting(PreviousMonthYearAndMonth);
            const dateDiff = this.getDaysDifference(monthEndDate, new Date(startDate)); //calculates the diff between current month start date and previous monthend date     
            if (dateDiff <= 7 && new Date(monthEndDate) < startDate) {
              if (new Date() > new Date(monthEndDate)) {
                startDate = new Date();
              }
              else {
                startDate = new Date(monthEndDate.setDate(monthEndDate.getDate() + 1));
              }
            }
            // }
          }
          var previousRentalYearMonth;
          var currentMonthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
          if (this.MonthlyBookingEndDate) {
            const currentStartMonth = this.selectedMonthlyBooths[recordExist].RentStartPeriod;
            const [startMonthDate, startMonthYear] = currentStartMonth.split(' ');
            const startMonthIndex = this.getMonthIndex(startMonthDate);
            var currentMonthEnd = new Date(startMonthYear, startMonthIndex + 1, 0);
            const rentalEndDateYear = currentMonthEnd.getFullYear();
            const rentalEndDateMonth = currentMonthEnd.getMonth() + 1;
            const rentalEndDateYearMonth = `${rentalEndDateYear}${rentalEndDateMonth < 10 ? '0' : ''}${rentalEndDateMonth}`;
            previousRentalYearMonth = rentalEndDateYearMonth;
            if (endDateYearMonth == rentalEndDateYearMonth)
              currentMonthEnd = await this.getMonthEndingDateFromSetting(rentalEndDateYearMonth);
          }

          let currentDay = new Date(startDate);
          while (currentDay <= endDate) {
            if (currentDay.getDay() === selectedDayOfWeek) {
              if (!rentalstartDate) {
                rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
              }
              marketDays += 1;
              rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
            }
            currentDay.setDate(currentDay.getDate() + 1);
          }

          if (this.MonthlyBookingEndDate) {
            currentDay = new Date(startDate);
            while (currentDay <= currentMonthEnd) {
              if (currentDay.getDay() === selectedDayOfWeek) {
                if (!rentalstartDate) {
                  rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
                }
                //marketDays += 1;
                currentMonthRentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
              }
              currentDay.setDate(currentDay.getDate() + 1);
            }
          }
        }

        const compareDates = (a: DateString, b: DateString): number => {
          const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
          const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
          return dateA - dateB;
        };
        const sortedDates = rentalDates.sort(compareDates);

        if (this.MonthlyBookingEndDate) {
          const compareCurrentMonthDates = (a: DateString, b: DateString): number => {
            const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
            const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
            return dateA - dateB;
          };
          const currentMonthSortedDates = currentMonthRentalDates.sort(compareCurrentMonthDates);
        }
        else {
          currentMonthRentalDates = rentalDates;
        }

        var allRentalDates = rentalDates.map(el => new Date(el.startDate));
        var currentRentedEndDate = new Date(this.selectedMonthlyBooths[recordExist].RentalEndDate);
        const filteredRentalDates = allRentalDates.filter(rentalDate => rentalDate > currentRentedEndDate);
        var obj1 = {
          RentalDates: filteredRentalDates,
          BoothNumber: this.selectedMonthlyBooths[recordExist].BoothNumber
        }

        this.subscriptions.push(this.rentalService.checkRentalExits(this.marketId, obj1).subscribe((res) => {
          if (!res.Success) {
            var RentAmount = this.selectedMonthlyBooths[recordExist].Rent;
            var SalesTax = this.selectedMonthlyBooths[recordExist].SalesTax;
            var CityTax = this.selectedMonthlyBooths[recordExist].CityTax;          
            var JanRentAmount = this.selectedMonthlyBooths[recordExist].January ?? 0;
            var FebRentAmount = this.selectedMonthlyBooths[recordExist].February ?? 0;
            var MarRentAmount = this.selectedMonthlyBooths[recordExist].March ?? 0;
            var AprRentAmount = this.selectedMonthlyBooths[recordExist].April ?? 0;
            var MayRentAmount = this.selectedMonthlyBooths[recordExist].May ?? 0;
            var JunRentAmount = this.selectedMonthlyBooths[recordExist].June ?? 0;
            var JulRentAmount = this.selectedMonthlyBooths[recordExist].July ?? 0;
            var AugRentAmount = this.selectedMonthlyBooths[recordExist].August ?? 0;
            var SepRentAmount = this.selectedMonthlyBooths[recordExist].September ?? 0;
            var OctRentAmount = this.selectedMonthlyBooths[recordExist].October ?? 0;
            var NovRentAmount = this.selectedMonthlyBooths[recordExist].November ?? 0;
            var DecRentAmount = this.selectedMonthlyBooths[recordExist].December ?? 0;

            this.totalRentalCharge -= RentAmount.toString().includes('(') && RentAmount.toString().includes(')') ? -1 * parseFloat(RentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(RentAmount.toString().replace(/[^0-9.-]/g, ''));
            this.totalSalesTax -= SalesTax.toString().includes('(') && SalesTax.toString().includes(')') ? -1 * parseFloat(SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(SalesTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalSecondLeveltax -= CityTax.toString().includes('(') && CityTax.toString().includes(')') ? -1 * parseFloat(CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(CityTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
            this.bookingDetailsForm.patchValue({
              rentalCharge: this.customCurrency.transform(this.totalRentalCharge.toString()),
              totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
              totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
              totalCharge: this.customCurrency.transform(this.totalCharge.toString())
            });

            var extendRentalStartPeriod;
            if (!this.selectedMonthlyBooths[recordExist].NewBooth && this.selectedMonthlyBooths[recordExist].Status != "R") {
              extendRentalStartPeriod = new Date(this.selectedMonthlyBooths[recordExist].RentalSavedEndDate);
              if (extendRentalStartPeriod.getDate() == 31) {
                extendRentalStartPeriod.setDate(extendRentalStartPeriod.getDate() + 1);
              }
              else {
                extendRentalStartPeriod.setMonth(extendRentalStartPeriod.getMonth() + 1);
              }
              extendRentalStartPeriod.setDate(1);
            }
            else {
              extendRentalStartPeriod = this.selectedMonthlyBooths[recordExist].RentalStartDate;
            }
            var obj = {
              BoothTypeId: this.selectedMonthlyBooths[recordExist].BoothTypeId,
              VendorId: this.vendorId,
              RentalStartPeriod: new Date(extendRentalStartPeriod),
              RentalEndPeriod: new Date(rentalDates[rentalDates.length - 1].startDate),
              PerDiemApplicable: this.selectedMonthlyBooths[recordExist].PerDiem,
              RentalMarketDay : marketDay,
              JanRent: JanRentAmount.toString().includes('(') && JanRentAmount.toString().includes(')') ? -1 * parseFloat(JanRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(JanRentAmount.toString().replace(/[^0-9.-]/g, '')),
              FebRent: FebRentAmount.toString().includes('(') && FebRentAmount.toString().includes(')') ? -1 * parseFloat(FebRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(FebRentAmount.toString().replace(/[^0-9.-]/g, '')),
              MarRent: MarRentAmount.toString().includes('(') && MarRentAmount.toString().includes(')') ? -1 * parseFloat(MarRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(MarRentAmount.toString().replace(/[^0-9.-]/g, '')),
              AprRent: AprRentAmount.toString().includes('(') && AprRentAmount.toString().includes(')') ? -1 * parseFloat(AprRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(AprRentAmount.toString().replace(/[^0-9.-]/g, '')),
              MayRent: MayRentAmount.toString().includes('(') && MayRentAmount.toString().includes(')') ? -1 * parseFloat(MayRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(MayRentAmount.toString().replace(/[^0-9.-]/g, '')),
              JunRent: JunRentAmount.toString().includes('(') && JunRentAmount.toString().includes(')') ? -1 * parseFloat(JunRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(JunRentAmount.toString().replace(/[^0-9.-]/g, '')),
              JulRent: JulRentAmount.toString().includes('(') && JulRentAmount.toString().includes(')') ? -1 * parseFloat(JulRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(JulRentAmount.toString().replace(/[^0-9.-]/g, '')),
              AugRent: AugRentAmount.toString().includes('(') && AugRentAmount.toString().includes(')') ? -1 * parseFloat(AugRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(AugRentAmount.toString().replace(/[^0-9.-]/g, '')),
              SepRent: SepRentAmount.toString().includes('(') && SepRentAmount.toString().includes(')') ? -1 * parseFloat(SepRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(SepRentAmount.toString().replace(/[^0-9.-]/g, '')),
              OctRent: OctRentAmount.toString().includes('(') && OctRentAmount.toString().includes(')') ? -1 * parseFloat(OctRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(OctRentAmount.toString().replace(/[^0-9.-]/g, '')),
              NovRent: NovRentAmount.toString().includes('(') && NovRentAmount.toString().includes(')') ? -1 * parseFloat(NovRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(NovRentAmount.toString().replace(/[^0-9.-]/g, '')),
              DecRent: DecRentAmount.toString().includes('(') && DecRentAmount.toString().includes(')') ? -1 * parseFloat(DecRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(DecRentAmount.toString().replace(/[^0-9.-]/g, '')),
            }

            this.subscriptions.push(this.rentalService.getBoothRentalMontlyCharges(this.marketId, obj).subscribe((res: any) => {
              this.selectedMonthlyBooths[recordExist].RentEndPeriod = extendRentalRecord.MonthlyRenewalMonth;
              this.selectedMonthlyBooths[recordExist].RentalEndDate = this.datePipe.transform(new Date(rentalDates[rentalDates.length - 1].startDate), 'MMM d, y');
              this.selectedMonthlyBooths[recordExist].MktDays = marketDays;
              this.selectedMonthlyBooths[recordExist].Rent = this.customCurrency.transform(res.Rent);
              this.selectedMonthlyBooths[recordExist].SalesTax = this.customCurrency.transform(res.SalesTax);
              this.selectedMonthlyBooths[recordExist].CityTax = this.customCurrency.transform(res.CityTax);
              this.selectedMonthlyBooths[recordExist].January = this.customCurrency.transform(res.JanRent);
              this.selectedMonthlyBooths[recordExist].February = this.customCurrency.transform(res.FebRent);
              this.selectedMonthlyBooths[recordExist].March = this.customCurrency.transform(res.MarRent);
              this.selectedMonthlyBooths[recordExist].April = this.customCurrency.transform(res.AprRent);
              this.selectedMonthlyBooths[recordExist].May = this.customCurrency.transform(res.MayRent);
              this.selectedMonthlyBooths[recordExist].June = this.customCurrency.transform(res.JunRent);
              this.selectedMonthlyBooths[recordExist].July = this.customCurrency.transform(res.JulRent);
              this.selectedMonthlyBooths[recordExist].August = this.customCurrency.transform(res.AugRent);
              this.selectedMonthlyBooths[recordExist].September = this.customCurrency.transform(res.SepRent);
              this.selectedMonthlyBooths[recordExist].October = this.customCurrency.transform(res.OctRent);
              this.selectedMonthlyBooths[recordExist].November = this.customCurrency.transform(res.NovRent);
              this.selectedMonthlyBooths[recordExist].December = this.customCurrency.transform(res.DecRent);
              this.selectedMonthlyBooths[recordExist].JanuaryMonthPresent = res.JanuaryMonthPresent;
              this.selectedMonthlyBooths[recordExist].FebruaryMonthPresent = res.FebruaryMonthPresent;
              this.selectedMonthlyBooths[recordExist].MarchMonthPresent = res.MarchMonthPresent;
              this.selectedMonthlyBooths[recordExist].AprilMonthPresent = res.AprilMonthPresent;
              this.selectedMonthlyBooths[recordExist].MayMonthPresent = res.MayMonthPresent;
              this.selectedMonthlyBooths[recordExist].JuneMonthPresent = res.JuneMonthPresent;
              this.selectedMonthlyBooths[recordExist].JulyMonthPresent = res.JulyMonthPresent;
              this.selectedMonthlyBooths[recordExist].AugustMonthPresent = res.AugustMonthPresent;
              this.selectedMonthlyBooths[recordExist].SeptemberMonthPresent = res.SeptemberMonthPresent;
              this.selectedMonthlyBooths[recordExist].OctoberMonthPresent = res.OctoberMonthPresent;
              this.selectedMonthlyBooths[recordExist].NovemberMonthPresent = res.NovemberMonthPresent;
              this.selectedMonthlyBooths[recordExist].DecemberMonthPresent = res.DecemberMonthPresent;
              this.selectedMonthlyBooths[recordExist].DisableJanMonth = res.JanRent || res.JanRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].DisableFebMonth = res.FebRent || res.FebRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].DisableMarMonth = res.MarRent || res.MarRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].DisableAprMonth = res.AprRent || res.AprRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].DisableMayMonth = res.MayRent || res.MayRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].DisableJunMonth = res.JunRent || res.JunRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].DisableJulMonth = res.JulRent || res.JulRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].DisableAugMonth = res.AugRent || res.AugRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].DisableSepMonth = res.SepRent || res.SepRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].DisableOctMonth = res.OctRent || res.OctRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].DisableNovMonth = res.NovRent || res.NovRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].DisableDecMonth = res.DecRent || res.DecRent == 0 ? false : true;
              this.selectedMonthlyBooths[recordExist].StartDateList = currentMonthRentalDates;
              this.selectedMonthlyBooths[recordExist].ExtendRental = true;
              this.selectedMonthlyBooths[recordExist].AllRentalDates = rentalDates.map(el => new Date(el.startDate));

              this.totalRentalCharge += res.Rent.toString().includes('(') && res.Rent.toString().includes(')') ? -1 * parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, ''));
              this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
              this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
              this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
              this.bookingDetailsForm.patchValue({
                rentalCharge: this.customCurrency.transform(this.totalRentalCharge.toString()),
                totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
                totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
                totalCharge: this.customCurrency.transform(this.totalCharge.toString())
              });
              // this.getDiscounts();
            }, err => {
            }));
          }
          else {
            Swal.fire({
              title: $localize`Warning`,
              text: $localize`Rental End Period cannot be extended further`,
              icon: "warning",
              confirmButtonText: $localize`Ok`,
            });
          }
        }));
      }
      else {
        this.selectedMonthlyBooths[recordExist].MonthlyRenewalMonth =  this.selectedMonthlyBooths[recordExist].RentEndPeriod;
        this.selectedMonthlyBooths[recordExist].RenewalStartMonth = "This booking cannot be extended further!"
        this.selectedMonthlyBooths[recordExist].BookingCanExtend = false;
      }
    }
  }

  closeResaleModal(){
   // this.refreshAddBooth();
    const modal = this.modalResaleNo.nativeElement;
    if (modal.classList.contains('show')) {
      modal.style.display = 'none';
      modal.classList.remove('show');
    }
  }

  resaleOptionValidation() {
    this.exemptResaleReason = "";
    this.resaleNo = "";
  }

  getWeeklyRental() {
    this.loaderIcon = Promise.resolve(true);
    this.selectedWeeklyBooths = [];
    var existingBooths = [];
    const sub = this.rentalService.getWeeklyBookings(this.marketId,this.vendorId).subscribe((res: any[]) => {
      if (res) {
        existingBooths = res;
        existingBooths.forEach((el) => {
          var marketDay;
          if (el.Sunday) {
            marketDay = 'Sun'
          }
          else if (el.Monday) {
            marketDay = 'Mon'
          }
          else if (el.Tuesday) {
            marketDay = 'Tue'
          }
          else if (el.Wednesday) {
            marketDay = 'Wed'
          }
          else if (el.Thursday) {
            marketDay = 'Thu'
          }
          else if (el.Friday) {
            marketDay = 'Fri'
          }
          else if (el.Saturday) {
            marketDay = 'Sat'
          }
          
          if (el.Status == "R") {
            const rentEndPeriodIndex = this.weeklyMarketDays.findIndex(e=>e.RentalEndDays == rentalEndDate);
            const renewalStartWeek = this.weeklyMarketDays[rentEndPeriodIndex +1].RentalEndDays
            var obj = {
              BoothTypeId: el.BoothTypeId,
              VendorId: this.vendorId,
              RentalStartPeriod: new Date(this.weeklyMarketDays[0].RentalEndDays.split('-')[0]),
              RentalEndPeriod: new Date(this.weeklyMarketDays[0].RentalEndDays.split('-')[1]),
              RentalMarketDay: marketDay
            }
            this.rentalService.getBoothRentalWeeklyCharges(this.marketId,obj).subscribe((res: any) => {
              const ob = {
                WeeklyRentalId: el.WeeklyRentalId,
                BoothNumber: el.BoothNumber,
                BoothType: el.BoothType,
                BoothTypeId: el.BoothTypeId,
                RentStartPeriod: this.weeklyMarketDays[0].RentalEndDays,
                CurrentRentStartPeriod: this.weeklyMarketDays[0].RentalEndDays,
                RentEndPeriod: this.weeklyMarketDays[0].RentalEndDays,
                CurrentRentEndPeriod: this.weeklyMarketDays[0].RentalEndDays,
                RentalSavedEndDate: this.weeklyMarketDays[0].RentalEndDays.split('-')[1],
                Product: el.ProductId,
                ProductName: this.vendorProducts.filter(e=>e.ProductId == el.ProductId)[0]?.ProductName,
                Rent: this.customCurrency.transform(res.Rent),
                SalesTax: this.customCurrency.transform(res.SalesTax),
                CityTax: this.customCurrency.transform(res.CityTax),
                CurrentRent: this.customCurrency.transform(el.RentalCharge),
                CurrentSalesTax: this.customCurrency.transform(el.SalesTax),
                CurrentSecondTax: this.customCurrency.transform(el.SecLevelTax),
                Sunday: el.Sunday,
                Monday: el.Monday,
                Tuesday: el.Tuesday,
                Wednesday: el.Wednesday,
                Thursday: el.Thursday,
                Friday: el.Friday,
                Saturday: el.Saturday,
                Reservation: el.BoothReserved,
                WeekDay: marketDay,
                Drop: el.DoNotRenew,
                January: this.customCurrency.transform(res.JanRent),
                February: this.customCurrency.transform(res.FebRent),
                March: this.customCurrency.transform(res.MarRent),
                April: this.customCurrency.transform(res.AprRent),
                May: this.customCurrency.transform(res.MayRent),
                June: this.customCurrency.transform(res.JunRent),
                July: this.customCurrency.transform(res.JulRent),
                August: this.customCurrency.transform(res.AugRent),
                September: this.customCurrency.transform(res.SepRent),
                October: this.customCurrency.transform(res.OctRent),
                November: this.customCurrency.transform(res.NovRent),
                December: this.customCurrency.transform(res.DecRent),
                JanuaryMonthPresent: res.JanuaryMonthPresent,
                FebruaryMonthPresent: res.FebruaryMonthPresent,
                MarchMonthPresent: res.MarchMonthPresent,
                AprilMonthPresent: res.AprilMonthPresent,
                MayMonthPresent: res.MayMonthPresent,
                JuneMonthPresent: res.JuneMonthPresent,
                JulyMonthPresent: res.JulyMonthPresent,
                AugustMonthPresent: res.AugustMonthPresent,
                SeptemberMonthPresent: res.SeptemberMonthPresent,
                OctoberMonthPresent: res.OctoberMonthPresent,
                NovemberMonthPresent: res.NovemberMonthPresent,
                DecemberMonthPresent: res.DecemberMonthPresent,
                DisableJanMonth: res.JanRent ? false : true,
                DisableFebMonth: res.FebRent ? false : true,
                DisableMarMonth: res.MarRent ? false : true,
                DisableAprMonth: res.AprRent ? false : true,
                DisableMayMonth: res.MayRent ? false : true,
                DisableJunMonth: res.JunRent ? false : true,
                DisableJulMonth: res.JulRent ? false : true,
                DisableAugMonth: res.AugRent ? false : true,
                DisableSepMonth: res.SepRent ? false : true,
                DisableOctMonth: res.OctRent ? false : true,
                DisableNovMonth: res.NovRent ? false : true,
                DisableDecMonth: res.DecRent ? false : true,
                Status: el.Status,
                NewBooth: false,
                BookingCanExtend: true,
                RenewalStartWeek:renewalStartWeek,
                CWRenewalWeek:this.weeklyMarketDays[rentEndPeriodIndex +1].RentalEndDays,
                RentEndPeriodIndex:rentEndPeriodIndex
              }
              this.totalRentalCharge += res.Rent.toString().includes('(') && res.Rent.toString().includes(')') ? -1 * parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, ''));
              this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
              this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
               this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
              this.bookingDetailsForm.patchValue({
                rentalCharge: this.customCurrency.transform(this.totalRentalCharge.toString()),
                totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
                totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
                totalCharge: this.customCurrency.transform(this.totalCharge.toString())
              });
              this.selectedWeeklyBooths.push(ob);
            });
          }
          else {
            var JanuaryMonthPresent = false;
            var FebruaryMonthPresent = false;
            var MarchMonthPresent = false;
            var AprilMonthPresent = false;
            var MayMonthPresent = false;
            var JuneMonthPresent = false;
            var JulyMonthPresent = false;
            var AugustMonthPresent = false;
            var SeptemberMonthPresent = false;
            var OctoberMonthPresent = false;
            var NovemberMonthPresent = false;
            var DecemberMonthPresent = false;

            var rentalStartDateRecord = this.weeklyMarketDays.filter(date => this.datePipe.transform(new Date(date.RentalStartDays.split('-')[0]), 'MM/dd/yyyy') <= this.datePipe.transform(new Date(el.RentalStartDate), 'MM/dd/yyyy')
              && this.datePipe.transform(new Date(date.RentalStartDays.split('-')[1]), 'MM/dd/yyyy') >= this.datePipe.transform(new Date(el.RentalStartDate), 'MM/dd/yyyy'))
            if (rentalStartDateRecord.length > 0)
              var rentalStartDate = rentalStartDateRecord[0].RentalStartDays;

            var rentalEndDateRecord = this.weeklyMarketDays.filter(date => this.datePipe.transform(new Date(date.RentalEndDays.split('-')[0]), 'MM/dd/yyyy') <= this.datePipe.transform(new Date(el.RentalEndDate), 'MM/dd/yyyy')
              && this.datePipe.transform(new Date(date.RentalEndDays.split('-')[1]), 'MM/dd/yyyy') >= this.datePipe.transform(new Date(el.RentalEndDate), 'MM/dd/yyyy'))
            if (rentalEndDateRecord.length > 0)
              var rentalEndDate = rentalEndDateRecord[0].RentalEndDays;

            const rentEndPeriodIndex = this.weeklyMarketDays.findIndex(e=>e.RentalEndDays == rentalEndDate);
            const renewalStartWeek = this.weeklyMarketDays[rentEndPeriodIndex +1].RentalEndDays

            if (rentalStartDate && rentalEndDate) {
              var month = this.datePipe.transform(rentalEndDate.split('-')[1], 'MMM');
              switch (month.toLowerCase()) {
                case "jan":
                  JanuaryMonthPresent = true;
                  break;
                case "feb":
                  FebruaryMonthPresent = true;
                  break;
                case "mar":
                  MarchMonthPresent = true;
                  break;
                case "apr":
                  AprilMonthPresent = true;
                  break;
                case "may":
                  MayMonthPresent = true;
                  break;
                case "jun":
                  JuneMonthPresent = true;
                  break;
                case "jul":
                  JulyMonthPresent = true;
                  break;
                case "aug":
                  AugustMonthPresent = true;
                  break;
                case "sep":
                  SeptemberMonthPresent = true;
                  break;
                case "oct":
                  OctoberMonthPresent = true;
                  break;
                case "nov":
                  NovemberMonthPresent = true;
                  break;
                case "dec":
                  DecemberMonthPresent = true;
                  break;
              }

              const ob = {
                WeeklyRentalId: el.WeeklyRentalId,
                BoothNumber: el.BoothNumber,
                BoothType: el.BoothType,
                BoothTypeId: el.BoothTypeId,
                RentStartPeriod: rentalStartDate,
                RentEndPeriod: rentalEndDate,
                CurrentRentStartPeriod: this.weeklyMarketDays[0].RentalEndDays,
                CurrentRentEndPeriod: this.weeklyMarketDays[0].RentalEndDays,
                RentalSavedEndDate: rentalEndDate,
                Product: el.ProductId,
                ProductName: this.vendorProducts.filter(e=>e.ProductId == el.ProductId)[0]?.ProductName,
                Rent: this.customCurrency.transform('0.00'),
                SalesTax: this.customCurrency.transform('0.00'),
                CityTax: this.customCurrency.transform('0.00'),
                CurrentRent: this.customCurrency.transform(el.RentalCharge),
                CurrentSalesTax: this.customCurrency.transform(el.SalesTax),
                CurrentSecondTax: this.customCurrency.transform(el.SecLevelTax),
                Sunday: el.Sunday,
                Monday: el.Monday,
                Tuesday: el.Tuesday,
                Wednesday: el.Wednesday,
                Thursday: el.Thursday,
                Friday: el.Friday,
                Saturday: el.Saturday,
                WeekDay: marketDay,
                Reservation: el.BoothReserved,
                Drop: el.DoNotRenew,
                January: this.customCurrency.transform('0.00'),
                February: this.customCurrency.transform('0.00'),
                March: this.customCurrency.transform('0.00'),
                April: this.customCurrency.transform('0.00'),
                May: this.customCurrency.transform('0.00'),
                June: this.customCurrency.transform('0.00'),
                July: this.customCurrency.transform('0.00'),
                August: this.customCurrency.transform('0.00'),
                September: this.customCurrency.transform('0.00'),
                October: this.customCurrency.transform('0.00'),
                November: this.customCurrency.transform('0.00'),
                December: this.customCurrency.transform('0.00'),
                NewBooth: false,
                JanuaryMonthPresent: JanuaryMonthPresent,
                FebruaryMonthPresent: FebruaryMonthPresent,
                MarchMonthPresent: MarchMonthPresent,
                AprilMonthPresent: AprilMonthPresent,
                MayMonthPresent: MayMonthPresent,
                JuneMonthPresent: JuneMonthPresent,
                JulyMonthPresent: JulyMonthPresent,
                AugustMonthPresent: AugustMonthPresent,
                SeptemberMonthPresent: SeptemberMonthPresent,
                OctoberMonthPresent: OctoberMonthPresent,
                NovemberMonthPresent: NovemberMonthPresent,
                DecemberMonthPresent: DecemberMonthPresent,
                DisableJanMonth: true,
                DisableFebMonth: true,
                DisableMarMonth: true,
                DisableAprMonth: true,
                DisableMayMonth: true,
                DisableJunMonth: true,
                DisableJulMonth: true,
                DisableAugMonth: true,
                DisableSepMonth: true,
                DisableOctMonth: true,
                DisableNovMonth: true,
                DisableDecMonth: true,
                BookingCanExtend: true,
                RenewalStartWeek:renewalStartWeek,
                CWRenewalWeek:this.weeklyMarketDays[rentEndPeriodIndex +1].RentalEndDays,
                RentEndPeriodIndex:rentEndPeriodIndex
              }
              this.selectedWeeklyBooths.push(ob);
            }
          }
        });
        this.loaderIcon = Promise.resolve(false);
        this.handleWeeklyEndDateChange();
      }
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  async handleWeeklyEndDateChange() {
    this.renewAllBoothsFromGrid = true;
    this.vendorRentalDays = [];
    this.marketDayCountForResale = 0;

    let rentalDates = this.calculateRentalDates();
    await this.processVendorRentalDays(rentalDates);
  }

  calculateRentalDates() {
    let rentalDates = [];
    const selectedBooths = this.selectedWeeklyBooths.filter(e => e.NewBooth || e.ExtendRental);
  
    selectedBooths.forEach(record => {
      const selectedDayOfWeek = this.getSelectedDayOfWeek(record);
      let currentDay = new Date(record.RentalStartDate);
      const endDate = new Date(record.RentalEndDate);
  
      while (currentDay <= endDate) {
        if (currentDay.getDay() === selectedDayOfWeek) {
          rentalDates.push({ startDate: this.datePipe.transform(currentDay, 'MMM d, y') });
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }
    });
  
    return rentalDates;
  }

  getSelectedDayOfWeek(record) {
    return record.Sunday ? 0 :
           record.Monday ? 1 :
           record.Tuesday ? 2 :
           record.Wednesday ? 3 :
           record.Thursday ? 4 :
           record.Friday ? 5 :
           record.Saturday ? 6 : null;
  }

  async processVendorRentalDays(rentalDates) {
    rentalDates.forEach(date => {
      const targetDateString = this.datePipe.transform(date.startDate, "MM/dd/yyyy");
      if (!this.vendorRentalDays.some(el => this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy") === targetDateString)) {
        this.vendorRentalDays.push({ VendorRentalDate: targetDateString });
        this.marketDayCountForResale += 1;
        if (this.marketDayCountForResale > this.DaysSellWithoutResaleNumber && this.marketDayCountForResale > this.DaysSellWithoutBusiLicNumber) {
          return;
        }
      }
    });
  
    const obj = { VendorID: this.vendorId, VendorRentalDate: new Date() };
    const res = await this.rentalService.getVendorRentalDays(obj).toPromise();
  
    if (res && res.length > 0) {
      res.forEach(el => {
        const targetDateString = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
        if (!this.vendorRentalDays.some(data => this.datePipe.transform(data.VendorRentalDate, "MM/dd/yyyy") === targetDateString)) {
          this.vendorRentalDays.push({ VendorRentalDate: targetDateString });
          this.marketDayCountForResale += 1;
        }
      });
    }
  
    await this.checkResaleAndBusinessLicense();
  }

  showModal(modal) {
    modal.classList.add('show');
    modal.style.display = 'block';
  }

  async checkResaleAndBusinessLicense() {
    if (this.ResaleNumberRequired && !this.exemptResale && !this.resaleNo && this.marketDayCountForResale > this.DaysSellWithoutResaleNumber && !this.resaleOptionSelected) {
      this.showModal(this.modalResaleNo.nativeElement);
    } else if (this.BusinessLicenseNumberRequired && this.marketDayCountForResale > this.DaysSellWithoutBusiLicNumber) {
      if (!this.businessLicenseNumber) {
        await this.promptBusinessLicense();
      } else if (!this.businessLicenseExpiryDate || new Date(this.businessLicenseExpiryDate) < new Date()) {
        await this.promptExpiredBusinessLicense();
      } else {
        this.renewWeeklyAllBooths();
      }
    } else {
      this.renewWeeklyAllBooths();
    }
  }

  async promptBusinessLicense() {
    const result = await Swal.fire({
      title: $localize`Confirm`,
      text: $localize`Business License Number is required! Do you want to continue without Business License Number?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: $localize`Yes`,
      cancelButtonText: $localize`No`,
    });
  
    if (result.value) {
      this.renewWeeklyAllBooths();
    }
  }  

  async promptExpiredBusinessLicense() {
    const result = await Swal.fire({
      title: $localize`Confirm`,
      text: $localize`Business License is expired! Do you want to continue?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: $localize`Yes`,
      cancelButtonText: $localize`No`,
    });
  
    if (result.value) {
      this.renewWeeklyAllBooths();
    }
  }

  async renewWeeklyAllBooths(){
    var nonAvailableBooths = [];
    for (const item of this.selectedWeeklyBooths) {
      if (new Date(item.RentEndPeriod.split('-')[0]) < new Date(item.CWRenewalWeek.split('-')[0])
        && !item.Drop && item.Reservation) {
        if (item.WeeklyRentalId != 0)
          var recordExist = this.selectedWeeklyBooths.findIndex(e => e.RentStartPeriod == item.RentStartPeriod && e.RentEndPeriod == item.RentEndPeriod
            && e.BoothNumber == item.BoothNumber && e.WeeklyRentalId == item.WeeklyRentalId);
        else
          var recordExist = this.selectedWeeklyBooths.findIndex(e => e.RentStartPeriod == item.RentStartPeriod && e.RentEndPeriod == item.RentEndPeriod
            && e.BoothNumber == item.BoothNumber && e.RentalMarketDay == item.RentalMarketDay);

        if (recordExist != -1) {
          var marketDay;
          var selectedDayOfWeek;
          if (this.selectedWeeklyBooths[recordExist].Sunday == true) {
            marketDay = 'Sun'
            selectedDayOfWeek = 0;
          }
          else if (this.selectedWeeklyBooths[recordExist].Monday == true) {
            marketDay = 'Mon'
            selectedDayOfWeek = 1;
          }
          else if (this.selectedWeeklyBooths[recordExist].Tuesday == true) {
            marketDay = 'Tue'
            selectedDayOfWeek = 2;
          }
          else if (this.selectedWeeklyBooths[recordExist].Wednesday == true) {
            marketDay = 'Wed'
            selectedDayOfWeek = 3;
          }
          else if (this.selectedWeeklyBooths[recordExist].Thursday == true) {
            marketDay = 'Thu'
            selectedDayOfWeek = 4;
          }
          else if (this.selectedWeeklyBooths[recordExist].Friday == true) {
            marketDay = 'Fri'
            selectedDayOfWeek = 5;
          }
          else if (this.selectedWeeklyBooths[recordExist].Saturday == true) {
            marketDay = 'Sat'
            selectedDayOfWeek = 6;
          }

          var selectedRentalDates = [];
          const startDate = new Date(this.selectedWeeklyBooths[recordExist].RentStartPeriod.split('-')[0]);
          const endDate = new Date(item.CWRenewalWeek.split("-")[1]);
          while (new Date(startDate) <= new Date(endDate)) {
            if (startDate.getDay() === selectedDayOfWeek) {
              selectedRentalDates.push(new Date(startDate));
            }
            startDate.setDate(startDate.getDate() + 1);
          }

          var currentRentedEndDate = new Date(this.selectedWeeklyBooths[recordExist].RentEndPeriod.split("-")[1]);
          const filteredRentalDates = selectedRentalDates.filter(rentalDate => rentalDate > currentRentedEndDate);
          var obj1 = {
            RentalDates: filteredRentalDates,
            BoothNumber: this.selectedWeeklyBooths[recordExist].BoothNumber
          }

          const res = await this.rentalService.checkRentalExits(this.marketId,obj1).toPromise();
          if (!res.Success) {
            var RentAmount = this.selectedWeeklyBooths[recordExist].Rent;
            var SalesTax = this.selectedWeeklyBooths[recordExist].SalesTax;
            var CityTax = this.selectedWeeklyBooths[recordExist].CityTax;
            var JanRentAmount = this.selectedWeeklyBooths[recordExist].January ?? 0;
            var FebRentAmount = this.selectedWeeklyBooths[recordExist].February ?? 0;
            var MarRentAmount = this.selectedWeeklyBooths[recordExist].March ?? 0;
            var AprRentAmount = this.selectedWeeklyBooths[recordExist].April ?? 0;
            var MayRentAmount = this.selectedWeeklyBooths[recordExist].May ?? 0;
            var JunRentAmount = this.selectedWeeklyBooths[recordExist].June ?? 0;
            var JulRentAmount = this.selectedWeeklyBooths[recordExist].July ?? 0;
            var AugRentAmount = this.selectedWeeklyBooths[recordExist].August ?? 0;
            var SepRentAmount = this.selectedWeeklyBooths[recordExist].September ?? 0;
            var OctRentAmount = this.selectedWeeklyBooths[recordExist].October ?? 0;
            var NovRentAmount = this.selectedWeeklyBooths[recordExist].November ?? 0;
            var DecRentAmount = this.selectedWeeklyBooths[recordExist].December ?? 0;

            this.totalRentalCharge -= RentAmount.toString().includes('(') && RentAmount.toString().includes(')') ? -1 * parseFloat(RentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(RentAmount.toString().replace(/[^0-9.-]/g, ''));
            this.totalSalesTax -= SalesTax.toString().includes('(') && SalesTax.toString().includes(')') ? -1 * parseFloat(SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(SalesTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalSecondLeveltax -= CityTax.toString().includes('(') && CityTax.toString().includes(')') ? -1 * parseFloat(CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(CityTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
            this.bookingDetailsForm.patchValue({
              rentalCharge: this.customCurrency.transform(this.totalRentalCharge.toString()),
              totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
              totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
              totalCharge: this.customCurrency.transform(this.totalCharge.toString())
            });

            var extendRentalStartPeriod;
            if (!this.selectedWeeklyBooths[recordExist].NewBooth && this.selectedWeeklyBooths[recordExist].Status != "R") {
              extendRentalStartPeriod = new Date(this.selectedWeeklyBooths[recordExist].RentalSavedEndDate.split("-")[1])
              extendRentalStartPeriod.setDate(extendRentalStartPeriod.getDate() + 1);
            }
            else {
              extendRentalStartPeriod = new Date(this.selectedWeeklyBooths[recordExist].RentStartPeriod.split("-")[0]);
            }

            var obj = {
              BoothTypeId: this.selectedWeeklyBooths[recordExist].BoothTypeId,
              VendorId: this.vendorId,
              RentalStartPeriod: new Date(extendRentalStartPeriod),
              RentalMarketDay: marketDay,
              RentalEndPeriod: new Date(item.CWRenewalWeek.split("-")[1]),
              JanRent: JanRentAmount.toString().includes('(') && JanRentAmount.toString().includes(')') ? -1 * parseFloat(JanRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(JanRentAmount.toString().replace(/[^0-9.-]/g, '')),
              FebRent: FebRentAmount.toString().includes('(') && FebRentAmount.toString().includes(')') ? -1 * parseFloat(FebRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(FebRentAmount.toString().replace(/[^0-9.-]/g, '')),
              MarRent: MarRentAmount.toString().includes('(') && MarRentAmount.toString().includes(')') ? -1 * parseFloat(MarRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(MarRentAmount.toString().replace(/[^0-9.-]/g, '')),
              AprRent: AprRentAmount.toString().includes('(') && AprRentAmount.toString().includes(')') ? -1 * parseFloat(AprRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(AprRentAmount.toString().replace(/[^0-9.-]/g, '')),
              MayRent: MayRentAmount.toString().includes('(') && MayRentAmount.toString().includes(')') ? -1 * parseFloat(MayRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(MayRentAmount.toString().replace(/[^0-9.-]/g, '')),
              JunRent: JunRentAmount.toString().includes('(') && JunRentAmount.toString().includes(')') ? -1 * parseFloat(JunRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(JunRentAmount.toString().replace(/[^0-9.-]/g, '')),
              JulRent: JulRentAmount.toString().includes('(') && JulRentAmount.toString().includes(')') ? -1 * parseFloat(JulRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(JulRentAmount.toString().replace(/[^0-9.-]/g, '')),
              AugRent: AugRentAmount.toString().includes('(') && AugRentAmount.toString().includes(')') ? -1 * parseFloat(AugRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(AugRentAmount.toString().replace(/[^0-9.-]/g, '')),
              SepRent: SepRentAmount.toString().includes('(') && SepRentAmount.toString().includes(')') ? -1 * parseFloat(SepRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(SepRentAmount.toString().replace(/[^0-9.-]/g, '')),
              OctRent: OctRentAmount.toString().includes('(') && OctRentAmount.toString().includes(')') ? -1 * parseFloat(OctRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(OctRentAmount.toString().replace(/[^0-9.-]/g, '')),
              NovRent: NovRentAmount.toString().includes('(') && NovRentAmount.toString().includes(')') ? -1 * parseFloat(NovRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(NovRentAmount.toString().replace(/[^0-9.-]/g, '')),
              DecRent: DecRentAmount.toString().includes('(') && DecRentAmount.toString().includes(')') ? -1 * parseFloat(DecRentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(DecRentAmount.toString().replace(/[^0-9.-]/g, '')),
            }
            const res = await this.rentalService.getBoothRentalWeeklyCharges(this.marketId, obj).toPromise();         
              this.selectedWeeklyBooths[recordExist].RentEndPeriod = item.CWRenewalWeek;
              this.selectedWeeklyBooths[recordExist].January = this.customCurrency.transform(res.JanRent);
              this.selectedWeeklyBooths[recordExist].February = this.customCurrency.transform(res.FebRent);
              this.selectedWeeklyBooths[recordExist].March = this.customCurrency.transform(res.MarRent);
              this.selectedWeeklyBooths[recordExist].April = this.customCurrency.transform(res.AprRent);
              this.selectedWeeklyBooths[recordExist].May = this.customCurrency.transform(res.MayRent);
              this.selectedWeeklyBooths[recordExist].June = this.customCurrency.transform(res.JunRent);
              this.selectedWeeklyBooths[recordExist].July = this.customCurrency.transform(res.JulRent);
              this.selectedWeeklyBooths[recordExist].August = this.customCurrency.transform(res.AugRent);
              this.selectedWeeklyBooths[recordExist].September = this.customCurrency.transform(res.SepRent);
              this.selectedWeeklyBooths[recordExist].October = this.customCurrency.transform(res.OctRent);
              this.selectedWeeklyBooths[recordExist].November = this.customCurrency.transform(res.NovRent);
              this.selectedWeeklyBooths[recordExist].December = this.customCurrency.transform(res.DecRent);
              this.selectedWeeklyBooths[recordExist].Rent = this.customCurrency.transform(res.Rent);
              this.selectedWeeklyBooths[recordExist].SalesTax = this.customCurrency.transform(res.SalesTax);
              this.selectedWeeklyBooths[recordExist].CityTax = this.customCurrency.transform(res.CityTax);
              this.selectedWeeklyBooths[recordExist].JanuaryMonthPresent = res.JanuaryMonthPresent;
              this.selectedWeeklyBooths[recordExist].FebruaryMonthPresent = res.FebruaryMonthPresent;
              this.selectedWeeklyBooths[recordExist].MarchMonthPresent = res.MarchMonthPresent;
              this.selectedWeeklyBooths[recordExist].AprilMonthPresent = res.AprilMonthPresent;
              this.selectedWeeklyBooths[recordExist].MayMonthPresent = res.MayMonthPresent;
              this.selectedWeeklyBooths[recordExist].JuneMonthPresent = res.JuneMonthPresent;
              this.selectedWeeklyBooths[recordExist].JulyMonthPresent = res.JulyMonthPresent;
              this.selectedWeeklyBooths[recordExist].AugustMonthPresent = res.AugustMonthPresent;
              this.selectedWeeklyBooths[recordExist].SeptemberMonthPresent = res.SeptemberMonthPresent;
              this.selectedWeeklyBooths[recordExist].OctoberMonthPresent = res.OctoberMonthPresent;
              this.selectedWeeklyBooths[recordExist].NovemberMonthPresent = res.NovemberMonthPresent;
              this.selectedWeeklyBooths[recordExist].DecemberMonthPresent = res.DecemberMonthPresent;
              this.selectedWeeklyBooths[recordExist].DisableJanMonth = res.JanRent || res.JanRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].DisableFebMonth = res.FebRent || res.FebRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].DisableMarMonth = res.MarRent || res.MarRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].DisableAprMonth = res.AprRent || res.AprRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].DisableMayMonth = res.MayRent || res.MayRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].DisableJunMonth = res.JunRent || res.JunRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].DisableJulMonth = res.JulRent || res.JulRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].DisableAugMonth = res.AugRent || res.AugRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].DisableSepMonth = res.SepRent || res.SepRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].DisableOctMonth = res.OctRent || res.OctRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].DisableNovMonth = res.NovRent || res.NovRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].DisableDecMonth = res.DecRent || res.DecRent == 0? false : true;
              this.selectedWeeklyBooths[recordExist].ExtendRental = true;
              this.selectedWeeklyBooths[recordExist].TaxModel = res.TaxModel;

              this.totalRentalCharge += res.Rent.toString().includes('(') && res.Rent.toString().includes(')') ? -1 * parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, ''));
              this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
              this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
              this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
              this.bookingDetailsForm.patchValue({
                rentalCharge: this.customCurrency.transform(this.totalRentalCharge.toString()),
                totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
                totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
                totalCharge: this.customCurrency.transform(this.totalCharge.toString())
              });
          }
          else {
            nonAvailableBooths.push(this.selectedWeeklyBooths[recordExist].BoothNumber);
          } 
        }
      }
    }

    if (nonAvailableBooths.length > 0) {
      const nonAvailableBoothsString = nonAvailableBooths.join(', ');
      Swal.fire({
        title: $localize`Warning`,
        text: $localize`Cannot be renewed further as the booth number ` + nonAvailableBoothsString + ` is not available`,
        icon: "info",
        confirmButtonText: $localize`Ok`,
      });
    }
  }

  getWeeklyOtherRentals(){
    this.loaderIcon = Promise.resolve(true);
    this.selectedOtherItems = [];
    const weekStartDate = this.weeklyMarketDays[0].RentalStartDays.split('-')[0];
    const weekEndDate =   this.weeklyMarketDays[0].RentalStartDays.split('-')[1];    
    const sub = this.rentalService.getWeeklyOtherRentals(this.marketId,this.vendorId).subscribe((res: any[]) => {
      this.allrentedOtherItems = res;
      if (res) {
        res.forEach((el) => {
          var RentalWeekRecord = this.weeklyMarketDays.filter(date => this.datePipe.transform(new Date(date.RentalStartDays.split('-')[0]), 'MM/dd/yyyy') <= this.datePipe.transform(new Date(el.RentalWeekDay), 'MM/dd/yyyy')
            && this.datePipe.transform(new Date(date.RentalStartDays.split('-')[1]), 'MM/dd/yyyy') >= this.datePipe.transform(new Date(el.RentalWeekDay), 'MM/dd/yyyy'))
          if (RentalWeekRecord.length > 0)
            var RentalWeek = RentalWeekRecord[0].RentalStartDays;
          const rentEndPeriodIndex = this.weeklyMarketDays.findIndex(e=>e.RentalEndDays == RentalWeek);
          const renewalStartWeek = this.weeklyMarketDays[rentEndPeriodIndex +1].RentalEndDays;

          if (new Date(el.RentalWeekDay) >= new Date(weekStartDate)) {
            const index = this.selectedOtherItems.findIndex(item => item.Id === el.OtherItemId && item.RentalWeek == el.RentalWeek && item.Reference == el.Reference && item.BoothNo == el.BoothNumber && item.Recurring == el.Recurring);
            if (index !== -1) {
              this.selectedOtherItems[index].Quantity += el.Quantity;
              this.selectedOtherItems[index].WeeklyOtherRentalIds += ',' + el.OtherRentalId
            }
            const ob = {
              Id: el.OtherItemId,
              WeekOtherRentalId: el.OtherRentalId,
              OtherItem: el.OtherItemName,
              OtherItemCharge: this.customCurrency.transform('0'),
              Quantity: el.Quantity,
              OtherItemtotal: this.customCurrency.transform('0'),
              RentalWeek: RentalWeek,
              SalesTax: this.customCurrency.transform('0'),
              CityTax: this.customCurrency.transform('0'),
              CurrentRentalWeek: RentalWeek,
              CurrentOtherItemtotal: this.customCurrency.transform(el.OtherCharge.toString()),
              CurrentSalesTax: this.customCurrency.transform(el.SalesTax.toString()),
              CurrentSecLevelTax: this.customCurrency.transform(el.SecLevelTax.toString()),
              BoothNo: el.BoothNumber,
              Recurring: el.Recurring,
              NewItem: false,
              Reference: el.Reference,
              WeeklyOtherRentalIds: el.OtherRentalId.toString(),
              Status: "B",
              RentEndPeriodIndex: rentEndPeriodIndex,
              RenewalStartWeek: renewalStartWeek,
              CWRenewalWeek: renewalStartWeek,
              BookingCanExtend: true
            }
            this.selectedOtherItems.push(ob);
          }
          else {
            var obj = {
              MarketId: this.marketId,
              OtherItemId: el.OtherItemId,
              VendorId: this.vendorId,
              Quantity: el.Quantity,
              OtherItemCharge: el.OtherCharge
            }

            this.rentalService.getOtherItemsRentalCharges(obj).subscribe((res: any) => {
              const ob = {
                Id: el.OtherItemId,
                MonthOtherRentalId: el.OtherRentalId,
                OtherItem: el.OtherItemName,
                OtherItemCharge: this.customCurrency.transform(el.OtherCharge),
                Quantity: el.Quantity,
                OtherItemtotal: this.customCurrency.transform((el.OtherCharge * el.Quantity).toString()),
                RentalWeek: this.weeklyMarketDays[0].RentalStartDays,
                SalesTax: this.customCurrency.transform(res.SalesTax.toString()),
                CityTax: this.customCurrency.transform(res.CityTax.toString()),
                BoothNo: el.BoothNumber ? el.BoothNumber : "none",
                Recurring: el.Recurring,
                Reference: el.Reference,
                NewItem: false,
                MonthlyRentalIds: '',
                Status: "R",
                RentEndPeriodIndex:rentEndPeriodIndex
              }
              this.selectedOtherItems.push(ob);
              const OtherItemtotal = el.OtherCharge * el.Quantity;
              this.totalOtherRentalCharge += OtherItemtotal;
              this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
              this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
              this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
              this.bookingDetailsForm.patchValue({
                otherCharge: this.customCurrency.transform(this.totalOtherRentalCharge.toString()),
                totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
                totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
                totalCharge: this.customCurrency.transform(this.totalCharge.toString())
              });
            });
          }
        });
        this.renewWeeklyOtherCharges();
        this.loaderIcon = Promise.resolve(false);
      }
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  renewWeeklyOtherCharges() {
    if (this.selectedOtherItems.length > 0) {
      this.proceedRenewingWeeklyOtherCharges();
    }
  }

  updateWeeklyRentalRecord(record) {
    if (record.Reservation && this.allowWeeklyRentalRenewal) {
      const rentEndPeriodIndex = this.weeklyMarketDays.findIndex(e => e.RentalEndDays == record.RentEndPeriod);
      const renewalStartWeek = this.weeklyMarketDays[rentEndPeriodIndex + 1].RentalEndDays;
      record.BookingCanExtend = true;
      record.RenewalStartWeek = renewalStartWeek
      record.CWRenewalWeek = renewalStartWeek;
      record.RentEndPeriodIndex = rentEndPeriodIndex
      this.extendWeeklyRental(record);
    }
    else {
      record.BookingCanExtend = false;
      record.RenewalStartWeek = "This booking cannot be extended further!";
      record.CWRenewalWeek = record.CurrentRentEndPeriod;
      record.RentEndPeriod = record.CurrentRentEndPeriod;
      this.totalRentalCharge -= record.Rent.toString().includes('(') && record.Rent.toString().includes(')') ? -1 * parseFloat(record.Rent.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.Rent.toString().replace(/[^0-9.-]/g, ''));
      this.totalSalesTax -= record.SalesTax.toString().includes('(') && record.SalesTax.toString().includes(')') ? -1 * parseFloat(record.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.SalesTax.toString().replace(/[^0-9.-]/g, ''));
      this.totalSecondLeveltax -= record.CityTax.toString().includes('(') && record.CityTax.toString().includes(')') ? -1 * parseFloat(record.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.CityTax.toString().replace(/[^0-9.-]/g, ''));
      this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
      this.bookingDetailsForm.patchValue({
        rentalCharge: this.customCurrency.transform(this.totalRentalCharge.toString()),
        totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
        totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
        totalCharge: this.customCurrency.transform(this.totalCharge.toString())
      });
      record.Rent = this.customCurrency.transform("0");
    }
  }

  extendWeeklyRental(extendRentalRecord) {
    if (extendRentalRecord) {
      var recordExist = this.selectedWeeklyBooths.findIndex(e => e.RentStartPeriod == extendRentalRecord.RentStartPeriod && e.RentEndPeriod == extendRentalRecord.RentEndPeriod
        && e.BoothNumber == extendRentalRecord.BoothNumber)
      if (recordExist != -1) {
        const rentalDates = [];
        const currentMonthRentalDates = [];
        var rentalstartDate;
        var marketDays = 0;
        var marketDay;
        
        if (this.selectedWeeklyBooths[recordExist].Sunday == true) {
          marketDay = 'Sun'
        }
        else if (this.selectedWeeklyBooths[recordExist].Monday == true) {
          marketDay = 'Mon'
        }
        else if (this.selectedWeeklyBooths[recordExist].Tuesday == true) {
          marketDay = 'Tue'
        }
        else if (this.selectedWeeklyBooths[recordExist].Wednesday == true) {
          marketDay = 'Wed'
        }
        else if (this.selectedWeeklyBooths[recordExist].Thursday == true) {
          marketDay = 'Thu'
        }
        else if (this.selectedWeeklyBooths[recordExist].Friday == true) {
          marketDay = 'Fri'
        }
        else if (this.selectedWeeklyBooths[recordExist].Saturday == true) {
          marketDay = 'Sat'
        }

        var RentAmount = this.selectedWeeklyBooths[recordExist].Rent;
        var SalesTax = this.selectedWeeklyBooths[recordExist].SalesTax;
        var CityTax = this.selectedWeeklyBooths[recordExist].CityTax;

        this.totalRentalCharge -= RentAmount.toString().includes('(') && RentAmount.toString().includes(')') ? -1 * parseFloat(RentAmount.toString().replace(/[^0-9.-]/g, '')) : parseFloat(RentAmount.toString().replace(/[^0-9.-]/g, ''));
        this.totalSalesTax -= SalesTax.toString().includes('(') && SalesTax.toString().includes(')') ? -1 * parseFloat(SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(SalesTax.toString().replace(/[^0-9.-]/g, ''));
        this.totalSecondLeveltax -= CityTax.toString().includes('(') && CityTax.toString().includes(')') ? -1 * parseFloat(CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(CityTax.toString().replace(/[^0-9.-]/g, ''));
         this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
        this.bookingDetailsForm.patchValue({
          rentalCharge: this.customCurrency.transform(this.totalRentalCharge.toString()),
          totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
          totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
          totalCharge: this.customCurrency.transform(this.totalCharge.toString())
        });

        var extendRentalStartPeriod;
        if (!this.selectedWeeklyBooths[recordExist].NewBooth  && this.selectedWeeklyBooths[recordExist].Status != "R") {
          extendRentalStartPeriod = new Date(this.selectedWeeklyBooths[recordExist].RentalSavedEndDate.split("-")[1]);
          extendRentalStartPeriod.setDate(extendRentalStartPeriod.getDate() + 1);
        }
        else {
          extendRentalStartPeriod = new Date(this.selectedWeeklyBooths[recordExist].RentStartPeriod.split("-")[0]);
        }

        var obj = {
          BoothTypeId: this.selectedWeeklyBooths[recordExist].BoothTypeId,
          VendorId: this.vendorId,
          RentalMarketDay: marketDay,
          RentalStartPeriod: new Date(extendRentalStartPeriod),
          RentalEndPeriod: new Date(extendRentalRecord.CWRenewalWeek.split("-")[1]),
          PerDiemApplicable: false
        }
        this.rentalService.getBoothRentalWeeklyCharges(this.marketId,obj).subscribe((res: any) => {
          this.selectedWeeklyBooths[recordExist].RentEndPeriod =extendRentalRecord.CWRenewalWeek;
          this.selectedWeeklyBooths[recordExist].January = this.customCurrency.transform(res.JanRent);
          this.selectedWeeklyBooths[recordExist].February = this.customCurrency.transform(res.FebRent);
          this.selectedWeeklyBooths[recordExist].March = this.customCurrency.transform(res.MarRent);
          this.selectedWeeklyBooths[recordExist].April = this.customCurrency.transform(res.AprRent);
          this.selectedWeeklyBooths[recordExist].May = this.customCurrency.transform(res.MayRent);
          this.selectedWeeklyBooths[recordExist].June = this.customCurrency.transform(res.JunRent);
          this.selectedWeeklyBooths[recordExist].July = this.customCurrency.transform(res.JulRent);
          this.selectedWeeklyBooths[recordExist].August = this.customCurrency.transform(res.AugRent);
          this.selectedWeeklyBooths[recordExist].September = this.customCurrency.transform(res.SepRent);
          this.selectedWeeklyBooths[recordExist].October = this.customCurrency.transform(res.OctRent);
          this.selectedWeeklyBooths[recordExist].November = this.customCurrency.transform(res.NovRent);
          this.selectedWeeklyBooths[recordExist].December = this.customCurrency.transform(res.DecRent);
          this.selectedWeeklyBooths[recordExist].Rent = this.customCurrency.transform(res.Rent);
          this.selectedWeeklyBooths[recordExist].SalesTax = this.customCurrency.transform(res.SalesTax);
          this.selectedWeeklyBooths[recordExist].CityTax = this.customCurrency.transform(res.CityTax);
          this.selectedWeeklyBooths[recordExist].JanuaryMonthPresent = res.JanuaryMonthPresent;
          this.selectedWeeklyBooths[recordExist].FebruaryMonthPresent = res.FebruaryMonthPresent;
          this.selectedWeeklyBooths[recordExist].MarchMonthPresent = res.MarchMonthPresent;
          this.selectedWeeklyBooths[recordExist].AprilMonthPresent = res.AprilMonthPresent;
          this.selectedWeeklyBooths[recordExist].MayMonthPresent = res.MayMonthPresent;
          this.selectedWeeklyBooths[recordExist].JuneMonthPresent = res.JuneMonthPresent;
          this.selectedWeeklyBooths[recordExist].JulyMonthPresent = res.JulyMonthPresent;
          this.selectedWeeklyBooths[recordExist].AugustMonthPresent = res.AugustMonthPresent;
          this.selectedWeeklyBooths[recordExist].SeptemberMonthPresent = res.SeptemberMonthPresent;
          this.selectedWeeklyBooths[recordExist].OctoberMonthPresent = res.OctoberMonthPresent;
          this.selectedWeeklyBooths[recordExist].NovemberMonthPresent = res.NovemberMonthPresent;
          this.selectedWeeklyBooths[recordExist].DecemberMonthPresent = res.DecemberMonthPresent;
          this.selectedWeeklyBooths[recordExist].DisableJanMonth = res.JanRent || res.JanRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].DisableFebMonth = res.FebRent || res.FebRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].DisableMarMonth = res.MarRent || res.MarRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].DisableAprMonth = res.AprRent || res.AprRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].DisableMayMonth = res.MayRent || res.MayRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].DisableJunMonth = res.JunRent || res.JunRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].DisableJulMonth = res.JulRent || res.JulRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].DisableAugMonth = res.AugRent || res.AugRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].DisableSepMonth = res.SepRent || res.SepRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].DisableOctMonth = res.OctRent || res.OctRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].DisableNovMonth = res.NovRent || res.NovRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].DisableDecMonth = res.DecRent || res.DecRent == 0 ? false : true;
          this.selectedWeeklyBooths[recordExist].ExtendRental = true;

          this.totalRentalCharge += res.Rent.toString().includes('(') && res.Rent.toString().includes(')') ? -1 * parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, ''));
          this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
          this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
          this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
          this.bookingDetailsForm.patchValue({
            rentalCharge: this.customCurrency.transform(this.totalRentalCharge.toString()),
            totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
            totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
            totalCharge: this.customCurrency.transform(this.totalCharge.toString())
          });
        });      
      }
    }
  }

  extendWeeklyOtherRental(item) {
    const cuurentWeek = new Date(item.RentalWeek.split('-')[1]);
    if (cuurentWeek < new Date(item.CWRenewalWeek.split('-')[1]) && item.Recurring && this.allowWeeklyRentalRenewal) {
      var nextDate = this.addDays(new Date(item.RentalWeek.split('-')[0]), 7);
      var rentalWeekDate = this.weeklyMarketDays.filter(date => this.datePipe.transform(new Date(date.RentalEndDays.split('-')[0]), 'MM/dd/yyyy') <= this.datePipe.transform(new Date(nextDate), 'MM/dd/yyyy')
        && this.datePipe.transform(new Date(date.RentalEndDays.split('-')[1]), 'MM/dd/yyyy') >= this.datePipe.transform(new Date(nextDate), 'MM/dd/yyyy'))
      if (rentalWeekDate.length > 0)
        var rentalStartWeek = rentalWeekDate[0].RentalStartDays;

      var otherItemCharge = this.otherItemList.filter(e => e.Id == item.Id)[0].OtherItemCharge;
      if (!this.masterRentChargeforResOtherItems && this.allrentedOtherItems.length > 0) {
        otherItemCharge = this.customCurrency.transform(this.allrentedOtherItems.filter(e => e.OtherItemId == item.Id)[0].OtherCharge);
      }
      var obj = {
        MarketId: this.marketId,
        OtherItemId: item.Id,
        VendorId: this.vendorId,
        Quantity: item.Quantity,
        OtherItemCharge: otherItemCharge.includes('(') && otherItemCharge.includes(')') ? (-1) * parseFloat(otherItemCharge.replace(/[^0-9.-]/g, '')) : parseFloat(otherItemCharge.replace(/[^0-9.-]/g, ''))
      }

      this.rentalService.getOtherItemsRentalCharges(obj).subscribe((res: any) => {
        const isNegative = otherItemCharge.includes('(') && otherItemCharge.includes(')');
        const totalValue = parseFloat(otherItemCharge.replace(/[^0-9.-]/g, ''));
        const finalCharge = isNegative ? -totalValue : totalValue;
        item.OtherItemtotal =this.customCurrency.transform((parseFloat(item.OtherItemtotal.replace(/[^0-9.-]/g, '')) + (finalCharge * item.Quantity)).toString());
        item.Quantity += item.Quantity;
        item.OtherItemCharge = otherItemCharge;
        item.MonthlyRentalIds += ',' + item.Id
        item.CWRenewalWeek = rentalStartWeek;

        this.totalOtherRentalCharge += item.OtherItemtotal.toString().includes('(') && item.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(item.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(item.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
        this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
        this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
        this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
        this.bookingDetailsForm.patchValue({
          otherCharge: this.customCurrency.transform(this.totalOtherRentalCharge.toString()),
          totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
          totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
          totalCharge: this.customCurrency.transform(this.totalCharge.toString())
        });
      });
    }
    else {
      item.BookingCanExtend = false;
      item.RenewalStartMonth = "This booking cannot be extended further!";
      item.CMRenewalMonth = item.RentalMonth;
    }
  }

  updateWeeklyOtherItemRecord(record){
    if (record.Recurring && this.allowOtherItemRenewal) {
      const rentEndPeriodIndex = this.weeklyMarketDays.findIndex(e => e.RentalEndDays == record.RentalWeek);
      const renewalStartWeek = this.weeklyMarketDays[rentEndPeriodIndex + 1].RentalEndDays;
      record.BookingCanExtend = true;
      record.RenewalStartWeek = renewalStartWeek
      record.CWRenewalWeek = renewalStartWeek;
      record.RentEndPeriodIndex = rentEndPeriodIndex
      this.extendWeeklyOtherRental(record);
    }
    else {
      record.BookingCanExtend = false;
      record.RenewalStartWeek = "This booking cannot be extended further!";
      record.CWRenewalWeek = record.RentalWeek;
      this.totalOtherRentalCharge -= record.OtherItemtotal.toString().includes('(') && record.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(record.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
      this.totalSalesTax -= record.SalesTax.toString().includes('(') && record.SalesTax.toString().includes(')') ? -1 * parseFloat(record.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.SalesTax.toString().replace(/[^0-9.-]/g, ''));
      this.totalSecondLeveltax -= record.CityTax.toString().includes('(') && record.CityTax.toString().includes(')') ? -1 * parseFloat(record.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(record.CityTax.toString().replace(/[^0-9.-]/g, ''));
      this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
      this.bookingDetailsForm.patchValue({
        otherCharge: this.customCurrency.transform(this.totalOtherRentalCharge.toString()),
        totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
        totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
        totalCharge: this.customCurrency.transform(this.totalCharge.toString())
      });
      record.Rent = this.customCurrency.transform("0");
    }
  }

  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  
  proceedRenewingWeeklyOtherCharges(){
    for(const item of this.selectedOtherItems){
      if (new Date(item.RentalWeek.split('-')[0]) < new Date(item.CWRenewalWeek.split('-')[0])
        && item.Recurring && this.allowOtherItemRenewal) {
        var nextDate = this.addDays(new Date(item.RentalWeek.split('-')[0]), 7);
        var rentalWeekDate = this.weeklyMarketDays.filter(date => this.datePipe.transform(new Date(date.RentalEndDays.split('-')[0]), 'MM/dd/yyyy') <= this.datePipe.transform(new Date(nextDate), 'MM/dd/yyyy')
          && this.datePipe.transform(new Date(date.RentalEndDays.split('-')[1]), 'MM/dd/yyyy') >= this.datePipe.transform(new Date(nextDate), 'MM/dd/yyyy'))
        if (rentalWeekDate.length > 0)
          var rentalStartWeek = rentalWeekDate[0].RentalStartDays;

        var recordExist = this.selectedOtherItems.findIndex(e => e.RentalWeek == rentalStartWeek && e.Id == item.Id && e.Reference == item.Reference && e.BoothNumber == item.item.BoothNo)
        var otherItemCharge = this.otherItemList.filter(e => e.Id == item.Id)[0].OtherItemCharge;
        if (!this.masterRentChargeforResOtherItems && this.allrentedOtherItems.length > 0) {
          otherItemCharge = this.customCurrency.transform(this.allrentedOtherItems.filter(e => e.OtherItemId == item.Id)[0].OtherCharge);
        }
        var obj = {
          MarketId: this.marketId,
          OtherItemId: item.Id,
          VendorId: this.vendorId,
          Quantity: item.Quantity,
          OtherItemCharge: otherItemCharge.includes('(') && otherItemCharge.includes(')') ? (-1) * parseFloat(otherItemCharge.replace(/[^0-9.-]/g, '')) : parseFloat(otherItemCharge.replace(/[^0-9.-]/g, ''))
        }
        this.rentalService.getOtherItemsRentalCharges(obj).subscribe((res: any) => {
          if (recordExist == -1) {
            const isNegative = otherItemCharge.includes('(') && otherItemCharge.includes(')');
            const totalValue = parseFloat(otherItemCharge.replace(/[^0-9.-]/g, ''));
            const finalCharge = isNegative ? -totalValue : totalValue;
            item.OtherItemtotal = this.customCurrency.transform((finalCharge * item.Quantity).toString());
            const ob = {
              Id: item.Id,
              WeekOtherRentalId: 0,
              OtherItem: item.OtherItem,
              OtherItemCharge: otherItemCharge,
              Quantity: item.Quantity,
              OtherItemtotal: item.OtherItemtotal,
              RentalWeek: rentalStartWeek,
              SalesTax: this.customCurrency.transform(res.SalesTax.toString()),
              CityTax: this.customCurrency.transform(res.CityTax.toString()),
              BoothNo: item.othChgBooths ?? "none",
              Recurring: item.Recurring,
              NewItem: true,
              Reference: item.Reference,
              WeeklyOtherRentalIds: ''
            }
            this.selectedOtherItems.push(JSON.parse(JSON.stringify(ob)));
          }
          this.totalOtherRentalCharge += item.OtherItemtotal.toString().includes('(') && item.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(item.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(item.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
          this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
          this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
          this.totalCharge = this.priorBalance + this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
          this.bookingDetailsForm.patchValue({
            totalOtherRentalCharge: this.customCurrency.transform(this.totalOtherRentalCharge.toString()),
            totalSalesTax: this.customCurrency.transform(this.totalSalesTax.toString()),
            totalSecondLeveltax: this.customCurrency.transform(this.totalSecondLeveltax.toString()),
            totalCharge: this.customCurrency.transform(this.totalCharge.toString())
          });
        });
      }
      else{
        item.BookingCanExtend = false;
        item.RenewalStartWeek = "This booking cannot be extended further!";
        item.CWRenewalWeek = item.RentalWeek;
      }
    }
  }
    
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}

