<div class="container-fluid">
    <div class="row">
        <div class="col-lg-2 col-xl-2 alignMenu shadow text-left pt-2 px-1">
            <app-rental-menu></app-rental-menu>
        </div>
        <div class="col-lg-10 col-xl-10 bgMainSection text-left">
            <div class="row">
                <div class="col-5 py-2 bgHead">
                    <div class="container mainContainer">
                        <label class="txtHead">Dashboard</label>
                    </div>
                </div>
                <div class="col-7 text-right py-2 bgHead">
                    <div class="container mainContainer">
                        <label class="txtHead">{{marketName}}</label>
                    </div>
                </div>
            </div>
            <div class="container mainContainer">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1 mt-2">
                        <img src="./assets/images/availableBooths.png" class="img-fluid">
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 mt-2">
                        <p class="mt-2">
                           {{message1}}
                        </p> <br />
                        <p>
                            {{message2}}
                        </p> <br />
                        <p>
                            {{message3}}
                        </p> <br />
                        <p>
                            {{message4}}
                        </p> <br />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-4">
                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#carouselExampleIndicators" *ngIf="imageURL1" data-slide-to="0" [class.active]="getFirstVisibleIndex() === 0"></li>
                              <li data-target="#carouselExampleIndicators" *ngIf="imageURL2" data-slide-to="1" [class.active]="getFirstVisibleIndex() === 1"></li>
                              <li data-target="#carouselExampleIndicators" *ngIf="imageURL3" data-slide-to="2" [class.active]="getFirstVisibleIndex() === 2"></li>
                            </ol>
                            <div class="carousel-inner" style="background: black;">
                              <div class="carousel-item" *ngIf="imageURL1" [class.active]="getFirstVisibleIndex() === 0">
                                <img [src]="imageURL1" class="img-fluid imgCarousel" style="width: 100%;">
                              </div>
                              <div class="carousel-item" *ngIf="imageURL2" [class.active]="getFirstVisibleIndex() === 1">
                                <img [src]="imageURL2" class="img-fluid imgCarousel" style="width: 100%;">
                              </div>
                              <div class="carousel-item"  *ngIf="imageURL3" [class.active]="getFirstVisibleIndex() === 2">
                                <img [src]="imageURL3" class="img-fluid imgCarousel" style="width: 100%;">
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1 mt-3 mb-3">
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 mt-3 mb-3">
                        <button [routerLink]="['/map', marketId]" class="btn btn-primary">View Market Map</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>