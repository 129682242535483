<app-loader *ngIf="loaderIcon | async"></app-loader>
<div class="container-fluid">
    <form [formGroup]="rentalForm">
        <div class="row">
            <div class="col-lg-2 col-xl-2 alignMenu shadow text-left pt-2">
                <app-rental-menu></app-rental-menu>
            </div>
            <div class="col-lg-10 col-xl-10 bgMainSection bgBlue text-left">
                <div class="container mainContainer">
                    <div class="row">
                        <div class="col-5 text-left mt-4">
                            <label class="mainHeading mb-0">{{pageName}}</label>
                        </div>
                        <div class="col-7 text-right pl-0 mt-4">
                            <label class="mainHeading mb-0">{{marketName}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                            <button #btnRentOnline class="btn btnMain mr-3 mt-2">Rent Online</button>
                            <button #ContactInfo class="btn btnMain mr-3 mt-2">Current Contact
                                Information</button>
                            <button #btnLoginInfo class="btn btnMain mr-3 mt-2">Online Login
                                Info</button>
                            <button #btnPayment class="btn btnMain mt-2">Payment</button>
                        </div>
                    </div>
                    <div #divRentOnline class="bgWghite shadow pt-2 mt-2"
                        [ngStyle]="{ display: showrentOnline ? 'block' : 'none' }">
                        <div class="container">
                            <div class="row mt-1 pl-4">
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsDaily">
                                    <input type="radio" id="rdDaily" class="form-check-input" value="1" formControlName="rentalTypes" >
                                    <label for="rdDaily" class="form-check-label">Daily</label>
                                </div>
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsWeekly">
                                    <input type="radio" id="rdWeekly" class="form-check-input" value="3" formControlName="rentalTypes" >
                                    <label for="rdWeekly" class="form-check-label">Weekly</label>
                                </div>
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsMonthly">
                                    <input type="radio" id="rdMonthly" class="form-check-input" value="2" formControlName="rentalTypes" >
                                    <label for="rdMonthly" class="form-check-label">Monthly</label>
                                </div>
                                <div class="col-md-6 col-lg-3 col-sm-12 col-xl-3 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsCw">
                                    <input type="radio" id="rdCombinedWeek" class="form-check-input" value="5" formControlName="rentalTypes" >
                                    <label for="rdCombinedWeek" class="form-check-label">Combined Weekly</label>
                                </div>
                                <div class="col-md-6 col-lg-3 col-sm-12 col-xl-3 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsCm">
                                    <input type="radio" id="rdCombinedMonth" class="form-check-input" value="4" formControlName="rentalTypes" >
                                    <label for="rdCombinedMonth" class="form-check-label">Combined Monthly</label>
                                </div>
                            </div>
                            <div class="row mt-1 pl-2">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                                    <div class="col-md-12 col-lg-10 col-sm-12 col-xl-10" style="display: inline-block;">
                                        <span *ngIf="this.rentalForm.value['rentalTypes'] == 1" style="color: red;font-size:12px;">Booths can be rented
                                            for single or multiple market days on a one time
                                            basis.</span>
                                        <span *ngIf="this.rentalForm.value['rentalTypes'] == 2" style="color: red;font-size:12px;">Booths can be rented for
                                            one market day per week on permanent monthly basis.</span>
                                        <span *ngIf="this.rentalForm.value['rentalTypes'] == 3" style="color: red;font-size:12px;">Booths can be rented for
                                            one market day in the week on permanent weekly basis.</span>
                                        <span *ngIf="this.rentalForm.value['rentalTypes'] == 4" style="color: red;font-size:12px;">Booth can be rented
                                            for all market days in the month on monthly basis.</span>
                                        <span *ngIf="this.rentalForm.value['rentalTypes'] == 5" style="color: red;font-size:12px;">Booths can be rented
                                            for all market days in the week on weekly basis.</span>                                
                                    </div>
                                    <div class="col-md-12 col-lg-2 col-sm-12 col-xl-2" style="display: inline-block;">
                                        <a [href]="howtoRentSpaceDocURL" target="_blank" class="btn btn-primary py-2">How To Rent</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <hr class="my-2" />
                                </div>
                            </div>
                            <div *ngIf="this.rentalForm.value['rentalTypes'] == '1'">
                                <app-dailyrental-online (isFormDirty)="setDirtyFlag($event)" (isCancelClicked)="isCancelClicked($event)" [newVendor]=newVendor (bookingFormCompleted)="bookingFormCompleted($event)" (rentalBookingData)="rentalBookingData($event)" 
                                 (resaleNoInputRequired)="resaleNoInputRequired($event)" (businessLicenseRequired)="businessLicenseRequired($event)"></app-dailyrental-online>
                            </div>
                            <div *ngIf="this.rentalForm.value['rentalTypes'] == '2'">
                                <app-monthlyrental-online (isFormDirty)="setDirtyFlag($event)" (isCancelClicked)="isCancelClicked($event)" [newVendor]=newVendor (bookingFormCompleted)="bookingFormCompleted($event)" (rentalBookingData)="rentalBookingData($event)"
                                (resaleNoInputRequired)="resaleNoInputRequired($event)" (businessLicenseRequired)="businessLicenseRequired($event)"></app-monthlyrental-online>
                            </div>
                            <div *ngIf="this.rentalForm.value['rentalTypes'] == '3'">
                                <app-weeklyrental-online (isFormDirty)="setDirtyFlag($event)" (isCancelClicked)="isCancelClicked($event)" [newVendor]=newVendor (bookingFormCompleted)="bookingFormCompleted($event)" (rentalBookingData)="rentalBookingData($event)"
                                (resaleNoInputRequired)="resaleNoInputRequired($event)" (businessLicenseRequired)="businessLicenseRequired($event)"></app-weeklyrental-online>
                            </div>
                            <div *ngIf="this.rentalForm.value['rentalTypes'] == '4'">
                                <app-combinedmonthlyrental-online (isFormDirty)="setDirtyFlag($event)" (isCancelClicked)="isCancelClicked($event)" [newVendor]=newVendor (bookingFormCompleted)="bookingFormCompleted($event)" (rentalBookingData)="rentalBookingData($event)"
                                (resaleNoInputRequired)="resaleNoInputRequired($event)" (businessLicenseRequired)="businessLicenseRequired($event)"></app-combinedmonthlyrental-online>
                            </div>
                            <div *ngIf="this.rentalForm.value['rentalTypes'] == '5'">
                                <app-combinedweeklyrental-online (isFormDirty)="setDirtyFlag($event)" (isCancelClicked)="isCancelClicked($event)" [newVendor]=newVendor (bookingFormCompleted)="bookingFormCompleted($event)" (rentalBookingData)="rentalBookingData($event)"
                                (resaleNoInputRequired)="resaleNoInputRequired($event)" (businessLicenseRequired)="businessLicenseRequired($event)"></app-combinedweeklyrental-online>
                            </div>
                        </div>
                    </div>
                    <div #divContactInfo class="bgWghite shadow pt-2 mt-2"
                        [ngStyle]="{ display: showContactInfo ? 'block' : 'none' }">
                        <div class="container" formGroupName="VendorForm">
                            <div class="row">
                                <div class="col-lg-4 pt-3">
                                    <label for="txtFirstName" i18n>*First Name</label>
                                    <input id="txtFirstName" class="form-control" type="text" #VendorFirstName
                                        formControlName="FirstName" required>
                                    <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('FirstName').hasError('required')"
                                        i18n>* required </small>
                                    <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('FirstName').hasError('maxlength')"
                                        i18n>* max length exceeded
                                    </small>
                                </div>
                                <div class="col-lg-4 pt-3"
                                    *ngIf="this.VendorGeneralSetting.VendorNameFormat.includes('Middle Name')">
                                    <label for="txtMiddleName" i18n>Middle Name</label>
                                    <input id="txtMiddleName" class="form-control" type="text" formControlName="MiddleName">
                                    <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('MiddleName').hasError('maxlength')"
                                        i18n>* max length exceeded
                                    </small>
                                </div>
                                <div class="col-lg-4 pt-3"
                                    *ngIf="this.VendorGeneralSetting.VendorNameFormat.includes('Last Name')">
                                    <label for="txtLastName" i18n>Last Name</label>
                                    <input id="txtLastName" class="form-control" type="text" formControlName="LastName">
                                    <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('LastName').hasError('maxlength')"
                                        i18n>* max length exceeded
                                    </small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0"><span *ngIf="vendorAddressMandatory">*</span>Address</label>
                                        <input id="txtAddress" class="form-control" type="text" formControlName="Address">
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('Address').hasError('maxlength')"
                                            i18n>* max length exceeded </small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0"><span *ngIf="vendorAddressMandatory">*</span>City</label>
                                        <input id="txtCity" class="form-control" type="text" formControlName="City">
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('City').hasError('required')" i18n>* required </small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0"><span *ngIf="vendorAddressMandatory">*</span>State/ Province</label>
                                        <ejs-dropdownlist id="ddlState" *ngIf="showStateDropDown" tabindex="9"
                                            [dataSource]="stateList" [fields]="StateDropDownfields"
                                            [placeholder]="'Select State'" [popupHeight]="200" [allowFiltering]="true"
                                            filterBarPlaceholder="Search for State/Province" formControlName="State">
                                        </ejs-dropdownlist>
                                        <!-- <select id="ddlState" class="form-control" *ngIf="showStateDropDown" formControlName="State">
                                            <option *ngFor="let st of stateList" [value]="st.StateCode">{{st.StateCode}}</option>
                                        </select> -->
                                        <input type="text" *ngIf="!showStateDropDown" maxlength="2" tabindex="9"
                                            class="form-control" formControlName="State">
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('State').hasError('required')" i18n>* required </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0">*Country</label>
                                        <select id="ddlCountry" class="form-control" formControlName="CountryCode"
                                            tabindex="11" (change)="onCountryChange()">
                                            <option *ngFor="let ct of countryList" [value]="ct.Code">{{ct.Name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0"><span *ngIf="vendorAddressMandatory">*</span>Zip/Postal Code</label>
                                        <!-- <input id="txtZipCode" class="form-control" type="text" formControlName="ZipCode"> -->
                                        <ejs-maskedtextbox [mask]="ZipCodeMask" tabindex="13" name="ZipCode" id="ZipCode"
                                            formControlName="ZipCode"></ejs-maskedtextbox>
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('ZipCode').hasError('required')" i18n>* required </small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0">Work Phone</label>
                                        <!-- <input class="form-control" type="text" formControlName="WorkPhone"> -->
                                        <ejs-maskedtextbox [mask]="PhoneNumberMask" tabindex="13" name="WorkPhone"
                                            id="telephone" formControlName="WorkPhone" (keyup)="onKeyUp('WorkPhone')"></ejs-maskedtextbox>
                                            <small style="color: red;" *ngIf="showWorkPhoneError" i18n>Enter valid work
                                                phone number</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0">*Cell Phone</label>
                                        <!-- <input class="form-control" type="text" formControlName="CellPhone"> -->
                                        <ejs-maskedtextbox [mask]="PhoneNumberMask" tabindex="13" name="telephone"
                                            id="telephone" formControlName="CellPhone" (keyup)="onKeyUp('CellPhone')"></ejs-maskedtextbox>
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('CellPhone').hasError('minlength')"
                                            i18n>* not complete</small>
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('CellPhone').hasError('required')" i18n>* required </small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0">*Email</label>
                                        <input id="txtEmail" class="form-control" type="text" formControlName="Email">
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('Email').hasError('required')" i18n>* required </small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0">Drivers License</label>
                                        <input id="txtDriverLicense" class="form-control" type="text" formControlName="DriverLicenseNumber">
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('DriverLicenseNumber').hasError('required')" i18n>* required </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0">DL Expiry Date</label>
                                        <!-- <input class="form-control" type="text" formControlName="VendorSince"> -->
                                        <ejs-datepicker format='MM/dd/yyyy' [allowEdit]=false
                                            id="filterWorkOrderCreatedDate" formControlName="DLExpiryDate"
                                            i18n-placeholder="DL Expiry Date" placeholder="DL Expiry Date"></ejs-datepicker>
                                        <div>
                                            <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('DLExpiryDate').invalid" i18n>* required </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0"><span *ngIf="resaleNumberRequired">*</span>Resale #</label>
                                        <input id="txtResaleNumber" class="form-control" type="text" formControlName="ResaleNumber">
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('ResaleNumber').hasError('required')" i18n>* required </small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0"><span *ngIf="businessLicenseNoRequired">*</span>Business License #</label>
                                        <input id="txtBusinessLicenseNumber" class="form-control" type="text" formControlName="BusinessLicenseNumber">
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('BusinessLicenseNumber').hasError('required')" i18n>* required </small>
                                    </div>
                                </div>                               
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3" *ngIf="businessLicenseNoRequired">
                                    <div>
                                        <label class="mb-0">*Business License Expiry Date</label>
                                        <input type="date" class="form-control" formControlName="BusinessLicenseExpiryDate"
                                        [placeholder]="'Business License Expiry Date'" i18n-placeholder="Business License Expiry Date">
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('BusinessLicenseExpiryDate').hasError('required')" i18n>* required </small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0">* Primary Selling Product</label>
                                        <ejs-dropdownlist id="ProductPriority" [dataSource]="productList"
                                            [fields]="ProductDropDownfields" [placeholder]="'Select product'"
                                            [popupHeight]="200" [allowFiltering]="true"
                                            filterBarPlaceholder="Search for product" formControlName="ProductPriority"
                                            (valueChange)="primaryproductchange($event)">
    
                                        </ejs-dropdownlist>
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('ProductPriority').invalid" i18n>*
                                            required</small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                                    <div>
                                        <label class="mb-0">* Vendor Type</label>
                                        <!-- <input class="form-control" type="text" formControlName="VendorType"> -->
    
                                        <ejs-dropdownlist id="ddlType" [dataSource]="VendorTypeList"
                                            [fields]="{text:'VtyTxtVendorType',value:'VtyTxtVendorType'}"
                                            formControlName="VendorType" tabindex="11" placeholder="Select Vendor Type">
    
                                        </ejs-dropdownlist>
                                        <small style="color: red;" *ngIf="rentalForm.controls['VendorForm'].get('VendorType').invalid" i18n>*
                                            required</small>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-right mb-3">
                                    <button class="btn btn-outline-primary mt-3 mr-1"
                                    (click)="rentOnline()">Back</button>
                                    <button class="btn btn-outline-primary mt-3 mr-2" (click)="cancelContactModification()">Cancel</button>
                                    <button class="btn btn-primary mt-3" (click)="ValidationVendorDetails()">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div #divLoginInfo class="bgWghite shadow pt-2 mt-2"
                    [ngStyle]="{ display: showLoginInfo ? 'block' : 'none' }">
                        <div class="container" formGroupName="OnlineAccessForm">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">*User Name</label>
                                        <input id="txtUserName" class="form-control" type="text" formControlName="UserName">
                                        <small
                                            *ngIf="rentalForm.controls['OnlineAccessForm'].get('UserName').invalid && (rentalForm.controls['OnlineAccessForm'].get('UserName').dirty || rentalForm.controls['OnlineAccessForm'].get('UserName').touched)"
                                            style="color: red;">
                                            <span *ngIf="rentalForm.controls['OnlineAccessForm'].get('UserName').hasError('required')" i18n>*
                                                required</span>
                                            <span *ngIf="rentalForm.controls['OnlineAccessForm'].get('UserName').hasError('minlength')" i18n>*
                                                minimum length is 6 characters</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">*Password</label>
                                        <input id="txtPassword" class="form-control" type="password"
                                            formControlName="Password">
                                        <small
                                            *ngIf="rentalForm.controls['OnlineAccessForm'].get('Password').errors && (rentalForm.controls['OnlineAccessForm'].get('Password').dirty || rentalForm.controls['OnlineAccessForm'].get('Password').touched)"
                                            style="color: red;">
                                            <div *ngIf="rentalForm.controls['OnlineAccessForm'].get('Password').errors.required">*password is
                                                required.</div>
                                            <div *ngIf="rentalForm.controls['OnlineAccessForm'].get('Password').errors.minLength">*password must be
                                                at least 6 characters long.</div>
                                            <div *ngIf="rentalForm.controls['OnlineAccessForm'].get('Password').errors.maxlength">*password must be
                                                at least 20 characters long.</div>
                                            <div *ngIf="rentalForm.controls['OnlineAccessForm'].get('Password').errors.specialChar">*password must
                                                contain at least one special
                                                character.</div>
                                            <div *ngIf="rentalForm.controls['OnlineAccessForm'].get('Password').errors.upperCase">*password must
                                                contain at least one uppercase letter
                                                .</div>
                                            <div *ngIf="rentalForm.controls['OnlineAccessForm'].get('Password').errors.lowerCase">*password must
                                                contain at least one lowercase letter
                                                .</div>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">*Confirm Password</label>
                                        <input id="txtConfirmPassword" class="form-control" type="password"
                                            formControlName="ConfirmPassword">
                                        <small
                                            *ngIf="rentalForm.controls['OnlineAccessForm'].get('ConfirmPassword').errors && (rentalForm.controls['OnlineAccessForm'].get('ConfirmPassword').dirty || rentalForm.controls['OnlineAccessForm'].get('ConfirmPassword').touched)"
                                            style="color: red;">
                                            <div *ngIf="rentalForm.controls['OnlineAccessForm'].get('ConfirmPassword').hasError('required')">
                                                *confirm password is
                                                required.</div>
                                        </small>
                                        <small
                                            *ngIf="(rentalForm.controls['OnlineAccessForm'].get('ConfirmPassword').dirty || rentalForm.controls['OnlineAccessForm'].get('ConfirmPassword').touched)"
                                            style="color: red;">
                                            <div
                                                *ngIf="rentalForm.controls['OnlineAccessForm'].get('Password').value !== rentalForm.controls['OnlineAccessForm'].get('ConfirmPassword').value">
                                                *Confirm password does not match.
                                            </div>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">*Security Question</label>
                                        <select id="txtSecurityQuestion" class="form-control"
                                            formControlName="SecurityQuestion" tabindex="11">
                                            <option *ngFor="let sq of securityQuestions" [value]="sq.ID">
                                                {{sq.SecurityQuestion}}</option>
                                        </select>
                                        <small
                                            *ngIf="(rentalForm.controls['OnlineAccessForm'].get('SecurityQuestion').dirty || rentalForm.controls['OnlineAccessForm'].get('SecurityQuestion').touched)"
                                            style="color: red;">
                                            <div *ngIf="rentalForm.controls['OnlineAccessForm'].get('SecurityQuestion').hasError('required')">
                                                *security question is
                                                required.</div>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">*Answer</label>
                                        <input id="txtAnswer" class="form-control" type="text" formControlName="Answer">
                                    </div>
                                    <small
                                        *ngIf="(rentalForm.controls['OnlineAccessForm'].get('Answer').dirty || rentalForm.controls['OnlineAccessForm'].get('Answer').touched)"
                                        style="color: red;">
                                        <div *ngIf="rentalForm.controls['OnlineAccessForm'].get('Answer').hasError('required')">*security answer is
                                            required.</div>
                                    </small>
                                </div>
                            </div>
                            <div class="row"
                                *ngIf="this.registrationFee.RegistrationFee!==0 && this.registrationFee.ChargeVendorRegistration">
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">*Registeration Fee</label>
                                        <input id="txtRegistrationFee" class="form-control text-right" type="text"
                                            [disabled]="true" [value]="rentalForm.controls['OnlineAccessForm'].get('RegistrationFee').value">
                                    </div>
                                </div>
                            </div>
                            <div class="row"
                                *ngIf="this.registrationFee.RegistrationFee!==0 && this.registrationFee.ChargeVendorRegistration">
                                <div class="col-12 text-left mt-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="chkCreditCard"
                                            formControlName="registrationPaymentCheck">
                                        <label class="form-check-label" for="chkCreditCard">
                                            I authorize ABC Flea Market to charge this amount to my Credit Card. Upon
                                            authorization and full payment by the issuing card company, ABC Flea Market will
                                            submit a confirmation number, as verification of charged transaction, to the
                                            above named cardholder.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-left mt-2">
                                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS4xMjQiIGhlaWdodD0iMTUuODQzIiB2aWV3Qm94PSIwIDAgMjEuMTI0IDE1Ljg0MyI+CiAgPHBhdGggaWQ9Ikljb25fYXdlc29tZS1oYW5kLXBvaW50LXJpZ2h0IiBkYXRhLW5hbWU9Ikljb24gYXdlc29tZS1oYW5kLXBvaW50LXJpZ2h0IiBkPSJNMjEuMTI0LDEwLjFBMS44NiwxLjg2LDAsMCwxLDE5LjI3NiwxMS45aC00LjEyYTIuMDI5LDIuMDI5LDAsMCwxLS4yNiwyLjkyNywxLjg5LDEuODksMCwwLDEtLjY3OSwyLjU3MWMuMzcsMi4wMjctLjkwNSwyLjk0LTMsMi45NC0uMTEzLDAtLjU0OC0uMDA4LS42Ni0uMDA4LTIuNTU3LjAwNy0zLjE3Mi0xLjI4Mi01LjEtMS41ODFhLjk4OS45ODksMCwwLDEtLjgzNi0uOTc4VjEwLjdoMEExLjk4LDEuOTgsMCwwLDEsNS44LDguODljMS4xOTItLjUzNiwzLjkzNy0yLjAyMyw0LjQzNy0zLjE5YTEuNzU5LDEuNzU5LDAsMCwxLDEuNjUtMS4yQTEuOTgyLDEuOTgyLDAsMCwxLDEzLjcsNy4yNmE1LjYwOCw1LjYwOCwwLDAsMS0uNTg0LDEuMDI4aDYuMTU3QTEuODksMS44OSwwLDAsMSwyMS4xMjQsMTAuMVptLTE3LjE2My4wMTR2Ny45MjJhLjk5Ljk5LDAsMCwxLS45OS45OUguOTlhLjk5Ljk5LDAsMCwxLS45OS0uOTlWMTAuMTExYS45OS45OSwwLDAsMSwuOTktLjk5aDEuOThBLjk5Ljk5LDAsMCwxLDMuOTYxLDEwLjExMVpNMi44MDYsMTcuMDQyYS44MjUuODI1LDAsMSwwLS44MjUuODI1QS44MjUuODI1LDAsMCwwLDIuODA2LDE3LjA0MloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTQuNSkiIGZpbGw9IiMwNzI4ZmYiLz4KPC9zdmc+Cg=="
                                        class="img-fluid" />
                                    &nbsp;<a [href]="rulesAndRegulations" target="_blank" class="rules">Click here to read the Rules and Regulations</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-left mt-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="chkRules"
                                            formControlName="registrationRuleReadCheck">
                                        <label class="form-check-label" for="chkRules">
                                            By checking this box, I acknowledge that I have read, understand, and agree to
                                            the rules and regulations, and will Abide by them. Please visit the office for a
                                            copy of the rules and regulations
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-right mb-3">
                                    <button class="btn btn-outline-primary mt-3 mr-1"
                                        (click)="contactInfo()">Back</button>
                                    <button class="btn btn-outline-primary mt-3 mr-1" (click)="Oncancel()">Cancel</button>
                                    <button class="btn btn-primary mt-3 mr-1" (click)="openPaymentPage()" [disabled]="!rentalForm.controls['OnlineAccessForm'].get('registrationRuleReadCheck').value">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div #divPayemntInfo class="bgWghite shadow pt-2 mt-2" *ngIf="openPaymentDetails" [ngStyle]="{ display: showPaymentPage ? 'block' : 'none' }" style="padding-bottom: 2rem;">
                        <app-vendor-payments [newVendor]="newVendor" [businessLicenseInfo]="businessLicenseInfo" (cancelClick)="paymentCancelClick($event)"></app-vendor-payments>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
