import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GridComponent, GridLine, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { forkJoin, Subscription } from 'rxjs';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
import { RentalService } from 'src/app/services/Rental.service';
import { VendorService } from 'src/app/services/Vendor.service';
import { customCurrencyPipe } from 'src/app/Shared/customCurrency.pipe';
import { SharedDataService } from 'src/app/Shared/SharedData.service';
import { DiscountType } from 'src/app/Shared/enum';
import Swal from 'sweetalert2';
interface DateString {
  startDate: string;
}
@Component({
  selector: 'app-monthlyrental-online',
  templateUrl: './monthlyrental-online.component.html',
  styleUrls: ['./monthlyrental-online.component.scss', '../rent-online.component.scss']
})

export class MonthlyrentalOnlineComponent implements OnInit, OnDestroy {
  rentalForm : FormGroup;
  marketId;
  vendorId;
  sunday = false;
  monday = false;
  tuesday = false;
  wednesday = false;
  thursay = false;
  friday = false;
  saturday = false;
  selectedMarketDays=[];
  selectedOtherItems = []
  parts = [];
  monthlyMarketDays = [];
  excludedMarketDays=[];
  selectedMonthlyBooths = []
  displaySalesTax = false;
  displayCityTax = false;
  tempRecordNo = 0;
  otherRentalCharges = [];
  otherItemList = [];
  vendorProducts = [];
  defaultProduct;
  vendorDiscountPlans =[];
  priorBalance = 0.00;
  AllowRentingOtherItems = false;
  layoutParts = 0;
  totalRentalCharge = 0.00;
  totalOtherRentalCharge = 0.00;
  totalSalesTax = 0.00;
  totalSecondLeveltax = 0.00
  totalCharge = 0.00;
  selectedDates = [];
  private subscriptions: Subscription[] = [];
  MonthlyLayoutData;
  combinedMonthlyBookingEndDate;
  displayLayout = false;
  totalDiscountCharge;
  isdirty = false;
  @Input() newVendor;
  @Output() bookingFormCompleted = new EventEmitter<any>(); 
  @Output() rentalBookingData = new EventEmitter<any>();
  @Output() isFormDirty = new EventEmitter<any>(); 
  @Output() isCancelClicked = new EventEmitter<any>(); 
  @Output() resaleNoInputRequired = new EventEmitter<any>();
  @Output() businessLicenseRequired = new EventEmitter<any>();
  ApplyBookingFee = false;
  ApplyBookingFeeId;
  boothsForOtherCharge = [];
  bookingFeeAmount;
  displayOtherChargeByBooth = false;
  displayOtherChargeSalesTax = false;
  displayOtherChargeCityTax = false;
  ResaleNumberRequired  = false;
  DaysSellWithoutResaleNumber;
  monthlyRentalStartDates;
  BusinessLicenseNumberRequired  = false;
  DaysSellWithoutBusiLicNumber;
  resaleNoOption = "1";
  exemptResale = false;
  resaleNo = "";
  resaleNoDisplay = false;
  businessLicenseDisplay = false;
  ResaleOrBusinessHeader = "";
  businessLicenseNumber;
  defaultPart;
  businessLicenseExpiryDate;
  vendorRentalDays = [];
  marketDayCountForResale = 0;
  resaleOptionSelected = false;
  selectedBoothsForRental=[];
  perDiemRentApplicable = false;
  selectedOtherCharges = [];
  selectedMonthlyBoothData = [];
  public lines: GridLine;
  rentalStartDates;
  warnExcessPayment = false;
  warnZeroPayment = false;
  RentalTaxPercentage = 0;
  OtherRentalTaxPercentage = 0;
  RentalSecondTaxPercentage = 0;
  OtherRentalSecondTaxPercentage = 0;
  DisplayPayDueDate = false;
  DisplaySpecificDate = false;
  OtherChargesPeriod = false;
  DisplayOptionValue = 0;
  secondTaxName="";
  public textWrapSettings: any = { wrapMode: 'Both' }; // Enable text wrapping
  sortOptions: SortSettingsModel = {
    columns: [{ field: 'RentalStartDate', direction: 'Ascending' }, { field: 'BoothNumber', direction: 'Ascending' }]
  };
  sortOtherItemsOptions: SortSettingsModel = {
    columns: [{ field: 'OtherItem', direction: 'Ascending' }, { field: 'BoothNo', direction: 'Ascending' }]
  };
  public loaderIcon: Promise<boolean>;
  @ViewChild('gridMonthlyRental', { static: false }) public gridMonthlyRental: GridComponent;
  @ViewChild('modalResaleNo', { static: false }) modalResaleNo: ElementRef;
  @ViewChild('modalMonthlyOtherCharges', { static: false }) modalMonthlyOtherCharges: ElementRef;
  @ViewChild('gridOtherRental', { static: false }) public gridOtherRental: GridComponent;
   constructor(private formbuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    public rentalService: RentalService,
    public vendorService: VendorService,
    private customCurrency: customCurrencyPipe,
    private datePipe: DatePipe,
    private sharedDataService: SharedDataService,
    private marketInfoService: MarketInfoService){
      this.rentalForm = this.formbuilder.group({
        marketWeekSunday: [0],
        marketWeekMonday: [0],
        marketWeekTuesday: [0],
        marketWeekWednesday: [0],
        marketWeekThursday: [0],
        marketWeekFriday: [0],
        marketWeekSaturday: [0],
        marketParts:[''],
        rentalStartMonth:[''],
        rentalEndMonth: [''],
        monthlyRentalStartDates:[],
        otherItemsScreen: this.formbuilder.group({
          otherChargeMarketMonth: [''],
          otherItemSearchText: ['']
        })
      })
    }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
      this.vendorId = params['vendorId'];
    });
    this.getMarketDays();
    this.getRentalPaymentSettings();
    this.getVendorProducts();
    this.getParts();
    this.getMarketVendorSettings();
    this.getVendorBusinessLicenseSettings();
    this.generateTempRecordNumber();
    this.getVendorBalances();
    this.getOtherRentalItems();
    if (this.sharedDataService.paymentSharedData)
      this.loadSelectedBooths();
  }

  getParts() {
    const sub = this.marketInfoService.getParts(this.marketId).subscribe((res: any) => {
      if (res) {
        this.parts = res;
        this.getWebRentalSetting();
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  getMarketVendorSettings(){
    this.loaderIcon = Promise.resolve(true);
    const sub = this.vendorService.getMarketVendorSettings(this.vendorId, this.marketId).subscribe((res: any) => {
      var response = res.body;
      if (response) {
        this.exemptResale = response.ExemptResale;
        this.resaleNo = response.Resale;
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  getVendorBusinessLicenseSettings(){
    this.loaderIcon = Promise.resolve(true);
    const sub = this.vendorService.getVendorBusinessLicenseSettings(this.vendorId, this.marketId).subscribe((res: any) => {
     if (res) {
        this.businessLicenseNumber = res.LicenseNumber;
        this.businessLicenseExpiryDate = res.ExpiryDate;
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  getRentalPaymentSettings() {
    this.loaderIcon = Promise.resolve(true);
    this.subscriptions.push(this.rentalService.getRentalSettings(this.marketId).subscribe((res: any) => {
      if (res) {
        this.warnExcessPayment = res.WarnExcessPayment;
        this.warnZeroPayment = res.WarnZeroPayment;
        this.displayOtherChargeByBooth = res.DisplayOtherChargesByBooth;
        this.displayCityTax = res.RentalSecondTaxable;
        this.displaySalesTax = res.RentalTaxable;
        this.perDiemRentApplicable = res.PerDiemRentApplicable;
        this.gridMonthlyRental.getColumnByField("SalesTax").visible = this.displaySalesTax;
        this.gridMonthlyRental.getColumnByField("CityTax").visible = this.displayCityTax;
        if(this.newVendor){
          this.gridMonthlyRental.getColumnByField("Product").visible = false;
        }
        this.gridMonthlyRental.refreshColumns();
        this.displayOtherChargeSalesTax = res.OtherRentalTaxable;
        this.displayOtherChargeCityTax = res.OtherRentalSecondTaxable;       
        this.ResaleNumberRequired = res.ResaleNumberRequired;
        this.DaysSellWithoutResaleNumber = res.DaysSellWithoutResaleNumber;
        this.BusinessLicenseNumberRequired = res.BusinessLicenseNumberRequired;
        this.DaysSellWithoutBusiLicNumber = res.DaysSellWithoutBusiLicNumber;
        this.DisplayPayDueDate = res.DisplayPayDueDate;
        this.DisplaySpecificDate = res.DisplaySpecificDate;
        this.DisplayOptionValue = res.DisplayOptionValue;
        const nextDate = this.getNextDate(this.DisplayOptionValue);
        this.secondTaxName = res.SecondTaxName ? res.SecondTaxName: "Sec Level Tax"
        var secLevelTaxRentHeader = this.gridMonthlyRental.getColumnByField("CityTax"); 
        secLevelTaxRentHeader.headerText = this.secondTaxName;       
        this.RentalTaxPercentage = res.RentalTaxPercentage;
        this.OtherRentalTaxPercentage = res.OtherRentalTaxPercentage;
        this.RentalSecondTaxPercentage = res.RentalSecondTaxPercentage;
        this.OtherRentalSecondTaxPercentage = res.OtherRentalSecondTaxPercentage;
        this.OtherChargesPeriod = res.OtherChargesPeriod;
        this.gridMonthlyRental.refresh();
        if (this.AllowRentingOtherItems) {
          var secLevelTaxOtherRentHeader = this.gridOtherRental.getColumnByField("CityTax");
          secLevelTaxOtherRentHeader.headerText = this.secondTaxName;
          this.gridOtherRental.getColumnByField("BoothNo").visible = res.DisplayOtherChargesByBooth;
          this.gridOtherRental.getColumnByField("SalesTax").visible = this.displayOtherChargeSalesTax;
          this.gridOtherRental.getColumnByField("CityTax").visible = this.displayOtherChargeCityTax;
          this.gridOtherRental.refreshColumns();
          this.gridOtherRental.refresh();
        }
      }
      this.loaderIcon = Promise.resolve(false);
    }, err =>{
      this.loaderIcon = Promise.resolve(false);
    }));
  }

  getNextDate(day: number): Date {
    if (this.DisplaySpecificDate) {
      const today = new Date();
      let nextDate = new Date(today.getFullYear(), today.getMonth(), day);
      // If the nextDate is in the past or today, move to the next month
      if (nextDate < today) {
        nextDate.setMonth(nextDate.getMonth() + 1);
      }
      // Handle cases where the day is not valid in the next month (e.g., 31st in February)
      while (nextDate.getDate() !== day) {
        nextDate.setMonth(nextDate.getMonth() + 1);
        nextDate.setDate(day);
      }
      return nextDate;
    }
    else{
      const today = new Date();
      let nextDate = new Date(today.setDate(today.getDate() + day));
      return nextDate;
    }
  }

  getOtherRentalItems() {
    var formattedDate;
    if (this.rentalForm.controls['otherItemsScreen'].value['otherChargeMarketMonth']) {
      var input = this.rentalForm.controls['otherItemsScreen'].value['otherChargeMarketMonth'];
      const date = new Date(`${input} 1`);
      formattedDate = this.datePipe.transform(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`, 'MM/dd/yyyy');
    }
    this.subscriptions.push(this.rentalService.getOtherRentalItems(this.marketId, formattedDate).subscribe((response) => {
      if (response) {
        response.map((el, i) => {
          if (el.WebRental) {
            const ob = {
              Id: el.OtherItemId,
              OtherItem: el.OtherItem,
              OtherItemCharge: this.customCurrency.transform(el.OtherItemCombinedMonthlyCharge),
              Quantity: 0,
              OtherItemtotal: this.customCurrency.transform('0'),
              othChgBooths: "none"
            }
            this.otherRentalCharges.push(ob);
            this.otherItemList.push(ob);
          }
        });
      }
    }, (err) => {
    }));
  }

  onCheckNumKeyPress(event: KeyboardEvent) {
    const allowedChars = /[0-9]/g;
    const key = event.key;
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === '#') {
      return;
    }
    if (!key.match(allowedChars)) {
      event.preventDefault();
    }
  }

  updateOtherItem(data) {
    if (parseFloat(data.Quantity) == 0) {
      data.Quantity = "1"
    }

    this.totalSalesTax -= data.SalesTax.toString().includes('(') && data.SalesTax.toString().includes(')') ? -1 * parseFloat(data.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(data.SalesTax.toString().replace(/[^0-9.-]/g, ''));
    this.totalSecondLeveltax -= data.CityTax.toString().includes('(') && data.CityTax.toString().includes(')') ? -1 * parseFloat(data.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(data.CityTax.toString().replace(/[^0-9.-]/g, ''));
    this.totalOtherRentalCharge -= data.OtherItemtotal.toString().includes('(') && data.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(data.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(data.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
    this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;

    var index;
    if (data.NewItem == true)
      index = this.selectedOtherItems.findIndex(oth => oth.Id === data.Id && oth.RentalMonth == data.RentalMonth && oth.NewItem == true && oth.BoothNo == data.BoothNo);
    else
      index = this.selectedOtherItems.findIndex(oth => oth.Id === data.Id && oth.RentalMonth == data.RentalMonth && oth.MonthlyRentalIds == data.MonthlyRentalIds && oth.BoothNo == data.BoothNo);

    if (index !== -1) {
      var obj = {
        MarketId: this.marketId,
        OtherItemId: data.Id,
        VendorId: this.vendorId,
        Quantity: data.Quantity,
        OtherItemCharge: data.OtherItemCharge.includes('(') && data.OtherItemCharge.includes(')') ? (-1) * parseFloat(data.OtherItemCharge.replace(/[^0-9.-]/g, '')) : parseFloat(data.OtherItemCharge.replace(/[^0-9.-]/g, '')),
        WRNewVendorRequest: this.newVendor
      }
      this.subscriptions.push(this.rentalService.getOtherItemsRentalCharges(obj).subscribe((res: any) => {
        this.selectedOtherItems[index].SalesTax = this.customCurrency.transform(res.SalesTax.toString()),
          this.selectedOtherItems[index].CityTax = this.customCurrency.transform(res.CityTax.toString())
        const isNegative = data.OtherItemCharge.includes('(') && data.OtherItemCharge.includes(')');
        const totalValue = parseFloat(data.OtherItemCharge.replace(/[^0-9.-]/g, ''));
        const finalCharge = isNegative ? -totalValue : totalValue;
        data.OtherItemtotal = this.customCurrency.transform((finalCharge * data.Quantity).toString());
        this.selectedOtherItems[index].OtherItemtotal = data.OtherItemtotal;
        this.selectedOtherItems[index].OtherItemCharge = this.customCurrency.transform((finalCharge).toString());
        this.selectedOtherItems[index].Recurring = data.Recurring;
        this.selectedOtherItems[index].Reference = data.Reference;
        this.selectedOtherItems[index].Quantity = data.Quantity;
        this.selectedOtherItems[index].SalesTax = this.customCurrency.transform(res.SalesTax.toString()),
          this.selectedOtherItems[index].CityTax = this.customCurrency.transform(res.CityTax.toString()),
          this.gridOtherRental.refresh();
        this.getDiscounts();
        this.totalOtherRentalCharge += data.OtherItemtotal.toString().includes('(') && data.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(data.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(data.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
        this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
        this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
        this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
      }));
    }
  }

  getVendorProducts() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.rentalService.GetVendorProductsById(this.marketId, this.vendorId).subscribe((res: any) => {
      if (res) {
        this.vendorProducts = res.body;
        this.defaultProduct = this.vendorProducts[0].ProductId;
        this.loaderIcon = Promise.resolve(false);
      }
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  generateTempRecordNumber() {
    this.subscriptions.push(this.rentalService.generateTempRecordNumber(this.marketId).subscribe((response) => {
      if (response.Success) {
        this.tempRecordNo = response.Message;
      }
    }));
  }

  getVendorBalances() {
    this.vendorDiscountPlans =[];
  forkJoin({
    vendorBalanceRes: this.rentalService.getVendorBalances(this.vendorId, this.marketId),
    discountPlansRes:    this.vendorService.GetDiscountPlan(this.vendorId,this.marketId)
  }).subscribe(
    ({ vendorBalanceRes, discountPlansRes }: any) => {
      if (vendorBalanceRes) {
        this.priorBalance = vendorBalanceRes.PriorBalance;
      }
      if(discountPlansRes){
        if (discountPlansRes) {
          for (const el of discountPlansRes) {
            if (el.Monthly && el.OnlineTransactions) {
              const ob = {
                PlanSeqId: el.PlanSeqId,
                PlanId: el.PlanId,
                PlanName: el.PlanName,
                EffectiveFrom: this.datePipe.transform(el.EffectiveFrom, 'MM/dd/yyyy'),
                ExpiryDate: this.datePipe.transform(el.ExpiryDate, 'MM/dd/yyyy'),
                DiscountType: el.DiscountType == 0 ? DiscountType.Percentage : DiscountType.FixedAmount,
                DiscountValue: el.DiscountValue,
                OtherItems: el.OtherItems,
                Daily: el.Daily,
                Weekly: el.Weekly,
                Monthly: el.Monthly,
                CombinedWeekly: el.CombinedWeekly,
                CombinedMonthly: el.CombinedMonthly,
                IsExceedAmount: el.IsExceedAmount,
                ExceedAmount: el.ExceedAmount,
                BalanceDue: el.BalanceDue,
                Notes: el.Notes,
                QDR: el.QDR,
                CheckAll: el.Selected,
                AutoSelected: el.Selected,
                DisableDiscount: el.Selected,
                AppliedDiscount: this.customCurrency.transform("0.00"),
                SalesTaxAmount: this.customCurrency.transform("0.00"),
                SecondLevelTaxAmount: this.customCurrency.transform("0.00"),
                SalesTaxApplied: el.SalesTax,
                SecondLevelTaxApplied: el.SecondLevelTax,
              }
              this.vendorDiscountPlans.push(ob);
            }
          }
          this.loaderIcon = Promise.resolve(false);
        //  this.disableDiscountAmount = this.vendorDiscountPlans.length > 0 ? false : true;
        }
        this.getDiscounts();
      }
    })
  }

  getDiscounts() {
    var totalRentalCharge = this.totalRentalCharge;
    var totalSalesTax = this.totalSalesTax;
    var totalSecondLeveltax = this.totalSecondLeveltax;
    var totalOtherRentalCharge = this.totalOtherRentalCharge;
    var priorBalance = 0;
    var totalChargeWithDue = this.priorBalance + (totalRentalCharge)
      + (totalSalesTax)
      + (totalSecondLeveltax)
      + (totalOtherRentalCharge)

    var totalCharge = (totalRentalCharge) + (totalSalesTax) + (totalSecondLeveltax) + (totalOtherRentalCharge)
    //   totalCharge = totalCharge.toString().includes('(') && totalCharge.toString().includes(')') ? -1 * parseFloat(totalCharge.toString().replace(/[^0-9.-]/g, '')) : parseFloat(totalCharge.toString().replace(/[^0-9.-]/g, ''));

    if (this.vendorDiscountPlans.length > 0 && totalCharge > 0) {
      var discountTotalAmount = 0;
      var totalAmountForDiscount = 0;
      for (const disc of this.vendorDiscountPlans) {
        disc.CheckAll = disc.AutoSelected;
        if (disc.CheckAll && disc.Monthly) {
          var currentDiscountValue = 0;
          if (!disc.BalanceDue || (disc.BalanceDue && priorBalance <= 0)) {
            if (!disc.IsExceedAmount || (disc.IsExceedAmount && totalCharge > disc.ExceedAmount)) {
              const discountValue = disc.DiscountValue;
              totalAmountForDiscount = totalRentalCharge
              if (disc.OtherItems) {
                totalAmountForDiscount += totalOtherRentalCharge
              }
              if (disc.DiscountType == 'Percentage') {
                discountTotalAmount += totalAmountForDiscount * (discountValue / 100);
                currentDiscountValue = totalAmountForDiscount * (discountValue / 100);
              }
              else {
                discountTotalAmount += discountValue;
                currentDiscountValue = discountValue;
              }
            }
            else {
              disc.CheckAll = false;
            }
          }
          else {
            disc.CheckAll = false;
          }
          disc.AppliedDiscount = this.customCurrency.transform(currentDiscountValue.toString());
        }
        else {
          disc.AppliedDiscount = this.customCurrency.transform("0");
        }

        this.totalDiscountCharge = parseFloat(discountTotalAmount.toFixed(2)).toString()
      }
    }
  }

  getWebRentalSetting() {
    const sub = this.marketInfoService.getWebRentalSetting(this.marketId).subscribe((res: any) => {
      if (res) {
        this.defaultPart = res.DefaultLayoutPart;
        this.rentalForm.get('marketParts').setValue(this.defaultPart);
        this.ApplyBookingFee = res.ApplyBookingFee;
        this.ApplyBookingFeeId = res.ApplyBookingFeeId;
        this.AllowRentingOtherItems = res.AllowRentingOtherItems;
        if (this.ApplyBookingFee) {
          this.subscriptions.push(this.rentalService.getBookingFeeById(this.marketId, this.ApplyBookingFeeId).subscribe((resFee: any) => {
            if (resFee) {
              this.bookingFeeAmount = resFee.OtherItemCharge;
            }
          }));
        }
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  } 

  getMarketDays() {
    const sub = this.marketInfoService.getMarketDays(this.marketId).subscribe((res: any) => {
      if (res) {
        this.sunday = res.Sunday;
        this.monday = res.Monday;
        this.tuesday = res.Tuesday;
        this.wednesday = res.Wednesday;
        this.thursay = res.Thursday;
        this.friday = res.Friday;
        this.saturday = res.Saturday;
        this.rentalForm.get('marketWeekSunday').setValue(this.sunday);
        this.rentalForm.get('marketWeekMonday').setValue(this.monday);
        this.rentalForm.get('marketWeekTuesday').setValue(this.tuesday);
        this.rentalForm.get('marketWeekWednesday').setValue(this.wednesday);
        this.rentalForm.get('marketWeekThursday').setValue(this.thursay);
        this.rentalForm.get('marketWeekFriday').setValue(this.friday);
        this.rentalForm.get('marketWeekSaturday').setValue(this.saturday);  
        this.getMonthlyMarketDays();
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  getMonthlyMarketDays() {
    this.subscriptions.push(this.marketInfoService.getMonthlyMarketDays(this.marketId).subscribe((response) => {
      if (response) {
        this.monthlyMarketDays = response;
        this.rentalForm.patchValue({
          rentalStartMonth: response[0].RentalMonths,
          rentalEndMonth: response[0].RentalMonths,
        });
        this.rentalForm.controls['otherItemsScreen'].patchValue({
          otherChargeMarketMonth: response[0].RentalMonths
        });  
        this.selectedMonthlyMarketDays();  
      }
    }, (err) => {
    }));
  }

  isDateSelected(item: any): boolean {
    var isItemSelected = this.selectedOtherItems.some(
       (element) =>
         (element.RentalMonth === this.rentalForm.controls['otherItemsScreen'].value['otherChargeMarketMonth']) &&
         element.Id === item.Id && element.NewItem == true
     );
 
     if(!isItemSelected){
       isItemSelected = this.selectedOtherCharges.some(
         (element) =>
           (element.RentalMonth === this.rentalForm.controls['otherItemsScreen'].value['otherChargeMarketMonth'])  &&
           element.Id === item.Id
       );
     }
 
     !isItemSelected? item.otherItemChecked = false : item.otherItemChecked = true;
     item.othChgBooths = "none";
     return isItemSelected;
   }

  checkRentalMonths() {
    if (this.rentalForm.value['rentalStartMonth'] && this.rentalForm.value['rentalEndMonth']) {
      const startMonth = this.rentalForm.value['rentalStartMonth'];
      const endMonth = this.rentalForm.value['rentalEndMonth'];
      const [startMonthDate, startYear] = startMonth.split(' ');
      const [endMonthDate, endMonthYear] = endMonth.split(' ');
      const startMonthIndex = this.getMonthIndex(startMonthDate);
      const endMonthIndex = this.getMonthIndex(endMonthDate);
      if (endMonthYear < startYear || (endMonthYear == startYear && endMonthIndex < startMonthIndex)) {
        this.rentalForm.get('rentalEndMonth').setValue(startMonth);
      }    
      this.selectedMonthlyMarketDays();      
    }
  }  

  selectedMonthlyMarketDays() {
    this.selectedMarketDays = [];
    this.rentalForm.value['marketWeekSunday'] == true ? this.selectedMarketDays.push(1) : 0;
    this.rentalForm.value['marketWeekMonday'] == true ? this.selectedMarketDays.push(2) : 0;
    this.rentalForm.value['marketWeekTuesday'] == true ? this.selectedMarketDays.push(3) : 0;
    this.rentalForm.value['marketWeekWednesday'] == true ? this.selectedMarketDays.push(4) : 0;
    this.rentalForm.value['marketWeekThursday'] == true ? this.selectedMarketDays.push(5) : 0;
    this.rentalForm.value['marketWeekFriday'] == true ? this.selectedMarketDays.push(6) : 0;
    this.rentalForm.value['marketWeekSaturday'] == true ? this.selectedMarketDays.push(7) : 0;
    this.monthlyRentalStartDates = [];
    this.selectedMarketDays.map((el, i) => {      
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const startMonth = this.rentalForm.value['rentalStartMonth'];
      const endMonth = this.rentalForm.value['rentalEndMonth'];
      const [startMonthDate, startYear] = startMonth.split(' ');
      const [endMonthDate, endMonthYear] = endMonth.split(' ');
      const excludedDates = this.excludedMarketDays.map(dateString => new Date(dateString));
      const startMonthIndex = this.getMonthIndex(startMonthDate);
      const endMonthIndex = this.getMonthIndex(startMonthDate);
      var startDate;
      if (startYear === currentYear.toString() && startMonthIndex === currentMonth)
        startDate = currentDate
      else
        startDate = new Date(startYear, startMonthIndex, 1);
      const endDate = new Date(startYear, endMonthIndex + 1, 0);;
      const selectedDayOfWeek = el - 1;
      let currentDay = new Date(startDate);
      while (new Date(this.datePipe.transform(currentDay,'MM/dd/yyyy')) <= new Date(this.datePipe.transform(endDate,'MM/dd/yyyy'))) {
        var exludedDate = excludedDates.some(date => {
          return this.datePipe.transform(date, 'MM/dd/yyyy') === this.datePipe.transform(currentDay, 'MM/dd/yyyy');
        });
        if (currentDay.getDay() === selectedDayOfWeek && !exludedDate) {
          this.monthlyRentalStartDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }
      const compareDates = (a: DateString, b: DateString): number => {
        const dateA = new Date(a.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
        const dateB = new Date(b.startDate.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
        return dateA - dateB;
      };
      const sortedDates = this.monthlyRentalStartDates.sort(compareDates);
      if (this.monthlyRentalStartDates && this.monthlyRentalStartDates.length > 0) {
        this.rentalForm.get('monthlyRentalStartDates').setValue(this.monthlyRentalStartDates[0].startDate)
      }
    });
  }

  getMonthIndex(monthString: string): number {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthIndex = months.findIndex(month => month.toLowerCase() === monthString.toLowerCase());
    return monthIndex !== -1 ? monthIndex : 0;
  }

  checkMarketDaysSelected() {
    const sunday = this.rentalForm.value['marketWeekSunday'];
    const monday = this.rentalForm.value['marketWeekMonday'];
    const tuesday = this.rentalForm.value['marketWeekTuesday'];
    const wednesday = this.rentalForm.value['marketWeekWednesday'];
    const thursday = this.rentalForm.value['marketWeekThursday'];
    const friday = this.rentalForm.value['marketWeekFriday'];
    const saturday = this.rentalForm.value['marketWeekSaturday'];
    return sunday || monday || tuesday || wednesday || thursday || friday || saturday;
  }

  openLayout() {
    this.layoutParts = this.rentalForm.value['marketParts'];
    var marketDaysSelected = this.checkMarketDaysSelected();
    if (!marketDaysSelected) {
      Swal.fire({
        title: $localize`Warning`,
        text: $localize`Please select a market day!`,
        icon: "warning",
        confirmButtonText: $localize`Ok`,
      });
    }
    else {
      if (this.selectedMarketDays.length > 0) {
        this.selectedDates = [];
        this.selectedMarketDays.map((el, i) => {      

        const startDate = new Date(this.rentalForm.value['monthlyRentalStartDates']);
        const endMonth = this.rentalForm.value['rentalEndMonth'];
        const [endMonthDate, endMonthYear] = endMonth.split(' ');
        const endMonthIndex = this.getMonthIndex(endMonthDate);
        const endDate = new Date(endMonthYear, endMonthIndex + 1, 0);

        const selectedDayOfWeek = el - 1;
        let currentDay = new Date(startDate);
        while (currentDay <= endDate) {
          if (currentDay.getDay() === selectedDayOfWeek) {
            this.selectedDates.push(this.datePipe.transform(new Date(currentDay), 'MM/dd/yyyy'));
          }
          currentDay.setDate(currentDay.getDate() + 1);
        }

        const compareDates = (a, b): number => {
          const dateA = new Date(a.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
          const dateB = new Date(b.replace(/(\d+)(st|nd|rd|th)/, '$1')).getTime();
          return dateA - dateB;
        };
        const sortedDates = this.selectedDates.sort(compareDates);
      });
      }
      const rentalStartDateYear = new Date(this.rentalForm.value['rentalStartMonth'].split('-')[0]).getFullYear();
      const rentalStartDateMonth = new Date(this.rentalForm.value['rentalStartMonth'].split('-')[0]).getMonth() + 1;
      const rentalStartDateYearMonth = `${rentalStartDateYear}${rentalStartDateMonth < 10 ? '0' : ''}${rentalStartDateMonth}`;
      this.MonthlyLayoutData = {
        Sunday: this.rentalForm.value['marketWeekSunday'],
        Monday: this.rentalForm.value['marketWeekMonday'],
        Tuesday: this.rentalForm.value['marketWeekTuesday'],
        Wednesday: this.rentalForm.value['marketWeekWednesday'],
        Thursday: this.rentalForm.value['marketWeekThursday'],
        Friday: this.rentalForm.value['marketWeekFriday'],
        Saturday: this.rentalForm.value['marketWeekSaturday'],
        monthlyRentalStartDate: this.rentalForm.value['rentalStartMonth'],
        monthlyRentalEndDate: this.rentalForm.value['rentalEndMonth'],
        monthlyRentalMarketDays: this.selectedMarketDays,
        selectedDates: this.selectedDates,
        monthlyRentalSelectedBooths: this.selectedMonthlyBooths.filter(e => e.NewBooth == true),
        RentalStartDate: this.datePipe.transform(new Date(this.rentalForm.value['monthlyRentalStartDates']), 'MM/dd/yyyy'),
        RentalEndDate: this.datePipe.transform(new Date(this.monthlyRentalStartDates[this.monthlyRentalStartDates.length-1].startDate), 'MM/dd/yyyy'),
        RentalStartDateYearMonth:rentalStartDateYearMonth,
        tempRecordNo: this.tempRecordNo
      }      
      this.displayLayout = true;
    }
  }

  validateAndAddSelectedBoothsToGrid() {
    let noResale = true;
    var rentalDates = [];
    var marketDay;
    this.vendorRentalDays = [];
    this.marketDayCountForResale = 0 
    if ((this.ResaleNumberRequired && !this.exemptResale) || (this.BusinessLicenseNumberRequired)) {
      var rentalstartDate = "";
      var selectedBoothRecord = this.selectedMonthlyBooths.filter(e => e.NewBooth == true || e.ExtendRental == true);
      if (selectedBoothRecord.length > 0) {
        selectedBoothRecord.forEach(record => {
          var selectedDaysOfWeek;
          record.Sunday == true ? selectedDaysOfWeek = 0 :
            record.Monday == true ? selectedDaysOfWeek = 1 :
              record.Tuesday == true ? selectedDaysOfWeek = 2 :
                record.Wednesday == true ? selectedDaysOfWeek = 3 :
                  record.Thursday == true ? selectedDaysOfWeek = 4 :
                    record.Friday == true ? selectedDaysOfWeek = 5 :
                      record.Saturday == true ? selectedDaysOfWeek = 6 : 0;
          const startDate = new Date(record.RentalStartDate);
          const endDate = new Date(record.RentalEndDate);
          let currentDay = new Date(startDate);
          const excludedDates = this.excludedMarketDays.map(dateString => new Date(dateString));
          while (currentDay <= endDate) {
            var exludedDate = excludedDates.some(date => {
              return this.datePipe.transform(date, 'MM/dd/yyyy') === this.datePipe.transform(currentDay, 'MM/dd/yyyy');
            });
            if (currentDay.getDay() === selectedDaysOfWeek && !exludedDate) {
              if (!rentalstartDate) {
                rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
              }
              rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
            }
            currentDay.setDate(currentDay.getDate() + 1);
          }
        });      
      }

      for (const el of this.selectedMarketDays) {
       switch (el) {
          case 1: marketDay = 'Sun'
            break;
          case 2: marketDay = 'Mon'
            break;
          case 3: marketDay = 'Tue'
            break;
          case 4: marketDay = 'Wed'
            break;
          case 5: marketDay = 'Thu'
            break;
          case 6: marketDay = 'Fri'
            break;
          case 7: marketDay = 'Sat'
            break;
        }        
      
       // rentalDates=[];
        const startDate = new Date(this.rentalForm.value['monthlyRentalStartDates']);
        const endMonth = this.rentalForm.value['rentalEndMonth'];
        const [endMonthDate, endMonthYear] = endMonth.split(' ');
        const endMonthIndex = this.getMonthIndex(endMonthDate);
        const endDate = new Date(endMonthYear, endMonthIndex + 1, 0);
         const selectedDayOfWeek = el - 1;
        let currentDay = new Date(startDate);
        const excludedDates = this.excludedMarketDays.map(dateString => new Date(dateString));
        while (currentDay <= endDate) {
          var exludedDate = excludedDates.some(date => {
            return this.datePipe.transform(date, 'MM/dd/yyyy') === this.datePipe.transform(currentDay, 'MM/dd/yyyy');
          });
          if (currentDay.getDay() === selectedDayOfWeek && !exludedDate) {
            if (!rentalstartDate) {
              rentalstartDate = this.datePipe.transform(new Date(currentDay), 'MMM d, y');
            }
            rentalDates.push({ startDate: this.datePipe.transform(new Date(currentDay), 'MMM d, y') });
          }
          currentDay.setDate(currentDay.getDate() + 1);
        }
      }

      rentalDates.map(date => {
        const targetDateString = this.datePipe.transform(date.startDate, "MM/dd/yyyy");
        const dateExist = this.vendorRentalDays.find(el => {
          const rentalDateString = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
          return rentalDateString === targetDateString;
        });
        if (!dateExist) {
          const formattedDate = this.datePipe.transform(date.startDate, "MM/dd/yyyy");
          this.vendorRentalDays.push({ VendorRentalDate: formattedDate });
          this.marketDayCountForResale += 1;
          if (this.marketDayCountForResale > this.DaysSellWithoutResaleNumber) {
            return; // exits the forEach loop when the condition is met
          }
        }
      });

      const obj = {
        VendorID: this.vendorId,
        VendorRentalDate: new Date()
      };

      this.subscriptions.push(this.rentalService.getVendorRentalDays(obj).subscribe((res: any[]) => {
          if (this.marketDayCountForResale <= this.DaysSellWithoutResaleNumber || this.marketDayCountForResale <= this.DaysSellWithoutBusiLicNumber) {
         // const date = rentalDates[index].startDate;
          if (res && res.length > 0) {
            res.forEach(el => {
              const targetDateString = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
              const dateExist = this.vendorRentalDays.find(data => {
                const rentalDateString = this.datePipe.transform(data.VendorRentalDate, "MM/dd/yyyy");
                return rentalDateString === targetDateString;
              });
              if (!dateExist) {
                const formattedDate = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
                this.vendorRentalDays.push({ VendorRentalDate: formattedDate });
                this.marketDayCountForResale += 1;
              }
            });
          }
        }

        if (!this.newVendor) {
          if (this.ResaleNumberRequired && !this.exemptResale && !this.resaleNo && this.marketDayCountForResale > this.DaysSellWithoutResaleNumber && !this.resaleOptionSelected) {
            const modal = this.modalResaleNo.nativeElement;
            modal.classList.add('show');
            if (modal.classList.contains('show')) {
              modal.style.display = 'block';
              this.ResaleOrBusinessHeader = "Resale Number"
              this.resaleNoDisplay = true;
              this.businessLicenseDisplay = false;
            }
            noResale = false;
          }
          else if (this.BusinessLicenseNumberRequired && this.marketDayCountForResale > this.DaysSellWithoutBusiLicNumber) {
            if (!this.businessLicenseNumber || !this.businessLicenseExpiryDate) {
              const modal = this.modalResaleNo.nativeElement;
              modal.classList.add('show');
              if (modal.classList.contains('show')) {
                modal.style.display = 'block';
                this.ResaleOrBusinessHeader = "Business License Number";
                this.resaleNoDisplay = false;
                this.businessLicenseDisplay = true;
              }
              noResale = false;
            }
            else {
              if (this.getDateFromDateTime(new Date(this.businessLicenseExpiryDate)) < this.getDateFromDateTime(new Date())) {
                Swal.fire({
                  title: $localize`Warning`,
                  text: $localize`Business License is expired! Please contact the market`,
                  icon: "warning",
                  confirmButtonText: $localize`Ok`,
                });
              }
              else {
                this.addSelectedBoothsToGrid();
              }
            }
          }
          else {
            noResale = true;
            this.addSelectedBoothsToGrid();
          }
        }
        else {
          if (this.ResaleNumberRequired && !this.exemptResale && !this.resaleNo && this.marketDayCountForResale > this.DaysSellWithoutResaleNumber && !this.resaleOptionSelected) {
            this.resaleNoInputRequired.emit(true)
          }
          if (this.BusinessLicenseNumberRequired && this.marketDayCountForResale > this.DaysSellWithoutBusiLicNumber) {
            this.businessLicenseRequired.emit(true);
          }
          this.addSelectedBoothsToGrid();
        }
      }));
    } else {
      this.addSelectedBoothsToGrid();
    }
  }

  refreshAddBooth() {
    this.getMarketDays();
    this.selectedMonthlyBoothData = [];
    if (this.selectedBoothsForRental.length > 0) {
      this.selectedBoothsForRental.forEach(ele => {
        const temp = {
          TempRecordNo: this.tempRecordNo.toString(),
          BoothNumber: ele.BoothNumber,
          StartDate: new Date(ele.StartDate),
          RentalType: 4,
          EndDate: new Date(ele.EndDate),
          Sunday: this.rentalForm.value['marketWeekSunday'],
          Monday: this.rentalForm.value['marketWeekMonday'],
          Tuesday: this.rentalForm.value['marketWeekTuesday'],
          Wednesday: this.rentalForm.value['marketWeekWednesday'],
          Thursday: this.rentalForm.value['marketWeekThursday'],
          Friday: this.rentalForm.value['marketWeekFriday'],
          Saturday: this.rentalForm.value['marketWeekSaturday'],
        }
        this.subscriptions.push(
          this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
          })
        );
      });
    }
    this.selectedBoothsForRental = [];
    this.rentalForm.reset();
    this.rentalForm.patchValue({
      rentalStartMonth: this.monthlyMarketDays[0].RentalMonths,
      rentalEndMonth: this.monthlyMarketDays[0].RentalMonths,
      marketParts: this.defaultPart
    })
  }

  getDateFromDateTime(date){
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  AddBoothsOnGridOnValidationSucess() {
    var addToGrid = false;
    var closeModalPopup = false;
    const modal = this.modalResaleNo.nativeElement;
    if (this.resaleNoDisplay) {
      if (!this.resaleNo || this.resaleNo.trim() === '') {
        Swal.fire({
          title: $localize`Warning`,
          text: $localize`Enter the resale number`,
          icon: "warning",
          confirmButtonText: $localize`Ok`,
        });
      }
      else {
        addToGrid = true;
        closeModalPopup = true;
        this.resaleOptionSelected = true;
      }

      if (closeModalPopup) {
        if (modal.classList.contains('show')) {
          modal.style.display = 'none';
          modal.classList.remove('show');
        }
      }

      if (addToGrid) {
        const obj = {
          VendorID: this.vendorId,
          ExemptResaleNo: false,
          ExemptResaleReason: "",
          ResaleNo: this.resaleNo,
          MarketId: this.marketId
        }

        this.subscriptions.push(this.rentalService.UpdateVendorResaleInfo(obj).subscribe((res: any) => {
        }));
      }
    }
    else{
      addToGrid = true;
    }

    if (addToGrid) {
      if (this.BusinessLicenseNumberRequired && this.marketDayCountForResale > this.DaysSellWithoutBusiLicNumber) {
        var addToGrid = false;
        var closeModalPopup = false;
        if ((!this.businessLicenseNumber || this.businessLicenseNumber.trim() === '') || !this.businessLicenseExpiryDate) {
          this.ResaleOrBusinessHeader = "Business License Number"
          const modal = this.modalResaleNo.nativeElement;
          this.resaleNoDisplay = false;   
          this.businessLicenseDisplay = true;
          modal.classList.add('show');
          if (modal.classList.contains('show')) {
            modal.style.display = 'block';
            this.ResaleOrBusinessHeader = "Business License Number"                  
          }
        }
        else if (this.getDateFromDateTime(new Date(this.businessLicenseExpiryDate)) < this.getDateFromDateTime(new Date())) {
          this.closeResaleModal();
          Swal.fire({
            text: $localize`Business License is expired! Please contact the market.`,
            icon: "warning",
            confirmButtonText: $localize`Ok`,
          });
        }
        else {
          addToGrid = true;
          this.closeResaleModal();
          if (this.businessLicenseDisplay) {
            const obj = {
              VendorID: this.vendorId,
              LicenseNameId: 1,
              LicenseName: "",
              ExpiryDate: new Date(this.businessLicenseExpiryDate),
              LicenseNumber: this.businessLicenseNumber
            }
  
            this.subscriptions.push(this.rentalService.updateVendorBusinessLicense(this.marketId, obj).subscribe((res: any) => {
            }));
          }
          this.addSelectedBoothsToGrid();
        }       
      }
      else {
        addToGrid = true;
        closeModalPopup = true;
        this.addSelectedBoothsToGrid();
      }
    }
  }

  addSelectedBoothsToGrid() {    
    this.selectedBoothsForRental = [];
    if (this.selectedMonthlyBoothData) {
      this.loaderIcon = Promise.resolve(true);
      this.selectedMonthlyBoothData.map((el, i) => {
        const endMonth = this.rentalForm.value['rentalEndMonth'];
        const [endMonthDate, endMonthYear] = endMonth.split(' ');
        const endMonthIndex = this.getMonthIndex(endMonthDate);
        const endDate = new Date(endMonthYear, endMonthIndex + 1, 0);      

        var obj = {
          BoothTypeId: el.BoothTypeId,
          VendorId: this.vendorId,
          RentalStartPeriod: new Date(this.rentalForm.value['monthlyRentalStartDates']),
          RentalEndPeriod:endDate,
          RentalMarketDay: el.MarketDay,
          PerDiemApplicable: this.perDiemRentApplicable? true: false,
          WRNewVendorRequest: this.newVendor
        }

        this.subscriptions.push(this.rentalService.getBoothRentalMontlyCharges(this.marketId,obj).subscribe((res: any) => {
          var newbooths = [];
          var vendorProducts = !this.newVendor ? this.vendorProducts.filter(x => x.Priority) ? this.vendorProducts.filter(x => x.Priority)[0].ProductId : this.vendorProducts[0].ProductId:"";
          const ob = {
            MonthlyRentalId: 0,
            BoothNumber: el.BoothNumber,
            BoothType: el.BoothType,
            BoothTypeId: el.BoothTypeId,
            BoothDescription: el.BoothDescription,
            Product: vendorProducts,
            RentStartPeriod: this.rentalForm.value['rentalStartMonth'],
            RentEndPeriod: this.rentalForm.value['rentalEndMonth'],
            RentalStartDate :el.RentalStartDate,
            RentalEndDate : el.RentalEndDate,
            MktDays: el.NoOfMarketDays,
            PerDiem: this.perDiemRentApplicable? true: false,
            PerDiemRent:this.customCurrency.transform(res.PerdiemRent),
            Rent: this.customCurrency.transform(res.Rent),
            SalesTax: this.customCurrency.transform(res.SalesTax),
            CityTax: this.customCurrency.transform(res.CityTax),
            Sunday: el.Sunday,
            Monday: el.Monday,
            Tuesday: el.Tuesday,
            Wednesday: el.Wednesday,
            Thursday: el.Thursday,
            Friday: el.Friday,
            Saturday: el.Saturday,
            Reservation: true,
            Drop: false,
            January: this.customCurrency.transform(res.JanRent),
            February: this.customCurrency.transform(res.FebRent),
            March: this.customCurrency.transform(res.MarRent),
            April: this.customCurrency.transform(res.AprRent),
            May: this.customCurrency.transform(res.MayRent),
            June: this.customCurrency.transform(res.JunRent),
            July: this.customCurrency.transform(res.JulRent),
            August: this.customCurrency.transform(res.AugRent),
            September: this.customCurrency.transform(res.SepRent),
            October: this.customCurrency.transform(res.OctRent),
            November: this.customCurrency.transform(res.NovRent),
            December: this.customCurrency.transform(res.DecRent),
            NewBooth: true,
            RentalMarketDay: el.MarketDay,
            JanuaryMonthPresent: res.JanuaryMonthPresent,
            FebruaryMonthPresent: res.FebruaryMonthPresent,
            MarchMonthPresent: res.MarchMonthPresent,
            AprilMonthPresent: res.AprilMonthPresent,
            MayMonthPresent: res.MayMonthPresent,
            JuneMonthPresent: res.JuneMonthPresent,
            JulyMonthPresent: res.JulyMonthPresent,
            AugustMonthPresent: res.AugustMonthPresent,
            SeptemberMonthPresent: res.SeptemberMonthPresent,
            OctoberMonthPresent: res.OctoberMonthPresent,
            NovemberMonthPresent: res.NovemberMonthPresent,
            DecemberMonthPresent: res.DecemberMonthPresent,
            DisableJanMonth: res.JanRent || res.JanRent == 0?false:true, 
            DisableFebMonth: res.FebRent || res.FebRent == 0?false:true, 
            DisableMarMonth: res.MarRent || res.MarRent == 0?false:true, 
            DisableAprMonth: res.AprRent || res.AprRent == 0?false:true, 
            DisableMayMonth: res.MayRent || res.MayRent == 0?false:true, 
            DisableJunMonth: res.JunRent || res.JunRent == 0?false:true, 
            DisableJulMonth: res.JulRent || res.JulRent == 0?false:true, 
            DisableAugMonth: res.AugRent || res.AugRent == 0?false:true, 
            DisableSepMonth: res.SepRent || res.SepRent == 0?false:true, 
            DisableOctMonth: res.OctRent || res.OctRent == 0?false:true, 
            DisableNovMonth: res.NovRent || res.NovRent == 0?false:true, 
            DisableDecMonth: res.DecRent || res.DecRent == 0?false:true,
            Status: "N",         
            StartDateList:el.StartDateList,
            DiasbleStartDate: false, 
            RentalStartDateYearMonth:el.RentalStartDateYearMonth,
            ExtendRental: false,
            TaxModel: res.TaxModel
          }
          this.totalRentalCharge += res.Rent.toString().includes('(') && res.Rent.toString().includes(')') ? -1 * parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, ''));
          this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
          this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
          this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
         
          this.getDiscounts();
          this.getMarketDays();
          newbooths.push(JSON.parse(JSON.stringify(ob)));
          this.selectedMonthlyBooths = this.selectedMonthlyBooths.concat(newbooths);
        }, err => {
          this.loaderIcon = Promise.resolve(false);
        }));
        this.isdirty = true;
        this.isFormDirty.emit(true);
      });      
      this.gridMonthlyRental.refresh();    
      this.loaderIcon = Promise.resolve(false);
    //  this.refreshAddBooth();
    }
  }

  closeResaleModal(){
    const modal = this.modalResaleNo.nativeElement;
    if (modal.classList.contains('show')) {
      modal.style.display = 'none';
      modal.classList.remove('show');
    }
  }

  searchOtherItem() {
    this.otherRentalCharges = this.otherItemList;
    const otherItemSearchText = this.rentalForm.controls['otherItemsScreen'].value['otherItemSearchText'];
    if (otherItemSearchText != '') {
      this.otherRentalCharges = this.otherItemList.filter((ele) => ele.OtherItem.toString().toLowerCase().indexOf(otherItemSearchText.toLowerCase()) !== -1);
    }
  }

  created(args) {
    if(this.newVendor){
      this.gridMonthlyRental.getColumnByField("Product").visible = false;
    }
  }

  loadSelectedBooths() {    
    var newItem =[];
    if (this.sharedDataService.paymentSharedData.ReturnData.length > 0) {
      this.isdirty = true;
      this.isFormDirty.emit(true);
      this.sharedDataService.paymentSharedData.ReturnData.forEach((item) => {
        var extendedBoothIndex = this.selectedMonthlyBooths.findIndex(e=>e.MonthlyRentalId == item.MonthlyRentalId && item.ExtendRental)       
        var newbooths=[];
        if (extendedBoothIndex == -1) {
          var ob = {
            BoothNumber: item.BoothNumber,
            BoothType: item.BoothType,
            BoothTypeId: item.BoothTypeId,
            BoothDescription: item.BoothDescription,
            Product: item.Product,
            RentStartPeriod: item.RentStartPeriod,
            RentEndPeriod: item.RentEndPeriod,
            RentalSavedEndDate: item.RentalSavedEndDate,
            RentalStartDate: item.RentalStartDate,
            RentalEndDate: item.RentalEndDate,
            MktDays: item.MktDays,
            Rent: item.Rent,
            SalesTax: item.SalesTax,
            CityTax: item.CityTax,
            PerDiem: item.PerDiem,
            PerDiemRent: item.PerDiemRent,
            Sunday: item.Sunday,
            Monday: item.Monday,
            Tuesday: item.Tuesday,
            Wednesday: item.Wednesday,
            Thursday: item.Thursday,
            Friday: item.Friday,
            Saturday: item.Saturday,
            Reservation: item.Reservation,
            Drop: item.Drop,
            January: item.January,
            February: item.February,
            March: item.March,
            April: item.April,
            May: item.May,
            June: item.June,
            July: item.July,
            August: item.August,
            September: item.September,
            October: item.October,
            November: item.November,
            December: item.December,
            JanuaryMonthPresent: item.JanuaryMonthPresent,
            FebruaryMonthPresent: item.FebruaryMonthPresent,
            MarchMonthPresent: item.MarchMonthPresent,
            AprilMonthPresent: item.AprilMonthPresent,
            MayMonthPresent: item.MayMonthPresent,
            JuneMonthPresent: item.JuneMonthPresent,
            JulyMonthPresent: item.JulyMonthPresent,
            AugustMonthPresent: item.AugustMonthPresent,
            SeptemberMonthPresent: item.SeptemberMonthPresent,
            OctoberMonthPresent: item.OctoberMonthPresent,
            NovemberMonthPresent: item.NovemberMonthPresent,
            DecemberMonthPresent: item.DecemberMonthPresent,
            NewBooth: true,
            Status: "N",
            StartDateList: item.StartDateList,
            DisableStartDate: false,
            TaxModel: item.TaxModel
          }
          newbooths.push(JSON.parse(JSON.stringify(ob)));
        }
        else {
          this.selectedMonthlyBooths[extendedBoothIndex].RentEndPeriod = item.RentEndPeriod;
          this.selectedMonthlyBooths[extendedBoothIndex].RentalSavedEndDate = item.RentalSavedEndDate;
          this.selectedMonthlyBooths[extendedBoothIndex].RentalEndDate = item.RentalEndDate;
          this.selectedMonthlyBooths[extendedBoothIndex].MktDays = item.MktDays;
          this.selectedMonthlyBooths[extendedBoothIndex].Rent = item.Rent;
          this.selectedMonthlyBooths[extendedBoothIndex].SalesTax = item.SalesTax;
          this.selectedMonthlyBooths[extendedBoothIndex].CityTax = item.CityTax;
          this.selectedMonthlyBooths[extendedBoothIndex].PerDiem = item.PerDiem;
          this.selectedMonthlyBooths[extendedBoothIndex].PerDiemRent = item.PerDiemRent;
          this.selectedMonthlyBooths[extendedBoothIndex].Sunday = item.Sunday;
          this.selectedMonthlyBooths[extendedBoothIndex].Monday = item.Monday;
          this.selectedMonthlyBooths[extendedBoothIndex].Tuesday = item.Tuesday;
          this.selectedMonthlyBooths[extendedBoothIndex].Wednesday = item.Wednesday;
          this.selectedMonthlyBooths[extendedBoothIndex].Thursday = item.Thursday;
          this.selectedMonthlyBooths[extendedBoothIndex].Friday = item.Friday;
          this.selectedMonthlyBooths[extendedBoothIndex].Saturday = item.Saturday;
          this.selectedMonthlyBooths[extendedBoothIndex].Reservation = item.Reservation;
          this.selectedMonthlyBooths[extendedBoothIndex].Drop = item.Drop;
          this.selectedMonthlyBooths[extendedBoothIndex].January = item.January;
          this.selectedMonthlyBooths[extendedBoothIndex].February = item.February;
          this.selectedMonthlyBooths[extendedBoothIndex].March = item.March;
          this.selectedMonthlyBooths[extendedBoothIndex].April = item.April;
          this.selectedMonthlyBooths[extendedBoothIndex].May = item.May;
          this.selectedMonthlyBooths[extendedBoothIndex].June = item.June;
          this.selectedMonthlyBooths[extendedBoothIndex].July = item.July;
          this.selectedMonthlyBooths[extendedBoothIndex].August = item.August;
          this.selectedMonthlyBooths[extendedBoothIndex].September = item.September;
          this.selectedMonthlyBooths[extendedBoothIndex].October = item.October;
          this.selectedMonthlyBooths[extendedBoothIndex].November = item.November;
          this.selectedMonthlyBooths[extendedBoothIndex].December = item.December;
          this.selectedMonthlyBooths[extendedBoothIndex].JanuaryMonthPresent = item.JanuaryMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].FebruaryMonthPresent = item.FebruaryMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].MarchMonthPresent = item.MarchMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].AprilMonthPresent = item.AprilMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].MayMonthPresent = item.MayMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].JuneMonthPresent = item.JuneMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].JulyMonthPresent = item.JulyMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].AugustMonthPresent = item.AugustMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].SeptemberMonthPresent = item.SeptemberMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].OctoberMonthPresent = item.OctoberMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].NovemberMonthPresent = item.NovemberMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].DecemberMonthPresent = item.DecemberMonthPresent;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableJanMonth = item.JanRent || item.JanRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableFebMonth = item.FebRent || item.FebRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableMarMonth = item.MarRent || item.MarRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableAprMonth = item.AprRent || item.AprRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableMayMonth = item.MayRent || item.MayRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableJunMonth = item.JunRent || item.JunRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableJulMonth = item.JulRent || item.JulRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableAugMonth = item.AugRent || item.AugRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableSepMonth = item.SepRent || item.SepRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableOctMonth = item.OctRent || item.OctRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableNovMonth = item.NovRent || item.NovRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableDecMonth = item.DecRent || item.DecRent == 0 ? false : true;
          this.selectedMonthlyBooths[extendedBoothIndex].NewBooth = false;
          this.selectedMonthlyBooths[extendedBoothIndex].Status = "N";
          this.selectedMonthlyBooths[extendedBoothIndex].StartDateList = item.StartDateList;
          this.selectedMonthlyBooths[extendedBoothIndex].DisableStartDate = true;
          this.selectedMonthlyBooths[extendedBoothIndex].ExtendRental = true;
          this.selectedMonthlyBooths[extendedBoothIndex].TaxModel = item.TaxModel;
        }
        this.selectedMonthlyBooths = this.selectedMonthlyBooths.concat(newbooths);    
      });
    }
    if (this.sharedDataService.paymentSharedData.MonthlyOtherRentalCharges.length > 0) {
      this.isdirty = true;
      this.isFormDirty.emit(true);
      this.sharedDataService.paymentSharedData.MonthlyOtherRentalCharges.forEach((el) => {
        if (el.MonthOtherRentalId == 0) {
          const year = el.YearMonth.substr(0, 4);
          const month = el.YearMonth.substr(4, 2);
          const monthInWord = this.datePipe.transform(new Date(year, month - 1), 'MMMM');
          const extractedYearMonth = `${monthInWord} ${year}`;
          newItem = [];
          var ob = {
            Id: el.OtherItemId,
            MonthOtherRentalId: el.MonthOtherRentalId,
            OtherItem: el.OtherItemName,
            OtherItemCharge: this.customCurrency.transform(el.OtherCharge),
            Quantity: el.Quantity,
            OtherItemtotal: this.customCurrency.transform(el.OtherItemCharge),
            RentalMonth: extractedYearMonth,
            Recurring: el.Recurring,
            SalesTax: this.customCurrency.transform(el.SalesTax.toString()),
            CityTax: this.customCurrency.transform(el.SecLevelTax.toString()),
            BoothNo: el.BoothNumber,
            Reference: el.Reference,
            NewItem: true
          }
          newItem.push(JSON.parse(JSON.stringify(ob)));
        }      

        if (newItem.length > 0) {
          this.selectedOtherItems = this.selectedOtherItems.concat(newItem);
        }
      });
    }
    this.totalRentalCharge = parseFloat(this.sharedDataService.paymentSharedData.RentalCharge);
    this.totalOtherRentalCharge = parseFloat(this.sharedDataService.paymentSharedData.OtherCharge.toString());
    this.totalSalesTax = parseFloat(this.sharedDataService.paymentSharedData.SalesTax.toString());
    this.totalSecondLeveltax = parseFloat(this.sharedDataService.paymentSharedData.SecLevelTax.toString());
    this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
    this.sharedDataService.paymentSharedData = "";
    this.getDiscounts();
  }

  setDefaultRentalItem(args, selectedItems) {
    selectedItems.Quantity = 0
    selectedItems.OtherItemtotal = 0
    if (args.srcElement.checked) {
      selectedItems.otherItemChecked = true;
      selectedItems.Quantity = 1
      const isNegative = selectedItems.OtherItemCharge.includes('(') && selectedItems.OtherItemCharge.includes(')');
      const totalValue = parseFloat(selectedItems.OtherItemCharge.replace(/[^0-9.-]/g, ''));
      const finalCharge = isNegative ? -totalValue : totalValue;
      selectedItems.OtherItemtotal = this.customCurrency.transform((finalCharge * selectedItems.Quantity).toString());
      this.selectedOtherCharges.push({
        Id: selectedItems.Id,
        OtherItem: selectedItems.OtherItem,
        OtherItemCharge: selectedItems.OtherItemCharge,
        Quantity: selectedItems.Quantity,
        OtherItemtotal: selectedItems.OtherItemtotal,
        othChgBooths: selectedItems.othChgBooths,
        Recurring: selectedItems.Recurring,
        Reference:selectedItems.Reference,
        RentalMonth: this.rentalForm.controls['otherItemsScreen'].value['otherChargeMarketMonth']
      });
    } else {
      selectedItems.otherItemChecked = false;
      const index = this.selectedOtherCharges.findIndex(item => item.Id === selectedItems.Id);
      if (index !== -1) {
        this.selectedOtherCharges.splice(index, 1);
      }
    }
  }

  selectedBoothsFromLayout(data) {
    window.scrollTo(0, 0);
    this.selectedMonthlyBoothData = data;
    this.displayLayout = false;  
    this.getWebRentalSetting();
    this.validateAndAddSelectedBoothsToGrid();
  }

  closeLayoutForm() {
    this.displayLayout = false;
    this.getMarketDays();
  }

  async getBoothsAndItemsForOtherCharges() {
    this.boothsForOtherCharge = [];
    this.selectedOtherCharges = [];
    if (this.OtherChargesPeriod) {
      this.otherRentalCharges = [];
      this.otherItemList = [];
      await this.getOtherRentalItems();
    }
    const uniqueBoothNumbers = new Map<number, boolean>();
    if (this.rentalForm.controls['otherItemsScreen'].value['otherChargeMarketMonth']) {
      this.selectedMonthlyBooths.forEach((item) => {
        if (item.RentStartPeriod == this.rentalForm.controls['otherItemsScreen'].value['otherChargeMarketMonth']) {
          const boothNumber = item.BoothNumber;
          if (!uniqueBoothNumbers.has(boothNumber)) {
            this.boothsForOtherCharge.push({ BoothNumber: item.BoothNumber })
            uniqueBoothNumbers.set(boothNumber, true); // Set the booth number in the Map
          }
        }
      });
    }
    this.boothsForOtherCharge.sort((a, b) => {
      if (a.BoothNumber < b.BoothNumber) {
        return -1;
      }
      if (a.BoothNumber > b.BoothNumber) {
        return 1;
      }
      return 0;
    });    
  }

  disableSavePayment() {
    var vendorBalance = this.priorBalance + this.totalCharge;
    if (vendorBalance > 0) {
      return false
    }
    else
      return true
  }

  addOtherCharges() {
    if (this.selectedOtherCharges) {
      this.loaderIcon = Promise.resolve(true);
      this.selectedOtherCharges.map((el, i) => {
        var obj = {
          MarketId: this.marketId,
          OtherItemId: el.Id,
          VendorId: this.vendorId,
          Quantity: el.Quantity,
          OtherItemCharge: el.OtherItemCharge.includes('(') && el.OtherItemCharge.includes(')') ? (-1) * parseFloat(el.OtherItemCharge.replace(/[^0-9.-]/g, '')) : parseFloat(el.OtherItemCharge.replace(/[^0-9.-]/g, '')),
          WRNewVendorRequest: this.newVendor
        }
        const index = this.selectedOtherItems.findIndex(item => item.Id === el.Id && item.RentalMonth == this.rentalForm.controls['otherItemsScreen'].value['otherChargeMarketMonth'] && item.NewItem == true && item.BoothNo === el.othChgBooths && item.Reference == el.Reference);
        this.subscriptions.push(this.rentalService.getOtherItemsRentalCharges(obj).subscribe((res: any) => {
          var newItem = [];
          if (index !== -1) {
            var otherItemTotal = this.selectedOtherItems[index].OtherItemtotal;
            var salesTax = this.selectedOtherItems[index].SalesTax;
            var cityTax = this.selectedOtherItems[index].CityTax;
            this.totalOtherRentalCharge -= otherItemTotal.toString().includes('(') && otherItemTotal.toString().includes(')') ? -1 * parseFloat(otherItemTotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(otherItemTotal.toString().replace(/[^0-9.-]/g, ''));
            this.totalSalesTax -= salesTax.toString().includes('(') && salesTax.toString().includes(')') ? -1 * parseFloat(salesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(salesTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalSecondLeveltax -= cityTax.toString().includes('(') && cityTax.toString().includes(')') ? -1 * parseFloat(cityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(cityTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
            
            const ob = {
              Id: el.Id,
              MonthOtherRentalId: 0,
              OtherItem: el.OtherItem,
              OtherItemCharge: el.OtherItemCharge,
              Quantity: el.Quantity,
              OtherItemtotal: el.OtherItemtotal,
              RentalMonth: el.RentalMonth,
              SalesTax: this.customCurrency.transform(res.SalesTax.toString()),
              CityTax: this.customCurrency.transform(res.CityTax.toString()),
              BoothNo: el.othChgBooths,
              Recurring: el.Recurring,
              Reference:el.Reference,
              NewItem: true,
              MonthlyRentalIds:''
            }
            this.selectedOtherItems[index] = ob;
            this.gridOtherRental.refresh();
          }
          else {
            const ob = {
              Id: el.Id,
              MonthOtherRentalId: 0,
              OtherItem: el.OtherItem,
              OtherItemCharge: el.OtherItemCharge,
              Quantity: el.Quantity,
              OtherItemtotal: el.OtherItemtotal,
              RentalMonth: el.RentalMonth,
              SalesTax: this.customCurrency.transform(res.SalesTax.toString()),
              CityTax: this.customCurrency.transform(res.CityTax.toString()),
              BoothNo: el.othChgBooths,
              Recurring: el.Recurring,
              Reference:el.Reference,
              NewItem: true,
              MonthlyRentalIds : ''
            }
            newItem.push(JSON.parse(JSON.stringify(ob)));
            this.isdirty = true;
            this.isFormDirty.emit(true);
          }

          this.totalOtherRentalCharge += el.OtherItemtotal.toString().includes('(') && el.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(el.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(el.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
          this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
          this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
          this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
          
          this.getDiscounts();
          if (newItem.length > 0) {
            this.selectedOtherItems = this.selectedOtherItems.concat(newItem);
          }
        }, err => {
          this.loaderIcon = Promise.resolve(false);
        }));
      });

      this.loaderIcon = Promise.resolve(false);
      const modal = this.modalMonthlyOtherCharges.nativeElement;
      if (modal.classList.contains('show')) {
        modal.style.display = 'none';
        modal.classList.remove('show');
      }
    }
  }

  cancelRental() {
    if (this.isdirty) {
      Swal.fire({
        title: $localize`Confirm`,
        text: $localize`Unsaved changes will be lost. Are you sure you want to cancel?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: $localize`Yes`,
        cancelButtonText: $localize`No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isFormDirty.emit(false);
          this.isCancelClicked.emit('2');
        } else {
          //do nothing
        }
      });
    } else {
      this.isFormDirty.emit(false);
      this.isCancelClicked.emit('2');
    }
  }

  rentOnlineNextTab(){
    this.saveRentalWithPayment();
    this.bookingFormCompleted.emit(true);
  }

  saveRentalWithPayment() {
    this.isdirty = false;
    this.isFormDirty.emit(false);
    this.loaderIcon = Promise.resolve(true);
    var obj = {
      VendorId: this.vendorId,
      RentalType: 2,
      PriorBalance: this.priorBalance,
      RentalCharge: this.totalRentalCharge,
      SalesTax: this.totalSalesTax,
      SecLevelTax: this.totalSecondLeveltax,
      OtherCharge: this.totalOtherRentalCharge,
      RentalNotes: "",
      PaymentDone: false,
      ExemptResaleNo: false,
      ExemptResaleReason: "",
      ResaleNo: this.resaleNo,
      PaymentDueDate: null,
      MonthlyRentalBooth: [],
      MonthlyOtherRentalCharges: [],
      ReturnData: [],
      ReceiptSettings: "",
      PaymentOnly: false,
      AppliedDiscountModel: [],
      DiscountAmount: this.totalDiscountCharge ?? 0,
      BookingFee: this.bookingFeeAmount ?? 0
    }

    var objSelectedBooth = [];
    var objReturnData = [];
    if (this.selectedMonthlyBooths.length > 0) {
      this.selectedMonthlyBooths.forEach((item, i) => {
        const rentalStartDateYear = new Date(item.RentalEndDate).getFullYear();
        const rentalStartDateMonth = new Date(item.RentalEndDate).getMonth() + 1;
        const rentalStartDateYearMonth = `${rentalStartDateYear}${rentalStartDateMonth < 10 ? '0' : ''}${rentalStartDateMonth}`;
        objSelectedBooth.push({
          MonthlyRentalId: item.MonthlyRentalId,
          BoothNumber: item.BoothNumber,
          BoothTypeId: item.BoothTypeId,
          ProductId: item.Product,
          BoothType: item.BoothType,        
          RentalStartDate: new Date(item.RentalStartDate),
          RentalEndDate: new Date(item.RentalEndDate),
          RentalYearMonth: rentalStartDateYearMonth,
          BoothReserved: item.Reservation,
          DoNotRenew: item.Drop,
          PerDiem: item.PerDiem,
          Monday: item.Monday,
          Tuesday: item.Tuesday,
          Wednesday: item.Wednesday,
          Thursday: item.Thursday,
          Friday: item.Friday,
          Saturday: item.Saturday,
          Sunday: item.Sunday,
          PerDiemRent: item.PerDiemRent.includes('(') && item.PerDiemRent.includes(')') ? -1 * parseFloat(item.PerDiemRent.replace(/[^0-9.-]/g, '')) : parseFloat(item.PerDiemRent.replace(/[^0-9.-]/g, '')),
          RentalCharge: item.Rent.includes('(') && item.Rent.includes(')') ? -1 * parseFloat(item.Rent.replace(/[^0-9.-]/g, '')) : parseFloat(item.Rent.replace(/[^0-9.-]/g, '')),
          SalesTax: item.SalesTax.includes('(') && item.SalesTax.includes(')') ? -1 * parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')),
          SecLevelTax: item.CityTax.includes('(') && item.CityTax.includes(')') ? -1 * parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')),
          JanRent: item.January ? (item.January.includes('(') && item.January.includes(')') ? -1 * parseFloat(item.January.replace(/[^0-9.-]/g, '')) : parseFloat(item.January.replace(/[^0-9.-]/g, ''))) : 0,
          FebRent: item.February ? (item.February.includes('(') && item.February.includes(')') ? -1 * parseFloat(item.February.replace(/[^0-9.-]/g, '')) : parseFloat(item.February.replace(/[^0-9.-]/g, ''))) : 0,
          MarRent: item.March ? (item.March.includes('(') && item.March.includes(')') ? -1 * parseFloat(item.March.replace(/[^0-9.-]/g, '')) : parseFloat(item.March.replace(/[^0-9.-]/g, ''))) : 0,
          AprRent: item.April ? (item.April.includes('(') && item.April.includes(')') ? -1 * parseFloat(item.April.replace(/[^0-9.-]/g, '')) : parseFloat(item.April.replace(/[^0-9.-]/g, ''))) : 0,
          MayRent: item.May ? (item.May.includes('(') && item.May.includes(')') ? -1 * parseFloat(item.May.replace(/[^0-9.-]/g, '')) : parseFloat(item.May.replace(/[^0-9.-]/g, ''))) : 0,
          JunRent: item.June ? (item.June.includes('(') && item.June.includes(')') ? -1 * parseFloat(item.June.replace(/[^0-9.-]/g, '')) : parseFloat(item.June.replace(/[^0-9.-]/g, ''))) : 0,
          JulRent: item.July ? (item.July.includes('(') && item.July.includes(')') ? -1 * parseFloat(item.July.replace(/[^0-9.-]/g, '')) : parseFloat(item.July.replace(/[^0-9.-]/g, ''))) : 0,
          AugRent: item.August ? (item.August.includes('(') && item.August.includes(')') ? -1 * parseFloat(item.August.replace(/[^0-9.-]/g, '')) : parseFloat(item.August.replace(/[^0-9.-]/g, ''))) : 0,
          SepRent: item.September ? (item.September.includes('(') && item.September.includes(')') ? -1 * parseFloat(item.September.replace(/[^0-9.-]/g, '')) : parseFloat(item.September.replace(/[^0-9.-]/g, ''))) : 0,
          OctRent: item.October ? (item.October.includes('(') && item.October.includes(')') ? -1 * parseFloat(item.October.replace(/[^0-9.-]/g, '')) : parseFloat(item.October.replace(/[^0-9.-]/g, ''))) : 0,
          NovRent: item.November ? (item.November.includes('(') && item.November.includes(')') ? -1 * parseFloat(item.November.replace(/[^0-9.-]/g, '')) : parseFloat(item.November.replace(/[^0-9.-]/g, ''))) : 0,
          DecRent: item.December ? (item.December.includes('(') && item.December.includes(')') ? -1 * parseFloat(item.December.replace(/[^0-9.-]/g, '')) : parseFloat(item.December.replace(/[^0-9.-]/g, ''))) : 0,
          BookingLog: i + 1,
          TaxModel: item.TaxModel
        })

        if (item.NewBooth || item.ExtendRental) {
          objReturnData.push({
            MonthlyRentalId: item.MonthlyRentalId,
            BoothNumber: item.BoothNumber,
            BoothType: item.BoothType,
            BoothTypeId: item.BoothTypeId,
            BoothDescription: item.BoothDescription,
            Product: item.Product,
            RentStartPeriod: item.RentStartPeriod,
            RentEndPeriod: item.RentEndPeriod,
            RentalSavedEndDate: item.RentalEndDate,
            RentalStartDate: item.RentalStartDate,
            RentalEndDate: item.RentalEndDate,
            MktDays: item.MktDays,
            Rent: item.Rent,
            SalesTax: item.SalesTax,
            CityTax: item.CityTax,
            PerDiem: item.PerDiem,
            PerDiemRent: item.PerDiemRent,
            Sunday: item.Sunday,
            Monday: item.Monday,
            Tuesday: item.Tuesday,
            Wednesday: item.Wednesday,
            Thursday: item.Thursday,
            Friday: item.Friday,
            Saturday: item.Saturday,
            Reservation: item.Reservation,
            Drop: item.Drop,
            January: item.January,
            February: item.February,
            March: item.March,
            April: item.April,
            May: item.May,
            June: item.June,
            July: item.July,
            August: item.August,
            September: item.September,
            October: item.October,
            November: item.November,
            December: item.December,
            JanuaryMonthPresent: item.January ? true : false,
            FebruaryMonthPresent: item.February ? true : false,
            MarchMonthPresent: item.March ? true : false,
            AprilMonthPresent: item.April ? true : false,
            MayMonthPresent: item.May ? true : false,
            JuneMonthPresent: item.June ? true : false,
            JulyMonthPresent: item.July ? true : false,
            AugustMonthPresent: item.August ? true : false,
            SeptemberMonthPresent: item.September ? true : false,
            OctoberMonthPresent: item.October ? true : false,
            NovemberMonthPresent: item.November ? true : false,
            DecemberMonthPresent: item.December ? true : false,
            JanRent: item.January ? (item.January.includes('(') && item.January.includes(')') ? -1 * parseFloat(item.January.replace(/[^0-9.-]/g, '')) : parseFloat(item.January.replace(/[^0-9.-]/g, ''))) : 0,
            FebRent: item.February ? (item.February.includes('(') && item.February.includes(')') ? -1 * parseFloat(item.February.replace(/[^0-9.-]/g, '')) : parseFloat(item.February.replace(/[^0-9.-]/g, ''))) : 0,
            MarRent: item.March ? (item.March.includes('(') && item.March.includes(')') ? -1 * parseFloat(item.March.replace(/[^0-9.-]/g, '')) : parseFloat(item.March.replace(/[^0-9.-]/g, ''))) : 0,
            AprRent: item.April ? (item.April.includes('(') && item.April.includes(')') ? -1 * parseFloat(item.April.replace(/[^0-9.-]/g, '')) : parseFloat(item.April.replace(/[^0-9.-]/g, ''))) : 0,
            MayRent: item.May ? (item.May.includes('(') && item.May.includes(')') ? -1 * parseFloat(item.May.replace(/[^0-9.-]/g, '')) : parseFloat(item.May.replace(/[^0-9.-]/g, ''))) : 0,
            JunRent: item.June ? (item.June.includes('(') && item.June.includes(')') ? -1 * parseFloat(item.June.replace(/[^0-9.-]/g, '')) : parseFloat(item.June.replace(/[^0-9.-]/g, ''))) : 0,
            JulRent: item.July ? (item.July.includes('(') && item.July.includes(')') ? -1 * parseFloat(item.July.replace(/[^0-9.-]/g, '')) : parseFloat(item.July.replace(/[^0-9.-]/g, ''))) : 0,
            AugRent: item.August ? (item.August.includes('(') && item.August.includes(')') ? -1 * parseFloat(item.August.replace(/[^0-9.-]/g, '')) : parseFloat(item.August.replace(/[^0-9.-]/g, ''))) : 0,
            SepRent: item.September ? (item.September.includes('(') && item.September.includes(')') ? -1 * parseFloat(item.September.replace(/[^0-9.-]/g, '')) : parseFloat(item.September.replace(/[^0-9.-]/g, ''))) : 0,
            OctRent: item.October ? (item.October.includes('(') && item.October.includes(')') ? -1 * parseFloat(item.October.replace(/[^0-9.-]/g, '')) : parseFloat(item.October.replace(/[^0-9.-]/g, ''))) : 0,
            NovRent: item.November ? (item.November.includes('(') && item.November.includes(')') ? -1 * parseFloat(item.November.replace(/[^0-9.-]/g, '')) : parseFloat(item.November.replace(/[^0-9.-]/g, ''))) : 0,
            DecRent: item.December ? (item.December.includes('(') && item.December.includes(')') ? -1 * parseFloat(item.December.replace(/[^0-9.-]/g, '')) : parseFloat(item.December.replace(/[^0-9.-]/g, ''))) : 0,
            NewBooth: true,
            Status: "N",
            StartDateList: item.StartDateList,
            DisableStartDate: false,
            ExtendRental: item.ExtendRental,
            TaxModel: item.TaxModel
          });
        }
      });
    }

    if (objSelectedBooth.length > 0)
      obj.MonthlyRentalBooth = objSelectedBooth;

    if (objReturnData.length > 0) {
      obj.ReturnData = objReturnData;
    }

    var objSelectedOtherCharge = [];
    if (this.selectedOtherItems.length > 0) {
      this.selectedOtherItems.forEach((item) => {
        const otherRentalYear = new Date(item.RentalMonth.split('-')[0]).getFullYear();
        const otherRentalMonth = new Date(item.RentalMonth.split('-')[0]).getMonth() + 1;
        const otherRentalYearMonth = `${otherRentalYear}${otherRentalMonth < 10 ? '0' : ''}${otherRentalMonth}`;

        objSelectedOtherCharge.push({
          OtherItemId: item.Id,
          MonthOtherRentalId: item.MonthOtherRentalId,
          Quantity: item.Quantity,
          YearMonth: otherRentalYearMonth,
          OtherItemName: item.OtherItem,
          OtherItemCharge: item.OtherItemtotal.includes('(') && item.OtherItemtotal.includes(')') ? -1 * parseFloat(item.OtherItemtotal.replace(/[^0-9.-]/g, '')) : parseFloat(item.OtherItemtotal.replace(/[^0-9.-]/g, '')),
          BoothNumber: item.BoothNo,
          Recurring: item.Recurring,
          Reference: item.Reference,
          OtherCharge: item.OtherItemCharge.includes('(') && item.OtherItemCharge.includes(')') ? -1 * parseFloat(item.OtherItemCharge.replace(/[^0-9.-]/g, '')) : parseFloat(item.OtherItemCharge.replace(/[^0-9.-]/g, '')),
          SalesTax: item.SalesTax.includes('(') && item.SalesTax.includes(')') ? -1 * parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')),
          SecLevelTax: item.CityTax.includes('(') && item.CityTax.includes(')') ? -1 * parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')),
          MonthlyRentalIds: item.MonthlyRentalIds
        })
      });
    }
    if (objSelectedOtherCharge.length > 0)
      obj.MonthlyOtherRentalCharges = objSelectedOtherCharge;

    var objDiscountData = [];
    if (this.vendorDiscountPlans.length > 0 && this.totalDiscountCharge > 0) {
      this.vendorDiscountPlans.forEach((item) => {
        if (item.CheckAll) {
          objDiscountData.push({
            PlanId: item.PlanSeqId,
            DiscountAmount: item.AppliedDiscount.includes('(') && item.AppliedDiscount.includes(')') ? -1 * parseFloat(item.AppliedDiscount.replace(/[^0-9.-]/g, '')) : parseFloat(item.AppliedDiscount.replace(/[^0-9.-]/g, '')),
            OtherCharges: item.OtherItems,
            SalesTax: item.SalesTaxApplied,
            SecondLevelTax: item.SecondLevelTaxApplied,
            DiscountType: item.DiscountType == DiscountType.Percentage ? "0" : "1",
            DiscountValue: item.DiscountValue,
            SalesTaxAmount: item.SalesTaxAmount.includes('(') && item.SalesTaxAmount.includes(')') ? -1 * parseFloat(item.SalesTaxAmount.replace(/[^0-9.-]/g, '')) : parseFloat(item.SalesTaxAmount.replace(/[^0-9.-]/g, '')),
            SecondLevelTaxAmount: item.SecondLevelTaxAmount.includes('(') && item.SecondLevelTaxAmount.includes(')') ? -1 * parseFloat(item.SecondLevelTaxAmount.replace(/[^0-9.-]/g, '')) : parseFloat(item.SecondLevelTaxAmount.replace(/[^0-9.-]/g, ''))
          })
        }
      });
    }
    if (objDiscountData.length > 0)
      obj.AppliedDiscountModel = objDiscountData;   

    if (!this.newVendor) {
      this.sharedDataService.paymentSharedData = obj;
      var url = 'vendor-payments/' + this.marketId;
      this.router.navigate([url]);
    }
    else{
      this.rentalBookingData.emit(obj);
    }

    var temp = {
      TempRecordNo: this.tempRecordNo.toString(),
      BoothNumber: ""
    }
    this.subscriptions.push(this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
    }));
  }

  deleteSelectedBooth(data) {
      Swal.fire({
        title: $localize`Confirm`,
        text: $localize`Are you sure you want to delete the selected Booth?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: $localize`Yes`,
        cancelButtonText: $localize`No`,
      }).then((result) => {
        if (result.value) {
          const index = this.selectedMonthlyBooths.findIndex(selectedBooth => selectedBooth.BoothNumber === data.BoothNumber && selectedBooth.RentStartPeriod == data.RentStartPeriod && selectedBooth.RentEndPeriod == data.RentEndPeriod
            && selectedBooth.Sunday == data.Sunday && selectedBooth.Monday == data.Monday && selectedBooth.Tuesday == data.Tuesday && selectedBooth.Wednesday == data.Wednesday
            && selectedBooth.Thursday == data.Thursday && selectedBooth.Friday == data.Friday && selectedBooth.Saturday == data.Saturday
          );
          if (index !== -1) {
            this.selectedMonthlyBooths.splice(index, 1);
          }
          if (this.tempRecordNo) {
            const temp = {
              TempRecordNo: this.tempRecordNo.toString(),
              BoothNumber: data.BoothNumber,
              StartDate: new Date(data.RentalStartDate),
              RentalType: 2,
              EndDate: new Date(data.RentalEndDate),
              Sunday: data.Sunday,
              Monday: data.Monday,
              Tuesday: data.Tuesday,
              Wednesday: data.Wednesday,
              Thursday: data.Thursday,
              Friday: data.Friday,
              Saturday: data.Saturday
            }
            this.subscriptions.push(
              this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
              })
            );
          }  
          this.totalRentalCharge -= data.Rent.includes('(') && data.Rent.includes(')') ? -1 * parseFloat(data.Rent.replace(/[^0-9.-]/g, '')) : parseFloat(data.Rent.replace(/[^0-9.-]/g, ''));
          this.totalSalesTax -= data.SalesTax.includes('(') && data.SalesTax.includes(')') ? -1 * parseFloat(data.SalesTax.replace(/[^0-9.-]/g, '')) : parseFloat(data.SalesTax.replace(/[^0-9.-]/g, ''));
          this.totalSecondLeveltax -= data.CityTax.includes('(') && data.CityTax.includes(')') ? -1 * parseFloat(data.CityTax.replace(/[^0-9.-]/g, '')) : parseFloat(data.CityTax.replace(/[^0-9.-]/g, ''));
          this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
          this.gridMonthlyRental.refresh();
          this.getDiscounts();
        }
    });
  }

  deleteOtherRentalCharges(data) {
    Swal.fire({
      title: $localize`Confirm`,
      text: $localize`Are you sure you want to delete the selected Other Charges?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: $localize`Yes`,
      cancelButtonText: $localize`No`,
    }).then((result) => {
      if (result.value) {
        const index = this.selectedOtherItems.findIndex(oth => oth.Id === data.Id && oth.RentalMonth == data.RentalMonth);
        if (index !== -1) {
          this.selectedOtherItems.splice(index, 1);
        }

        var selectedItems = this.otherRentalCharges.findIndex(item => item.Id == data.Id);
        if (selectedItems != -1) {
          this.subscriptions.push(this.rentalService.getOtherRentalItems(this.marketId).subscribe((response) => {
            if (response) {
              const masterChargeRecord = response.filter(item => item.OtherItemId == data.Id);
              if (masterChargeRecord) {
                this.otherRentalCharges[selectedItems].Quantity = 0;
                this.otherRentalCharges[selectedItems].othChgBooths = "none";
                this.otherRentalCharges[selectedItems].OtherItemCharge = this.customCurrency.transform(masterChargeRecord[0].OtherItemMonthlyCharge);
                this.otherRentalCharges[selectedItems].OtherItemtotal = this.customCurrency.transform('0');
                this.otherRentalCharges[selectedItems].Reference = ""
                this.otherRentalCharges[selectedItems].Recurring = masterChargeRecord[0].Recurring
              }
            }
          }, (err) => {
          }));
        }

        this.totalOtherRentalCharge -= data.OtherItemtotal.includes('(') && data.OtherItemtotal.includes(')') ? -1 * parseFloat(data.OtherItemtotal.replace(/[^0-9.-]/g, '')) : parseFloat(data.OtherItemtotal.replace(/[^0-9.-]/g, ''));
        this.totalSalesTax -= data.SalesTax.includes('(') && data.SalesTax.includes(')') ? -1 * parseFloat(data.SalesTax.replace(/[^0-9.-]/g, '')) : parseFloat(data.SalesTax.replace(/[^0-9.-]/g, ''));
        this.totalSecondLeveltax -= data.CityTax.includes('(') && data.CityTax.includes(')') ? -1 * parseFloat(data.CityTax.replace(/[^0-9.-]/g, '')) : parseFloat(data.CityTax.replace(/[^0-9.-]/g, ''));
        this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
        this.gridOtherRental.refresh();
        this.getDiscounts();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
